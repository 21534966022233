import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

import { getWithdrawBankTransfer } from "../main/store/uiSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const BankTransferForm = () => {
  const [bank, setBank] = useState(9);
  const [iban, setIban] = useState("");
  const [identity, setIdentity] = useState("");
  const [dob, setDob] = useState(null);
  const [amount, setAmount] = useState("");
  const dispatch = useDispatch();

  const bankList = [
    { id: 1, name: "Akbank" },
    { id: 2, name: "Denizbank" },
    { id: 3, name: "Finansbank" },
    { id: 4, name: "Halk Bankası" },
    { id: 5, name: "İş Bankası" },
    { id: 6, name: "TEB Bankası" },
    { id: 7, name: "Yapı Kredi Bankası" },
    { id: 8, name: "Ziraat Bankası" },
    { id: 9, name: "Vakıfbank" },
    { id: 10, name: "ING Bank" },
    // Add more banks as needed
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      getWithdrawBankTransfer({
        amount,
        iban,
        bank,
        identity,
        birth_date: dob,
      })
    ).then(async (response) => {
      const result = await response.json();
      if (result.code === 200) {
        alert("Withdrawal request accepted. Status changed to Pending.");
      } else {
        alert(`Withdrawal request rejected: ${result.message}`);
      }
    });
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: "auto",
      }}
    >
      <form onSubmit={handleSubmit}>
        {/* Bank Selector */}
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            mb: 2,
            "& .MuiInputLabel-root": {
              color: "#B0B0B0", // Label color
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#4DA533", // Label when focused
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              "& fieldset": {
                borderColor: "#B0B0B0", // Default border
              },
              "&:hover fieldset": {
                borderColor: "#4DA533", // Hover border
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4DA533", // Focus border
              },
              "& .MuiSvgIcon-root": {
                color: "#fff !important"
              }
            },
          }}
        >
          <InputLabel id="bank-label">Select Bank</InputLabel>
          <Select
            labelId="bank-label"
            value={bank}
            onChange={(e) => setBank(e.target.value)}
            label="Select Bank"
            className="text-white"
          >
            {bankList.map((bank) => (
              <MenuItem key={bank.id} value={bank.name}>
                {bank.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* IBAN Input */}
        <TextField
          label="IBAN"
          fullWidth
          value={iban}
          onChange={(e) => setIban(e.target.value)}
          required
          sx={{
            mb: 2,
            "& .MuiInputBase-root": {
              color: "white", // Input text color
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#B0B0B0",
              },
              "&:hover fieldset": {
                borderColor: "#4DA533",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4DA533",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#B0B0B0",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#4DA533",
            },
          }}
        />

        {/* Identity Input */}
        <TextField
          label="TC Kimlik Numarası"
          fullWidth
          value={identity}
          onChange={(e) => setIdentity(e.target.value)}
          required
          sx={{
            mb: 2,
            "& .MuiInputBase-root": {
              color: "white", // Input text color
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#B0B0B0",
              },
              "&:hover fieldset": {
                borderColor: "#4DA533",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4DA533",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#B0B0B0",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#4DA533",
            },
          }}
        />

        {/* Date of Birth Input */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={dob}
            onChange={(newValue) => setDob(newValue)}
            className="rounded-[6px] w-full mb-[16px]"
            sx={{
              border: '1px solid #B0B0B0',
              "& .muiltr-sbmv4m-MuiInputBase-input-MuiOutlinedInput-input": {
                color: "white !important", // Ensures the input text color is white
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Date of Birth"
                className="text-white"
                InputLabelProps={{ shrink: true }}
                required
                sx={{
                  mb: 2,
                  "& .muiltr-sbmv4m-MuiInputBase-input-MuiOutlinedInput-input": {
                    color: "white !important", // Ensures the input text color is white
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#B0B0B0",
                    },
                    "&:hover fieldset": {
                      borderColor: "#4DA533",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#4DA533",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#B0B0B0",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#4DA533",
                  },
                }}
              />
            )}
            components={{
              OpenPickerIcon: () => (
                <CalendarTodayIcon 
                  sx={{ 
                    color: "#B0B0B0",
                    fontSize: '16px' 
                  }} 
                /> // Custom color for the calendar icon
              ),
            }}
          />
        </LocalizationProvider>

        {/* <TextField
          label="Date of Birth"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          required
          sx={{
            mb: 2,
            "& .MuiInputBase-root": {
              color: "white",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#B0B0B0",
              },
              "&:hover fieldset": {
                borderColor: "#4DA533",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4DA533",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#B0B0B0",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#4DA533",
            },
            "& .MuiSvgIcon-root": {
              color: "#fff !important"
            }
          }}
        /> */}

        {/* Amount Input */}
        <TextField
          label="Amount"
          type="number"
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
          sx={{
            mb: 2,
            "& .MuiInputBase-root": {
              color: "white",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#B0B0B0",
              },
              "&:hover fieldset": {
                borderColor: "#4DA533",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4DA533",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#B0B0B0",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#4DA533",
            },
          }}
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          className="rounded-[6px] disabled:bg-grey-500 min-h-[50px]"
          sx={{
            mt: 2,
            backgroundColor: "#4DA533",
            color: "white",
            "&:hover": {
              backgroundColor: "#388A29",
            },
          }}
          disabled={!dob || !identity || !iban || !amount || bank === ""}
        >
          Submit Withdrawal Request
        </Button>
      </form>
    </Box>
  );
};

export default BankTransferForm;
