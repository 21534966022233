import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { BETSHOP_SYSTEM, ONLINE_SYSTEM } from "src/app/constants";
import { useSystemType } from "src/app/hooks/useSystemType";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useSelector } from "react-redux";

const DropdownButton = ({ title, balance, currency, main = false }) => {
  return (
    <Box
      className="w-full flex justify-between items-center px-[12px] py-[6px]"
      sx={{ color: main ? "white" : "#ccc" }}
    >
      <Typography>{`${title} (${currency})`}</Typography>
      <Typography>{(+balance).toFixed(2)}</Typography>
    </Box>
  );
};

export const BalanceDropdown = ({ balance, sportsBonus, casinoBonus, currency }) => {
  const [menu, setMenu] = useState(null);
  const systemType = useSystemType();
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const totalBalance = (
    parseFloat(balance) +
    parseFloat(sportsBonus) +
    parseFloat(casinoBonus)
  ).toFixed(2);

  return (
    <Box className="relative">
      <button
        className="hidden sm:flex justify-center items-center gap-[12px] h-[40px] px-[4px] sm:pl-[24px] sm:pr-[16px] rounded-[12px] text-white bg-[#ffffff10] hover:bg-[#ffffff30] active:bg-[#ffffff50]"
        onClick={(e) => setMenu(e.currentTarget)}
      >
        {`${totalBalance}(${currency})`}
        {systemType === ONLINE_SYSTEM && (
          <KeyboardArrowDownIcon className="hidden sm:block" />
        )}
      </button>
      {systemType === ONLINE_SYSTEM && (
        <Popover
          open={Boolean(menu)}
          anchorEl={menu}
          onClose={() => setMenu(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: "max-h-[400px] !duration-75 text-white mt-[12px] bg-transparent",
          }}
        >
          <Box className="flex flex-col w-[250px] bg-[#2C374F]">
            <DropdownButton title={i18n.t("Main_balance")} balance={balance} main={true} currency={currency} />
            <Box className="h-[1px] border-t border-t-[#ffffff80] w-full"></Box>
            {systemType === ONLINE_SYSTEM && (
              <>
                <DropdownButton title={i18n.t("Sports_bonuses")} balance={sportsBonus} currency={currency} />
                <DropdownButton title={i18n.t("Casino_bonuses")} balance={casinoBonus} currency={currency} />
              </>
            )}
          </Box>
        </Popover>
      )}
    </Box>
  );
};
