import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { ContentCard } from "../component/ContentCard";
import Select from "react-select";
import { useState, useEffect } from "react";
import { getUserPreference, updateUserPreference } from "../store/profileSlice";
import { setOddsSetting } from "src/app/main/store/ticketSlice";
import {
  ODD_SETTING_ACCEPT_ANY_ODDS,
  ODD_SETTING_ACCEPT_HIGHER_ODDS,
  ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE,
  PopularPrinters
} from "src/app/constants";
import { selectUser } from 'app/store/userSlice';
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import {selectCurrentLanguageId} from "app/store/i18nSlice";

const Hr = () => <Box className="w-full h-[1.5px] border-t-[1.5px] border-t-[#D9E1E5]"></Box>;

const oddsSettingMapping = {
  ACCEPT_ANY_ODDS: ODD_SETTING_ACCEPT_ANY_ODDS,
  ACCEPT_HIGHER_ODDS: ODD_SETTING_ACCEPT_HIGHER_ODDS,
  DONT_ACCEPT_ODDS_CHANGE: ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE,
};

const oddsSettingDisplayText = {
  ACCEPT_ANY_ODDS: "Accept any odds",
  ACCEPT_HIGHER_ODDS: "Accept higher odds",
  DONT_ACCEPT_ODDS_CHANGE: "Don't accept odds change",
};

// const oddsSettingMapping = {
//   "Accept any odds": ODD_SETTING_ACCEPT_ANY_ODDS,
//   "Accept higher odds": ODD_SETTING_ACCEPT_HIGHER_ODDS,
//   "Don't accept odds change": ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE,
// };

function getOddsSettingDisplayText(key) {
  return oddsSettingDisplayText[key] || key;
}

export const Preferences = () => {
  const dispatch = useDispatch();
  const [defaultSetting, SetDefaultSetting] = useState("Accept any odds");
  const [marketingPref, SetMarketingPref] = useState(0);
  const [bonus, SetBonus] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState(PopularPrinters[0]);
  const [autoPrint, setAutoPrint] = useState(false);
  const user = useSelector(selectUser);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  useEffect(() => {
    dispatch(getUserPreference()).then((res) => {
      switch (res.payload.slip_setting) {
        case ODD_SETTING_ACCEPT_ANY_ODDS:
          SetDefaultSetting("ACCEPT_ANY_ODDS");
          break;
        case ODD_SETTING_ACCEPT_HIGHER_ODDS:
          SetDefaultSetting("ACCEPT_HIGHER_ODDS");
          break;
        case ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE:
          SetDefaultSetting("DONT_ACCEPT_ODDS_CHANGE");
          break;
        default:
          SetDefaultSetting("ACCEPT_ANY_ODDS");
      }
      dispatch(setOddsSetting(res.payload.slip_setting));
      SetMarketingPref(res.payload.marketing_preferences);
      SetBonus(res.payload.bonus_issuance);
      setAutoPrint(res.payload.auto_print); 
      setSelectedPrinter(PopularPrinters.find(p => p.value === res.payload.printer_type) || PopularPrinters[0]);
    });
  }, [dispatch]);

  const handleOddsSettingChange = (event) => {
    const newSettingKey = event.target.value;
    SetDefaultSetting(newSettingKey);
  };

  const handleMarketingPrefChange = (event) => {
    const { name, checked } = event.target;
    if (name === "email") {
      SetMarketingPref((prev) => (checked ? (prev === 1 ? 2 : 0) : prev === 2 ? 1 : 0));
    } else if (name === "phone") {
      SetMarketingPref((prev) => (checked ? (prev === 0 ? 2 : 1) : prev === 2 ? 0 : 1));
    }
  };

  const handlePrinterChange = (selectedOption) => {
    setSelectedPrinter(selectedOption);
  };

  const handleAutoPrintChange = (event) => {
    setAutoPrint(event.target.checked);
  };

  const handleBonusChange = (event) => {
    SetBonus(event.target.checked);
  };

  const handleSave = () => {
    const updatedPreferences = {
      slip_setting: oddsSettingMapping[defaultSetting], // Use key from state
      marketing_preferences: marketingPref,
      bonus_issuance: bonus,
      auto_print: autoPrint,
      printer_type: selectedPrinter.value,
    };
    dispatch(updateUserPreference(updatedPreferences));
  };

  return (
    <Box aria-label="Preferences">
      <ContentCard title={i18next.t("Preferences")} icon="assets/images/pages/settings/history.svg">
        <Box className="bg-white rounded-b-[12px] py-[24px] px-[12px] md:px-0">
          <Box className="flex flex-col gap-[12px] max-w-[1000px] mx-auto min-h-[600px]">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">{i18next.t("Odds_Settings")}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={defaultSetting}
                onChange={handleOddsSettingChange}
              >
                <FormControlLabel
                  value="ACCEPT_ANY_ODDS"
                  control={
                    <Radio sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }} />
                  }
                  label={i18next.t("Accept_any_odds")}
                />
                <FormControlLabel
                  value="DONT_ACCEPT_ODDS_CHANGE"
                  control={
                    <Radio sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }} />
                  }
                  label={i18next.t("Dont_accept_odds_change")}
                />
                <FormControlLabel
                  value="ACCEPT_HIGHER_ODDS"
                  control={
                    <Radio sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }} />
                  }
                  label={i18next.t("Accept_higher_odds")}
                />
              </RadioGroup>
            </FormControl>
            <Hr />
            <Typography className="uppercase text-[12px]">{i18next.t("MARKETING_PREFERENCES")}</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={marketingPref === 0 || marketingPref === 2}
                    onChange={handleMarketingPrefChange}
                    name="email"
                    sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }}
                  />
                }
                label={i18next.t("Email")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={marketingPref === 1 || marketingPref === 2}
                    onChange={handleMarketingPrefChange}
                    name="phone"
                    sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }}
                  />
                }
                label={i18next.t("Phone")}
              />
            </FormGroup>
            <Hr />
            <FormControlLabel
              control={
                <Switch
                  checked={bonus}
                  onChange={handleBonusChange}
                  sx={{
                    ".Mui-checked+.MuiSwitch-track": {
                      backgroundColor: "green !important",
                    },
                  }}
                />
              }
              label={i18next.t("BONUS_ISSUANCE")}
            />
            <Typography className="text-[12px]">
              {i18next.t("When_bonuses_issuance_is_turned_off_")}
            </Typography>
            <Hr />
            {user.role && user.role.includes("betshop_user") ? <><Typography className="uppercase text-[12px]">{i18next.t("PRINT_SETTINGS")}</Typography>
              {/* <Select
                value={selectedPrinter}
                onChange={handlePrinterChange}
                options={PopularPrinters}
              /> */}
              <FormControlLabel
                control={
                  <Switch
                    checked={autoPrint}
                    onChange={handleAutoPrintChange}
                    sx={{
                      ".Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "green !important",
                        color: "green !important"
                      },
                    }}
                  />
                }
                label={i18next.t("Print")}
              />
              <Typography className="text-[12px]">
                {i18next.t("Set_for_Print")}
              </Typography>
              </> : null }
            
            <button
              className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce] mt-[24px]"
              onClick={handleSave}
            >
              {i18next.t("Save")}
            </button>
          </Box>
        </Box>
      </ContentCard>
    </Box>
  );
};
