// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./print.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./tables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./prism.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Component classes registered by plugins.
 *
 */

.\\!container{

    width: 100% !important
}

.container{

    width: 100%
}

@media (min-width: 375px){

    .\\!container{

        max-width: 375px !important
    }

    .container{

        max-width: 375px
    }
}

@media (min-width: 600px){

    .\\!container{

        max-width: 600px !important
    }

    .container{

        max-width: 600px
    }
}

@media (min-width: 960px){

    .\\!container{

        max-width: 960px !important
    }

    .container{

        max-width: 960px
    }
}

@media (min-width: 1280px){

    .\\!container{

        max-width: 1280px !important
    }

    .container{

        max-width: 1280px
    }
}

@media (min-width: 1720px){

    .\\!container{

        max-width: 1720px !important
    }

    .container{

        max-width: 1720px
    }
}

@media (min-width: 1920px){

    .\\!container{

        max-width: 1920px !important
    }

    .container{

        max-width: 1920px
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/app-components.css"],"names":[],"mappings":"AAAA;;;EAGE;;AAQF;;IAAA;AAAoB;;AAApB;;IAAA;AAAoB;;AAApB;;IAAA;;QAAA;IAAoB;;IAApB;;QAAA;IAAoB;AAAA;;AAApB;;IAAA;;QAAA;IAAoB;;IAApB;;QAAA;IAAoB;AAAA;;AAApB;;IAAA;;QAAA;IAAoB;;IAApB;;QAAA;IAAoB;AAAA;;AAApB;;IAAA;;QAAA;IAAoB;;IAApB;;QAAA;IAAoB;AAAA;;AAApB;;IAAA;;QAAA;IAAoB;;IAApB;;QAAA;IAAoB;AAAA;;AAApB;;IAAA;;QAAA;IAAoB;;IAApB;;QAAA;IAAoB;AAAA","sourcesContent":["/**\n * Component classes registered by plugins.\n *\n */\n\n@import 'print.css';\n\n@import 'tables.css';\n\n@import 'prism.css';\n\n@tailwind components;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
