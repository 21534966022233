import { createEntityAdapter, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import { ODD_SETTING_ACCEPT_ANY_ODDS, ODD_SETTING_ACCEPT_HIGHER_ODDS, ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE } from "src/app/constants";
import toast from 'react-hot-toast'
import Alert from '@mui/material/Alert';
import { generateSystemOptions } from 'src/app/utils'

const api_domain = jwtServiceConfig.apiUrl;

export const createCoupon = createAsyncThunk("sportsApp/createCoupon", async (data) => {
  try {
    const response = await axios.put(`${api_domain}createCoupon`, data);
    toast.custom(
      <Alert severity='success'>
        Coupon has been successully created
      </Alert>
    )
    const res = response.data;
    return res === undefined ? null : res;
  } catch (err) {
    // toast.custom(
    //   <Alert severity='error'>
    //     {err.response.data.error}
    //   </Alert>
    // )
    return { err: err.response.data.error.charAt(0).toUpperCase() + err.response.data.error.slice(1).toLowerCase() };
  }
});

const ticketAdapter = createEntityAdapter();

export const { selectAll: selectAllTickets } = ticketAdapter.getSelectors((state) => state.sportsApp.tickets);

const ticketSlice = createSlice({
  name: "sportsApp/tickets",
  initialState: ticketAdapter.getInitialState({
    selectedOdds: [],
    betType: "Single",
    systemOption: null,
    bankers: [],
    oddsSetting: ODD_SETTING_ACCEPT_ANY_ODDS,
    status: "idle",
    couponErrorMsg: '',
    isBetting: false,
    totalBetAmount: 0,
    totalOdds: 0,
    possibleWinnings: 0,
    printTickets: []
  }),
  reducers: {
    addTicket: {
      reducer: (state, action) => {
        state.selectedOdds.push(action.payload);
      },
    },
    removeTicket: {
      reducer: (state, action) => {
        state.selectedOdds = state.selectedOdds.filter(
          (ticket) =>
            ticket.id !== action.payload.id || ticket.outcome_reference_id !== action.payload.outcome_reference_id
        );
      },
    },
    initTickets: (state, action) => {
      state.status = "initialized";
      state.selectedOdds = action.payload.tickets || [];
      state.bankers = action.payload.bankers || [];
    },
    clearTicket: (state) => {
      state.selectedOdds = [];
    },
    setIsBetSaving: (state, action) => {
      state.isBetting = action.payload;
    },
    removeCouponErrorMsg: (state, action) => {
      state.couponErrorMsg = '';
    },
    setBetType: {
      reducer: (state, action) => {
        state.betType = action.payload;
        if (action.payload === "System") {
          state.systemOption = state.systemOption || generateSystemOptions(state.selectedOdds.length)[0]?.label;
          state.systemOption = state.systemOption;
        } else {
          state.systemOption = null;
        }
      },
    },
    setTotalBet: (state, action) => {
      state.totalBetAmount = action.payload;
    },
    setTotalOdds: (state, action) => {
      state.totalOdds = action.payload;
    },
    setPrintTickets: (state, action) => {
      state.printTickets = action.payload;
    },
    setPossibleWinnings: (state, action) => {
      state.possibleWinnings = action.payload;
    },
    setSystemOption: {
      reducer: (state, action) => {
        state.systemOption = action.payload;
      },
    },
    updateStakeAmount: {
      reducer: (state, action) => {
        const { id, outcome_reference_id, stake_amount } = action.payload;
        state.selectedOdds = state.selectedOdds.map((ticket) =>
          ticket.id === id && ticket.outcome_reference_id === outcome_reference_id
            ? { ...ticket, stake_amount }
            : ticket
        );
      },
    },
    updateAllStakeAmount: {
      reducer: (state, action) => {
        const { stake_amount } = action.payload;
        state.selectedOdds = state.selectedOdds.map((ticket) => ({ ...ticket, stake_amount }));
      },
    },
    addBanker: {
      reducer: (state, action) => {
        state.bankers.push(action.payload);
      },
    },
    removeBanker: {
      reducer: (state, action) => {
        state.bankers = state.bankers.filter(
          (banker) =>
            banker.id !== action.payload.id || banker.outcome_reference_id !== action.payload.outcome_reference_id
        );
      },
    },
    updateSingleTicket: {
      reducer: (state, action) => {
        const { event_reference_id, outcome_reference_id, odds, active } = action.payload;
        state.selectedOdds = state.selectedOdds.map((ticket) => {
          if (
            ticket.event_reference_id.toUpperCase() === event_reference_id.toUpperCase() &&
            ticket.outcome_reference_id.toUpperCase() === outcome_reference_id.toUpperCase()
          ) {
            const newTrend =
              state.oddsSetting === ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE
                ? ""
                : ticket.odds > odds
                  ? "down"
                  : state.oddsSetting === ODD_SETTING_ACCEPT_HIGHER_ODDS
                    ? "up"
                    : "";

            const newOdds =
              state.oddsSetting === ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE
                ? ticket.odds
                : state.oddsSetting === ODD_SETTING_ACCEPT_HIGHER_ODDS
                  ? odds > ticket.odds
                    ? odds
                    : ticket.odds
                  : odds;

            return { ...ticket, trend: newTrend, odds: newOdds, active };
          }
          return ticket;
        });
      },
    },
    updateTickets: {
      reducer: (state, action) => {
        const updates = action.payload;
        state.selectedOdds = state.selectedOdds.map((ticket) => {
          const update = updates.find(
            (u) => u.event_reference_id === ticket.event_reference_id && u.outcome_reference_id === ticket.outcome_reference_id
          );
          if (update) {
            let { odds, active, outcome_id } = update;
            // if (outcome_id == null || outcome_id == undefined) {
            //   active = false;
            // }
            const newTrend =
              state.oddsSetting === ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE
                ? ""
                : ticket.odds > odds
                  ? "down"
                  : state.oddsSetting === ODD_SETTING_ACCEPT_HIGHER_ODDS
                    ? "up"
                    : "";

            const newOdds =
              state.oddsSetting === ODD_SETTING_DONT_ACCEPT_ODDS_CHANGE
                ? ticket.odds
                : state.oddsSetting === ODD_SETTING_ACCEPT_HIGHER_ODDS
                  ? odds > ticket.odds
                    ? odds
                    : ticket.odds
                  : odds;

            return { ...ticket, trend: newTrend, odds: newOdds, active };
          }
          return ticket;
        });
      },
    },
    removeAllTickets: {
      reducer: (state, action) => {
        const updates = action.payload;
        state.selectedOdds = []
      }
    },
    setOddsSetting: (state, action) => {
      state.oddsSetting = action.payload;
    },
  },
  extraReducers: {
    [createCoupon.fulfilled]: (state, action) => {
      if (action.payload.err) {
        state.couponErrorMsg = action.payload.err;

        let errorString = action.payload.err;
        if (!errorString.includes("outcome_locked")) return;
        errorString = errorString
          .replace(/([a-zA-Z0-9_]+):/g, '"$1":') 
          .replace(/'/g, '"'); 
        const errorObject = JSON.parse(errorString);
        const ticketData = {
          event_reference_id: errorObject.outcome_locked.eventrefid,
          outcome_reference_id: errorObject.outcome_locked.outcomerefid,
          active: false,
          odds: 0
        };
        state.couponErrorMsg = `${errorObject.outcome_locked.eventname} - ${errorObject.outcome_locked.outcomerefid} is locked.`
      }
    }
  }
});

export const {
  addTicket,
  removeTicket,
  setBetType,
  setIsBetSaving,
  setSystemOption,
  updateStakeAmount,
  updateAllStakeAmount,
  addBanker,
  removeBanker,
  clearTicket,
  updateSingleTicket,
  updateTickets, // Add the new action
  setOddsSetting,
  removeAllTickets,
  initTickets,
  removeCouponErrorMsg,
  setTotalBet,
  setTotalOdds,
  setPossibleWinnings,
  setPrintTickets
} = ticketSlice.actions;

export const selectTickets = ({ sportsApp }) => sportsApp.tickets.selectedOdds;
export const selectPrintTickets = ({ sportsApp }) => sportsApp.tickets.printTickets;
export const selectPossibleWinnings = ({ sportsApp }) => sportsApp.tickets.possibleWinnings;
export const selectTotalBetAmount = ({ sportsApp }) => sportsApp.tickets.totalBetAmount;
export const selectTotalOdds = ({ sportsApp }) => sportsApp.tickets.totalOdds;
export const selectIsBetSaving = ({ sportsApp }) => sportsApp.tickets.isBetting;
export const getBetType = ({ sportsApp }) => sportsApp.tickets.betType;
export const getSystemOption = ({ sportsApp }) => sportsApp.tickets.systemOption;
export const getSystemOptions = ({ sportsApp }) => generateSystemOptions(sportsApp.tickets.selectedOdds.length, sportsApp.tickets.bankers.length);
export const selectBankers = ({ sportsApp }) => sportsApp.tickets.bankers;
export const selectOddsSetting = ({ sportsApp }) => sportsApp.tickets.oddsSetting;
export const selectTicketStatus = ({ sportsApp }) => sportsApp.tickets.status;
export const getCouponErrorMsg = ({ sportsApp }) => sportsApp.tickets.couponErrorMsg;

export default ticketSlice.reducer;
