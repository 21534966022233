import { Navigate } from "react-router-dom";
import { Promotion } from "./Promotion";
import { PromotionList } from "./outlets/PromotionList/PromotionList";

const PromotionConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "promotions",
      element: <Promotion />,
      children: [
        {
          path: "",
          element: <Navigate to="/promotions/popular" />,
        },
        { path: ":category", element: <PromotionList /> },
      ],
    },
  ],
};

export default PromotionConfig;
