import React, {useState} from "react";
import { Box, useTheme } from "@mui/material";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useSelector } from "react-redux";
import i18n from "src/i18n";

export const SearchBox = ({ inputProps, boxProps, onSearch  }) => {
  const theme = useTheme();
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const [searchText, setSearchText] = useState("");

  const handleKeyUp = (event) => {
    // if (event.key === "Enter" && onSearch) {
    //   onSearch(searchText);
    // }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    
    if (onSearch) {
      onSearch(value);
    }
  };
  
  return (
    <Box
      aria-label="searchBox"
      className="border border-[#D3D3D3] rounded-[8px] py-[8px] pl-[10px] pr-[14px] flex items-center gap-[12px] w-full"
      bgcolor={theme.palette.background.default}
      {...boxProps}
    >
      <input
        className="text-[#585858] text-[12px] font-thin border-none bg-transparent w-full leading-none"
        placeholder={i18n.t("Search")}
        onKeyUp={handleKeyUp}
        onChange={handleChange}
        value={searchText}
        {...inputProps}
      />
      <img src="assets/images/search-icon.svg" className="w-[16px] h-[16px] object-contain" />
    </Box>
  );
};
