import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useScreenSm } from "src/app/hooks/useScreens";
import {
  Box,
  Drawer,
  Popover,
  Collapse,
  Typography,
  useTheme,
} from "@mui/material";
import { setCashierModalVisible } from "src/app/main/store/uiSlice";
import { VerticalDrawer } from "../../main/dashboard/components/VerticalDrawler";
import {
  faAddressCard,
  faHome,
  faBullhorn,
  faWallet,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectUser } from "app/store/userSlice";
import { moneyText } from "src/app/utils";
import { useSystemType } from "src/app/hooks/useSystemType";
import { ONLINE_SYSTEM } from "src/app/constants";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useCustomAuth } from "src/app/hooks/useAuth";

export const MobileUserProfileMenu = ({ open, handleClose }) => {
  const isSm = useScreenSm();
  const [menuVisible, setMenuVisible] = useState(true);
  const user = useSelector(selectUser);
  const systemType = useSystemType();
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const authenticated = useCustomAuth();

  const rightSideBarList = [
    {
      id: "account",
      title: "My_Account",
      icon: faAddressCard,
      items: [
        { icon: "", path: "/settings/profile", name: "Personal_Details" },
        { icon: "", path: "/settings/preferences", name: "Preferences" },
        {
          icon: "",
          path: "/settings/verification",
          name: "Account_Verification",
        },
      ],
    },
    {
      id: "payment",
      title: "Payment",
      icon: faHome,
      items: [
        { id: "deposit", icon: "", path: undefined, name: "Deposit" },
        { id: "withdrawal", icon: "", path: undefined, name: "Withdrawal" },
        {
          id: "tx-history",
          icon: "",
          path: "/settings/transaction-history",
          name: "Transaction_History",
        },
      ],
    },
    {
      id: "promotions",
      title: "Active_Promotions",
      icon: faBullhorn,
      items: [
        { icon: "", path: "/settings/bonus", name: "Bonues" },
      ],
    },
    {
      id: "mybets",
      title: "My_Bets",
      icon: faWallet,
      items: [
        { icon: "", path: "/settings/game-history", name: "Bet_History" },
        { icon: "", path: "/settings/casino-history", name: "Casino_History" },
      ],
    },
  ];

  const balanceInfo = [
    { id: "accountBalance", title: "Main_balance" },
    { id: "sports", title: "Sports_bonuses" },
    { id: "casino", title: "Casino_bonuses" },
  ];

  const theme = useTheme();

  return (
    <Drawer
      aria-label="MobileUserProfileMenuForMobile"
      open={open}
      anchor="right"
      onClose={handleClose}
      className="block sm:hidden z-[9999]"
    >
      <Box
        aria-label="user-info"
        className="pt-[24px] pb-[4px] px-[8px] relative"
      >
        <button
          className="absolute right-0 top-0 p-[4px] bg-[#00000030]"
          onClick={handleClose}
        >
          <CloseIcon className="text-[#333]" />
        </button>
        {authenticated && (
          <Link to="/sign-out" className="flex gap-[8px] items-center">
            <img src="assets/images/logout.svg" style={{
              width: '20px'
            }} />
            <span className="text-[#FA652B] text-[14px] font-[500]">
              {i18n.t("Log_out")}
            </span>
          </Link>
        )}
        <div className="pt-[20px]">
          <button
            aria-label="titleBox"
            className="bg-[#E4E5E5] w-full flex justify-between items-center px-[16px] py-[8px] max-w-[300px]"
            onClick={() => setMenuVisible((v) => !v)}
          >
            <Box className="uppercase text-[#505155] font-500 max-w-[240px] flex items-center gap-[4px]">
              <FontAwesomeIcon icon={faUser} className="!text-[18px]" />{" "}
              <Box className="max-w-full truncate">
                {i18n.t(user.data.displayName) || i18n.t(user.data.userName)}
              </Box>
            </Box>
            {menuVisible && <KeyboardArrowUpIcon />}
            {!menuVisible && <KeyboardArrowDownIcon />}
          </button>
          <Collapse in={menuVisible}>
            <Box className="flex flex-col">
              {systemType === ONLINE_SYSTEM &&
                balanceInfo.map((item) => (
                  <Box
                    key={item.id}
                    aria-label="drawButton"
                    className="px-[16px] py-[8px] flex gap-[8px] items-center justify-between border-b border-b-[#F0F3F8] border-x border-x-[#F0F3F8] hover:bg-[#ddd] whitespace-nowrap"
                    to={item.path}
                  >
                    <Typography className="text-[#7A7A7B] text-[14px]">
                      {i18n.t(item.title)}
                    </Typography>
                    <Typography className="text-[#7A7A7B] text-[14px]">
                      {moneyText(user.data[item.id] || 0, user.data.currency)}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Collapse>
        </div>
        {rightSideBarList
          .filter(
            (item) =>
              systemType === ONLINE_SYSTEM ||
              (item.id !== "payment" && item.id !== "promotions")
          )
          .map((b, index) => (
            <VerticalDrawer
              key={index}
              titleIcon={b.icon}
              title={i18n.t(b.title)}
              items={b.items}
              onLinkClick={() => handleClose(null)}
            />
          ))}
      </Box>
    </Drawer>
  );
};
