import { useSearchParams } from "react-router-dom";

export const useIsLive = () => {
  const [params, setParams] = useSearchParams();
  const isLive = params.get("live") ?? false;
  return {
    isLive,
    setIsLive: (live) => setParams({ live }),
    unsetIsLive: () => {
      params.delete("live");
      setParams(params);
    },
  };
};
