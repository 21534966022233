import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useScreenLg } from "src/app/hooks/useScreens";
import i18n from "src/i18n";

export function SortTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className="bg-[#2C374F] rounded-t-[16px]">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align} // "left" | "right"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="text-[12px] text-white px-[8px] md:px-[12px] hidden md:table-cell"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {i18n.t(headCell.label)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

SortTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array,
};
