import { Navigate } from "react-router-dom";
import { Information } from "./Information";
import { InformationData } from "./outlets/InformationData";

const InfomrationConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "information",
      element: <Information />,
      children: [
        {
          path: "",
          element: <Navigate to="terms-and-conditions" />,
        },
        {
          path: ":section/:contentId",
          element: <InformationData />,
        },
      ],
    },
  ],
};

export default InfomrationConfig;
