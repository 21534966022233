import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { useEffect, useLayoutEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const HeaderFullScreenToggle = (props) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEnhancedEffect(() => {
    document.onfullscreenchange = () =>
      setIsFullScreen(document[getBrowserFullscreenElementProp()] != null);

    return () => {
      document.onfullscreenchange = undefined;
    };
  });

  function getBrowserFullscreenElementProp() {
    if (typeof document.fullscreenElement !== 'undefined') {
      return 'fullscreenElement';
    }
    if (typeof document.mozFullScreenElement !== 'undefined') {
      return 'mozFullScreenElement';
    }
    if (typeof document.msFullscreenElement !== 'undefined') {
      return 'msFullscreenElement';
    }
    if (typeof document.webkitFullscreenElement !== 'undefined') {
      return 'webkitFullscreenElement';
    }
    throw new Error('fullscreenElement is not supported by this browser');
  }

  /* View in fullscreen */
  function openFullscreen() {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  function toggleFullScreen() {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      closeFullscreen();
    } else {
      openFullscreen();
    }
  }

  return (
    <Tooltip title="Fullscreen toggle" placement="bottom">
      <IconButton
        onClick={toggleFullScreen}
        className={clsx('w-40 h-40', props.className)}
        size="large"
      >
        <FuseSvgIcon>heroicons-outline:arrows-expand</FuseSvgIcon>
      </IconButton>
    </Tooltip>
  );
};

export default HeaderFullScreenToggle;




// import React, { useState, useMemo } from 'react';
// import { memo } from 'react';
// import Select from 'react-select';
// import countryList from 'react-select-country-list'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// import '../../../../../src/styles/customPhoneCode.css'
// import en from "../../../../../src/images/flags/en.svg";
// import es from "../../../../../src/images/flags/es.svg";
// import he from "../../../../../src/images/flags/he.svg";
// import ar from "../../../../../src/images/flags/ar.svg";
// import pt from "../../../../../src/images/flags/pt.svg";
// import fr from "../../../../../src/images/flags/fr.svg";
// import ja from "../../../../../src/images/flags/ja.svg";
// import tr from "../../../../../src/images/flags/tr2.svg";
// import ko from "../../../../../src/images/flags/ko.svg";
// import zh from "../../../../../src/images/flags/zh.svg";
// import th from "../../../../../src/images/flags/th.svg";
// import vi from "../../../../../src/images/flags/vi.svg";
// import hi from "../../../../../src/images/flags/hi.svg";
// import de from "../../../../../src/images/flags/de.svg";
// import it from "../../../../../src/images/flags/it.svg";
// import no from "../../../../../src/images/flags/no.svg";
// import sv from "../../../../../src/images/flags/sv.svg";
// import fi from "../../../../../src/images/flags/fi.svg";
// import da from "../../../../../src/images/flags/da.svg";
// import ptbr from "../../../../../src/images/flags/pt-br.svg";
// import mn from "../../../../../src/images/flags/mn.svg";
// import signupImg1 from "../../../../../src/images/signupImg1.png";
// import signupImg2 from "../../../../../src/images/signupImg2.png";
// import signupImg3 from "../../../../../src/images/signupImg3.png";




// function ToolbarLayout1() {

//     // Language

//     const [langActive, setLangActive] = useState(false);

//     const langToggle = () => {
//         setLangActive(!langActive);
//     };

//     // Search

//     const [searchActive, setSearchActive] = useState(false);

//     const searchToggle = () => {
//         setSearchActive(!searchActive);
//     };

//     // Search

//     const [loginActive, setloginActive] = useState(false);

//     const loginToggle = () => {
//         setloginActive(!loginActive);
//     };


//     // SignUP Popup
//     const [isOpenSignup, setIsOpenSignup] = useState(false);

//     const openSignup = () => {
//         setIsOpenSignup(!isOpenSignup);
//     };

//     const options = countryList().getData()

//     // End SignUp Popup


//     return (
//         <>

//             {/* Signup Modal */}
//             <div className={isOpenSignup ? 'modal-view_modalView__A5u9e active' : 'modal-view_modalView__A5u9e'}>
//                 <div className="modal-view_modalViewFixed__oYTBR">
//                     <div className="modal-view_modalViewContainer__YHV-l">
//                         <div className="modal-view_modalViewContainerCell__YbDXB">
//                             <div className="modal-view_modalViewWrapper__GHIy2">
//                                 <div className="modal-view_modalViewClose__+02i8 modal-view-close" onClick={()=>openSignup(false)}>
//                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" id="svg-close">
//                                         <path d="m10.001 8.497 6.687-6.686A1.062 1.062 0 1 0 15.185.308L8.497 6.993 1.81.308A1.063 1.063 0 1 0 .306 1.81l6.688 6.686-6.688 6.685a1.062 1.062 0 1 0 1.504 1.503L8.497 10l6.688 6.685a1.06 1.06 0 0 0 1.503 0 1.062 1.062 0 0 0 0-1.503z"></path>
//                                     </svg>
//                                 </div>
//                                 <div className="one-step-container_wrapper__E-KQi one-step-container_wrapperHorizontal__0UfD4">
//                                     <div className="one-step-bonus-picker-custom_container__CtpKq">
//                                         <div className="one-step-title_content__-mNxQ">Select bonus</div>
//                                         <div className="one-step-bonus-picker-custom_itemWrapper__Qri2w">
//                                             <div className="one-step-bonus-picker-custom_item__6NTAr" role="button" tabIndex="0">
//                                                 <div className="one-step-bonus-picker-custom_backgroundImage__GizWc" style={{ backgroundImage: `url(${signupImg1})` }}></div>
//                                                 <div className="one-step-bonus-picker-custom_content__j7dJm">
//                                                     <div className="one-step-bonus-picker-custom_title__D8CA+">100% up to €120</div>
//                                                     <div className="one-step-bonus-picker-custom_text__tC9Up">Welcome Bonus Casino</div>
//                                                 </div>
//                                                 <div className="one-step-bonus-picker-custom_holder__NEIT5">
//                                                     <div className="one-step-radio-button_container__b5wyF">
//                                                         <svg className="one-step-radio-button_disabled__Y-yEE" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//                                                             <path opacity="0.5" d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34871 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99075 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27288 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7117 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z" fill="#94897A"></path>
//                                                         </svg>
//                                                         <svg className="one-step-radio-button_active__-LUpE" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//                                                             <g id="Radio">
//                                                                 <path id="Vector" d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34871 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99075 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27288 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7117 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z" fill="#4DA533"></path>
//                                                                 <path id="Vector_2" d="M9.99967 16.6673C13.6816 16.6673 16.6663 13.6825 16.6663 10.0006C16.6663 6.31875 13.6816 3.33398 9.99967 3.33398C6.31778 3.33398 3.33301 6.31875 3.33301 10.0006C3.33301 13.6825 6.31778 16.6673 9.99967 16.6673Z" fill="#4DA533"></path>
//                                                             </g>
//                                                         </svg>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                             <div className="one-step-bonus-picker-custom_item__6NTAr">
//                                                 <div className="one-step-bonus-picker-custom_backgroundImage__GizWc" style={{ backgroundImage: `url(${signupImg2})` }}></div>
//                                                 <div className="one-step-bonus-picker-custom_content__j7dJm">
//                                                     <div className="one-step-bonus-picker-custom_title__D8CA+">100% up to €100</div>
//                                                     <div className="one-step-bonus-picker-custom_text__tC9Up">Welcome Bonus Sport</div>
//                                                 </div>
//                                                 <div className="one-step-bonus-picker-custom_holder__NEIT5">
//                                                     <div className="one-step-radio-button_container__b5wyF">
//                                                         <svg className="one-step-radio-button_active__-LUpE" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//                                                             <path opacity="0.5" d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34871 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99075 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27288 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7117 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z" fill="#94897A"></path>
//                                                         </svg>
//                                                         <svg className="one-step-radio-button_disabled__Y-yEE" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//                                                             <g id="Radio">
//                                                                 <path id="Vector" d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34871 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99075 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27288 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7117 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z" fill="#4DA533"></path>
//                                                                 <path id="Vector_2" d="M9.99967 16.6673C13.6816 16.6673 16.6663 13.6825 16.6663 10.0006C16.6663 6.31875 13.6816 3.33398 9.99967 3.33398C6.31778 3.33398 3.33301 6.31875 3.33301 10.0006C3.33301 13.6825 6.31778 16.6673 9.99967 16.6673Z" fill="#4DA533"></path>
//                                                             </g>
//                                                         </svg>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                             <div className="one-step-bonus-picker-custom_item__6NTAr">
//                                                 <div className="one-step-bonus-picker-custom_backgroundImage__GizWc" style={{ backgroundImage: `url(${signupImg3})` }}></div>
//                                                 <div className="one-step-bonus-picker-custom_content__j7dJm">
//                                                     <div className="one-step-bonus-picker-custom_title__D8CA+">No Bonus</div>
//                                                     <div className="one-step-bonus-picker-custom_text__tC9Up">Continue without bonus</div>
//                                                 </div>
//                                                 <div className="one-step-bonus-picker-custom_holder__NEIT5">
//                                                     <div className="one-step-radio-button_container__b5wyF">
//                                                         <svg className="one-step-radio-button_active__-LUpE" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//                                                             <path opacity="0.5" d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34871 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99075 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27288 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7117 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z" fill="#94897A"></path>
//                                                         </svg>
//                                                         <svg className="one-step-radio-button_disabled__Y-yEE" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//                                                             <g id="Radio">
//                                                                 <path id="Vector" d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34871 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99075 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27288 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7117 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333Z" fill="#4DA533"></path>
//                                                                 <path id="Vector_2" d="M9.99967 16.6673C13.6816 16.6673 16.6663 13.6825 16.6663 10.0006C16.6663 6.31875 13.6816 3.33398 9.99967 3.33398C6.31778 3.33398 3.33301 6.31875 3.33301 10.0006C3.33301 13.6825 6.31778 16.6673 9.99967 16.6673Z" fill="#4DA533"></path>
//                                                             </g>
//                                                         </svg>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <form className="one-step-registration-form_container__z48zu one-step-registration-form_containerHorizontal__sDp3g">
//                                         <div className="one-step-title_content__-mNxQ">Create an account or<span>Login</span></div>
//                                         <div className="one-step-registration-form_wrapper__DWQV3">
//                                             <div className="one-step-registration-form_row__L2C3i">
//                                                 {/* <div className="one-step-form_row__kd40Z">
//                                                     <div className="one-step-form_marginLeft__Rcw9p one-step-form_col__tptd3">
//                                                         <div className="form-input">
//                                                             <label className="form-input__container">
//                                                                 <input placeholder="Phone number" type="tel" className="form-input__input form-input__input--error" />
//                                                                 <i className="form-input__icon form-input__icon--error" data-test="usernameErrorSymbol">
//                                                                     <svg viewBox="0 0 16 16" className="svg-icon ">
//                                                                         <use href="#svg-error"></use>
//                                                                     </svg>
//                                                                 </i>
//                                                             </label>
//                                                         </div>
//                                                     </div>
//                                                 </div> */}
//                                                 <PhoneInput
//                                                     country={'us'}
//                                                     enableSearch={true}
//                                                     // value={this.state.phone}
//                                                     // onChange={phone => this.setState({ phone })}
//                                                 />
//                                             </div>
//                                             <div className="one-step-registration-form_row__L2C3i">
//                                                 <div className="form-input">
//                                                     <label className="form-input__container" for="e45cjjjrj2b">
//                                                         <input placeholder="Email" type="email" className="form-input__input" /></label>
//                                                 </div>
//                                             </div>
//                                             <div className="one-step-registration-form_row__L2C3i">
//                                                 <div className="form-input">
//                                                     <label className="form-input__container" for="7l7f4zhztt3">
//                                                         <input placeholder="Password" type="password" className="form-input__input form-input__input--error form-input__input--password" />
//                                                         <i className="form-input__icon form-input__icon--error" data-test="usernameErrorSymbol">
//                                                             <svg viewBox="0 0 16 16" className="svg-icon ">
//                                                                 <use href="#svg-error"></use>
//                                                             </svg>
//                                                         </i>
//                                                         <i role="button" aria-hidden="true" className="form-input__icon form-input__icon--secondary form-input__icon--offset">
//                                                             <svg viewBox="0 -1 18 16" className="svg-icon svg-icon--odd">
//                                                                 <use href="#svg-see"></use>
//                                                             </svg>
//                                                         </i>
//                                                     </label>
//                                                 </div>
//                                             </div>
//                                             <div className="one-step-registration-form_row__L2C3i">
//                                             <div className="form-input">
//                                                 <Select
//                                                     options={options}
//                                                     placeholder="Search country"
//                                                     isSearchable
//                                                     theme={(theme) => ({
//                                                         ...theme,
//                                                         height: '40px', // Match the height with your other inputs
//                                                         minHeight: '40px',
//                                                         borderRadius: 30,
//                                                         colors: {
//                                                         ...theme.colors,
//                                                         primary25: 'lightblue',
//                                                         primary: 'blue',
//                                                         },
//                                                     })}
//                                                 />
//                                                 </div>
//                                             </div>
//                                             <div className="one-step-registration-form_row__L2C3i">
//                                                 <div className="one-step-registration-form_promoButton__o0LEn one-step-registration-form_promoDecoration__qyPcT one-step-registration-form_customPadding__Ah93B"><span>I have promo code</span></div>
//                                             </div>
//                                             <div className="one-step-registration-form_infoRow__3HrAJ">
//                                                 <label className="app-checkbox checkbox_checkbox__NzNwE checkbox_default__FAwOf">
//                                                     <input type="checkbox" className="checkbox_input__qqbya" />
//                                                     <span className="checkbox_fakeInput__YdeSp"></span>
//                                                     <span className="checkbox-content checkbox_content__OgB38">I wish to receive promotional offers</span></label></div>
//                                             <div className="one-step-registration-form_buttonContainer__Q0Har">
//                                                 <button type="button" className="app-button app-button--default app-button--full app-button--height_48  app-button--disabled "><span>Registration</span></button></div>
//                                             <div className="one-step-registration-form_infoRow__3HrAJ">
//                                                 <div className="registration-custom__field registration-custom__field--hint form__label">
//                                                     <div className="registration-custom__hint">This site is protected by reCaptcha and the Google <a>Policy and Terms</a> and <a>Terms of Service</a></div>
//                                                 </div>
//                                             </div>
//                                             <div className="one-step-registration-form_infoRow__3HrAJ form__label">
//                                                 <span data-test="termsAndConditionsAndPolicy">By creating an account I certify that I am over 18 years of age and have read and accepted the 
//                                                 <a>Terms and Conditions</a> and <a>Privacy Policy</a></span></div>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {/* End Signup Modal */}

//             <header>
//                 <div className="header_top">
//                     <div className="header_inner">
//                         <div className="settings">
//                             <div className="settingsInner">
//                                 <div className="btnWrap" style={{ display: "flex" }}>
//                                     <span className="layoutBtn deviceBtn active" data-layout="pc">
//                                         <svg width="20" height="14" viewBox="0 0 20 14" fill="none"
//                                             xmlns="http://www.w3.org/2000/svg">
//                                             <path
//                                                 d="M20 13.2024C19.54 13.3364 19.08 13.5824 18.62 13.5844C12.8733 13.6124 7.12733 13.6151 1.382 13.5924C0.92 13.5924 0.46 13.3364 0 13.2024V11.9924C2.13 11.9924 4.26 11.9444 6.386 12.0104C7.926 12.0564 9.528 12.6004 10.986 12.3184C14 11.7404 17 12.1924 20 11.9924V13.2024Z"
//                                                 fill="#989898"></path>
//                                             <path d="M17.128 11.1584H2.854V2.07438H17.128V11.1584Z" fill="#989898"></path>
//                                             <path
//                                                 d="M18 11.0284C18 8.13239 17.96 5.22839 18.022 2.33639C18.044 1.37839 17.716 1.13639 16.822 1.17639C15.222 1.23839 13.622 1.14839 12.022 1.21039C11.16 1.24439 10.222 1.75839 9.46401 1.53439C7.40001 0.914395 5.33401 1.39239 3.28201 1.19239C2.29401 1.09439 1.94801 1.39239 1.97401 2.44439C2.04801 5.30639 2.00001 8.17239 2.00001 11.0664C1.49201 11.3104 1.20001 11.3064 1.20001 10.6244C1.20001 7.64839 1.20001 4.68239 1.20001 1.71639C1.20001 0.876395 1.65801 0.402395 2.50001 0.400395C7.50001 0.395062 12.4967 0.392395 17.49 0.392395C18.332 0.402395 18.8 0.874395 18.8 1.71439C18.812 4.68039 18.816 7.64639 18.784 10.6124C18.784 10.8384 18.47 11.0604 18.302 11.2864L18 11.0284Z"
//                                                 fill="#989898"></path>
//                                         </svg>
//                                     </span>
//                                     <span className="layoutBtn deviceBtn" data-layout="mobile">
//                                         <svg width="10" height="16" viewBox="0 0 10 16" fill="none"
//                                             xmlns="http://www.w3.org/2000/svg">
//                                             <path
//                                                 d="M0.11484 7.97632C0.11484 5.86237 0.11484 3.75341 0.11484 1.64446C0.12423 0.497741 0.65758 0.0124643 1.93274 0.00747858C4.00104 -0.00249286 6.06683 -0.00249286 8.13012 0.00747858C9.31701 0.0141262 9.88041 0.497741 9.88041 1.52978C9.89168 5.83301 9.89168 10.1346 9.88041 14.4345C9.88041 15.4765 9.31701 15.9801 8.16017 15.9884C6.06182 16.0039 3.96285 16.0039 1.86325 15.9884C0.655703 15.9884 0.116718 15.4898 0.111084 14.3813C0.11484 12.2458 0.11484 10.1085 0.11484 7.97632ZM9.1386 7.97632C9.1386 5.9222 9.1386 3.86864 9.1386 1.81563C9.1386 0.800208 8.994 0.642327 7.82401 0.693846C6.88501 0.735393 5.95728 0.991327 5.0258 0.989665C4.03046 0.989665 3.037 0.780265 2.04166 0.683874C1.20783 0.592469 0.85477 0.924851 0.866038 1.68434C0.887322 5.90226 0.887322 10.1202 0.866038 14.3381C0.866038 15.0444 1.17778 15.3352 1.98157 15.3352C3.98726 15.3136 5.99108 15.3103 7.99115 15.3352C8.84564 15.3485 9.16302 15.0361 9.15363 14.2916C9.12921 12.1843 9.14048 10.0753 9.14048 7.96635L9.1386 7.97632Z"
//                                                 fill="#989898"></path>
//                                             <path d="M8.35528 1.40351V14.609H1.66962V1.40351H8.35528Z" fill="#989898"></path>
//                                         </svg>
//                                     </span>
//                                 </div>
//                                 <div className="divider"></div>
//                                 <div className="colorsWrap active" id="template9">
//                                     <span className="colorBtn active" data-theme="dark" data-template="template9"
//                                         data-template_multy="multibalance">
//                                         <span className="active">
//                                             <svg width="40" height="28" viewBox="0 0 40 28" fill="none"
//                                                 xmlns="http://www.w3.org/2000/svg">
//                                                 <path
//                                                     d="M20 26.6527C18.1815 27.5166 16.1472 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C16.1472 0 18.1815 0.483383 20 1.34726C21.8185 0.483383 23.8528 0 26 0C33.732 0 40 6.26801 40 14C40 21.732 33.732 28 26 28C23.8528 28 21.8185 27.5166 20 26.6527Z"
//                                                     fill="white"></path>
//                                                 <path
//                                                     d="M38 14C38 20.6274 32.6274 26 26 26C19.3726 26 14 20.6274 14 14C14 7.37258 19.3726 2 26 2C32.6274 2 38 7.37258 38 14Z"
//                                                     fill="#232323"></path>
//                                                 <path
//                                                     d="M26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14Z"
//                                                     fill="#134D38"></path>
//                                             </svg>
//                                         </span>
//                                         <span>
//                                             <svg width="40" height="28" viewBox="0 0 40 28" fill="none"
//                                                 xmlns="http://www.w3.org/2000/svg">
//                                                 <path
//                                                     d="M38 14C38 20.6274 32.6274 26 26 26C19.3726 26 14 20.6274 14 14C14 7.37258 19.3726 2 26 2C32.6274 2 38 7.37258 38 14Z"
//                                                     fill="#021C26"></path>
//                                                 <path
//                                                     d="M26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14Z"
//                                                     fill="#F4F7FC"></path>
//                                             </svg>
//                                         </span>
//                                     </span>

//                                     <span className="colorBtn" data-theme="darkGreen" data-template="template91"
//                                         data-template_multy="multibalance1">
//                                         <span className="active">
//                                             <svg width="40" height="28" viewBox="0 0 40 28" fill="none"
//                                                 xmlns="http://www.w3.org/2000/svg">
//                                                 <path
//                                                     d="M20 26.6527C18.1815 27.5166 16.1472 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C16.1472 0 18.1815 0.483383 20 1.34726C21.8185 0.483383 23.8528 0 26 0C33.732 0 40 6.26801 40 14C40 21.732 33.732 28 26 28C23.8528 28 21.8185 27.5166 20 26.6527Z"
//                                                     fill="white"></path>
//                                                 <path
//                                                     d="M38 14C38 20.6274 32.6274 26 26 26C19.3726 26 14 20.6274 14 14C14 7.37258 19.3726 2 26 2C32.6274 2 38 7.37258 38 14Z"
//                                                     fill="#7DFB00"></path>
//                                                 <path
//                                                     d="M26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14Z"
//                                                     fill="#272727"></path>
//                                             </svg>
//                                         </span>
//                                         <span>
//                                             <svg width="40" height="28" viewBox="0 0 40 28" fill="none"
//                                                 xmlns="http://www.w3.org/2000/svg">
//                                                 <path
//                                                     d="M38 14C38 20.6274 32.6274 26 26 26C19.3726 26 14 20.6274 14 14C14 7.37258 19.3726 2 26 2C32.6274 2 38 7.37258 38 14Z"
//                                                     fill="#E3E9ED"></path>
//                                                 <path
//                                                     d="M26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14Z"
//                                                     fill="#021C26"></path>
//                                             </svg>
//                                         </span>
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="header_bottom">
//                     <div className="header_inner">
//                         <div className="logo">
//                             <a href="#">
//                                 <h2>LOGO</h2>
//                             </a>
//                         </div>
//                         <div className="header_menu">
//                             <ul>
//                                 <li>
//                                     <a href="#">Casino</a>
//                                 </li>
//                                 <li>
//                                     <a href="#">Sports</a>
//                                 </li>
//                                 <li>
//                                     <a href="#">Live</a>
//                                 </li>
//                                 <li>
//                                     <a href="#">Promotions</a>
//                                 </li>
//                                 <li>
//                                     <a href="#">My Bets</a>
//                                 </li>
//                             </ul>
//                         </div>
//                         <div className="header_right">
//                             <div className={langActive ? 'haeder_lang active' : 'haeder_lang'}>
//                                 <a onClick={langToggle}>
//                                     <img src={en} className="iconSize iconList mr-10" />
//                                     <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation" className="vs__open-indicator">
//                                         <path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path>
//                                     </svg>
//                                 </a>
//                                 <ul className="vs__dropdown-menu">
//                                     <li>
//                                         <span>
//                                             <img src={en} className="iconSize iconList mr-10" />
//                                             English
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={es} className="iconSize iconList mr-10" />
//                                             español
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={he} className="iconSize iconList mr-10" />
//                                             עִבְרִית
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={ar} className="iconSize iconList mr-10" />
//                                             العربية
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={pt} className="iconSize iconList mr-10" />
//                                             Português
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={fr} className="iconSize iconList mr-10" />
//                                             Français
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={ja} className="iconSize iconList mr-10" />
//                                             日本語
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={tr} className="iconSize iconList mr-10" />
//                                             Türkçe
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={ko} className="iconSize iconList mr-10" />
//                                             한국어
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={zh} className="iconSize iconList mr-10" />
//                                             漢語
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={th} className="iconSize iconList mr-10" />
//                                             ภาษาไทย
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={vi} className="iconSize iconList mr-10" />
//                                             Tiếng Việt
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={hi} className="iconSize iconList mr-10" />
//                                             हिन्दी
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={de} className="iconSize iconList mr-10" />
//                                             Deutsch
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={it} className="iconSize iconList mr-10" />
//                                             Italiano
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={no} className="iconSize iconList mr-10" />
//                                             Norsk
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={sv} className="iconSize iconList mr-10" />
//                                             Svenska
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={fi} className="iconSize iconList mr-10" />
//                                             Suomen kieli
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={da} className="iconSize iconList mr-10" />
//                                             Dansk
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={ptbr} className="iconSize iconList mr-10" />
//                                             Português do Brasil
//                                         </span>
//                                     </li>
//                                     <li>
//                                         <span>
//                                             <img src={mn} className="iconSize iconList mr-10" />
//                                             Mongolian
//                                         </span>
//                                     </li>
//                                 </ul>
//                             </div>
//                             <div className="header_search">
//                                 <a onClick={searchToggle}>
//                                     <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
//                                         xmlns="http://www.w3.org/2000/svg">
//                                         <path fillRule="evenodd" clipRule="evenodd"
//                                             d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM15 9C15 12.3137 12.3137 15 9 15C5.68629 15 3 12.3137 3 9C3 5.68629 5.68629 3 9 3C12.3137 3 15 5.68629 15 9Z">
//                                         </path>
//                                         <path data-v-54093acb=""
//                                             d="M18.1213 16C17.5355 15.4142 16.5858 15.4142 16 16C15.4142 16.5858 15.4142 17.5355 16 18.1213L17.4392 19.5607C18.025 20.1465 18.9748 20.1465 19.5605 19.5607C20.1463 18.9749 20.1463 18.0251 19.5605 17.4393L18.1213 16Z">
//                                         </path>
//                                     </svg>
//                                 </a>
//                                 <div className={searchActive ? 'headerSearchDropDown active' : 'headerSearchDropDown'}>
//                                     <div className="searchWrapper">
//                                         <div className="searchMobileListWrp">
//                                             <span className="searchListDefault">Casino</span>
//                                         </div>
//                                         <span className="searchIcon">
//                                             <svg width="18" height="19" viewBox="0 0 18 19" fill="none"
//                                                 xmlns="http://www.w3.org/2000/svg">
//                                                 <circle cx="7.61522" cy="8.11522" r="6.61522" strokeWidth="2"></circle>
//                                                 <rect x="10.8174" y="12.9746" width="2.34315" height="7.81437" rx="1.17157"
//                                                     transform="rotate(-45 10.8174 12.9746)"></rect>
//                                             </svg>
//                                         </span>
//                                         <input type="text" placeholder="Search by Game" />
//                                         <span data-analytic="header-search_close" className="headerSearchClearBtn disabledBtn">
//                                             <svg viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg"
//                                                 style={{ width: "10px", height: "10px" }}>
//                                                 <g clipPath="url(#clip0)">
//                                                     <path fillRule="evenodd" clipRule="evenodd"
//                                                         d="M1.31661 29.848C-0.207848 31.3724 -0.207848 33.844 1.31661 35.3684C2.84107 36.8927 5.31262 36.8927 6.83708 35.3684L18.1716 24.0338L29.5067 35.3689C31.0312 36.8933 33.5027 36.8933 35.0272 35.3689C36.5516 33.8443 36.5516 31.3727 35.0272 29.8483L23.6921 18.5132L35.0295 7.17583C36.554 5.65144 36.554 3.17988 35.0295 1.6555C33.5052 0.13111 31.0335 0.13111 29.5091 1.6555L18.1718 12.9929L6.83471 1.65578C5.31026 0.131389 2.8387 0.131389 1.31424 1.65578C-0.210216 3.18016 -0.210216 5.652 1.31424 7.17639L12.6512 18.5135L1.31661 29.848Z">
//                                                     </path>
//                                                 </g>
//                                             </svg>
//                                         </span>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="header_button">
//                                 <a onClick={loginToggle}>
//                                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path></svg>
//                                     Log In</a>
//                                 <div className={loginActive ? 'sign-in active' : 'sign-in'}>
//                                     <div className="sign-in__content">
//                                         <form className="login-form">
//                                             <div className="login-form__field login-form__field--email">
//                                                 <div className="form-input">
//                                                     <label className="form-input__container">
//                                                         <input placeholder="Email" type="email" className="form-input__input form-input__input--icon" />
//                                                         <i className="form-input__icon form-input__icon--main">
//                                                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z"></path></svg>
//                                                         </i>
//                                                     </label>
//                                                 </div>
//                                             </div>
//                                             <div className="login-form__field login-form__field--password">
//                                                 <div className="form-input">
//                                                     <label className="form-input__container">
//                                                         <input placeholder="Password" type="password" className="form-input__input form-input__input--icon form-input__input--password" />
//                                                         <i className="form-input__icon form-input__icon--main">
//                                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 18" id="svg-lock">
//                                                                 <path d="M13 7.125V16.5c0 .827-.648 1.5-1.445 1.5H1.445C.647 18 0 17.327 0 16.5V7.125c0-.208.162-.375.361-.375h1.083v-1.5C1.444 2.355 3.712 0 6.5 0c2.788 0 5.055 2.355 5.055 5.25v1.5h1.084c.2 0 .361.167.361.375zm-5.647 5.33c.37-.278.591-.72.591-1.205 0-.828-.647-1.5-1.444-1.5-.797 0-1.445.672-1.445 1.5 0 .485.222.927.592 1.206l-.228 2.127a.386.386 0 0 0 .09.292c.068.08.166.125.269.125h1.444c.103 0 .2-.046.27-.125a.385.385 0 0 0 .09-.292zM9.39 5.25c0-1.655-1.296-3-2.89-3-1.592 0-2.888 1.345-2.888 3v1.5H9.39z"></path>
//                                                             </svg>
//                                                         </i>
//                                                     </label>
//                                                 </div>
//                                             </div>
//                                             <div className="login-form__field login-form__field--action">
//                                                 <div className="snake-loader_Wrapper__eWJsB loading-button">
//                                                     <button type="submit" className="app-button app-button--default app-button--full app-button--large  app-button--disabled"><span>Log in</span></button></div>
//                                             </div>
//                                         </form>
//                                         <div className="form__label">
//                                             <a>Forgot your password?</a></div>
//                                     </div>
//                                     <div className="sign-in__button">
//                                         <a className="sign-in__link" data-test="registerNowButton">Register now</a></div>
//                                 </div>
//                                 <a onClick={openSignup}>
//                                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 8h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4zm6 0a1.91 1.91 0 0 1-2 2 1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2zM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2z"></path></svg>
//                                     Join Now</a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </header>
//         </>
//     );
// }

// export default memo(ToolbarLayout1);
