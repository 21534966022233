import themesConfig from 'app/configs/themesConfig';
import i18n from '../../i18n';
import authRoles from "../auth/authRoles";

const settingsConfig = {
  layout: {
    style: 'layout1', // layout1 layout2 layout3
    config: {}, // checkout default layout configs at app/theme-layouts for example  app/theme-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  direction: i18n.dir(i18n.options.lng) || 'ltr', // rtl, ltr
  theme: {
    main: themesConfig.light1,
    navbar: themesConfig.light1,
    toolbar: themesConfig.light1,
    footer: themesConfig.light1,
  },
  /*
   To make whole app auth protected by default set defaultAuth:['admin','staff','user']
   To make whole app accessible without authorization by default set defaultAuth: null
   *** The individual route configs which has auth option won't be overridden.
   */
  // defaultAuth: authRoles.admin,
  /*
    Default redirect url for the logged-in user,
   */
  loginRedirectUrl: '/home',
  languages: [
    { id: 'tr', title: 'Türkçe', flag: 'TR' },
    { id: 'en', title: 'English', flag: 'US' },
    { id: 'ar', title: 'عربي', flag: 'SA' },
    { id: 'es', title: 'Español', flag: 'es' },
    { id: 'pt', title: 'Português', flag: 'pt' },
    { id: 'ru', title: 'Русский', flag: 'ru' },
    { id: 'fr', title: 'Français', flag: 'fr' },
    { id: 'de', title: 'Deutsch', flag: 'de' },
    { id: 'zh', title: '中文', flag: 'zh' },
    { id: 'ja', title: '日本語', flag: 'jp' },
    { id: 'it', title: 'Italiano', flag: 'it' },
    { id: 'ko', title: '한국어', flag: 'ko' },
  ],
};

export default settingsConfig;
