import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { getWithdrawJetpay } from "../main/store/uiSlice";

const JetpayForm = ({handleBack}) => {
  const [bank, setBank] = useState(9);
  const [iban, setIban] = useState("");
  const [amount, setAmount] = useState("");
  const dispatch = useDispatch();

  const bankList = [
    { id: 1, name: "Akbank" },
    { id: 2, name: "Denizbank" },
    { id: 3, name: "Finansbank" },
    // ... other banks
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      getWithdrawJetpay({
        amount,
        iban,
        bank,
      })
    ).then(async (response) => {
      setTimeout(handleBack, 2500)
    });
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: "auto",
      }}
    >
      <form onSubmit={handleSubmit}>
        {/* Bank Selector */}
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            mb: 2,
            "& .MuiInputLabel-root": {
              color: "#B0B0B0", // Label color
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#4DA533", // Label when focused
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              "& fieldset": {
                borderColor: "#B0B0B0", // Default border
              },
              "&:hover fieldset": {
                borderColor: "#4DA533", // Hover border
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4DA533", // Focus border
              },
              "& .MuiSvgIcon-root": {
                color: "#fff !important"
              }
            },
          }}
        >
          <InputLabel id="bank-label">Select Bank</InputLabel>
          <Select
            labelId="bank-label"
            value={bank}
            onChange={(e) => setBank(e.target.value+"")}
            label="Select Bank"
            className="text-white"
          >
            {bankList.map((bank) => (
              <MenuItem key={bank.id} value={bank.id}>
                {bank.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* IBAN Input */}
        <TextField
          label="IBAN"
          fullWidth
          value={iban}
          onChange={(e) => setIban(e.target.value)}
          required
          sx={{
            mb: 2,
            "& .MuiInputBase-root": {
              color: "white", // Input text color
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#B0B0B0",
              },
              "&:hover fieldset": {
                borderColor: "#4DA533",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4DA533",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#B0B0B0",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#4DA533",
            },
          }}
        />

        {/* Amount Input */}
        <TextField
          label="Amount"
          type="number"
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
          sx={{
            mb: 2,
            "& .MuiInputBase-root": {
              color: "white",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#B0B0B0",
              },
              "&:hover fieldset": {
                borderColor: "#4DA533",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4DA533",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#B0B0B0",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#4DA533",
            },
          }}
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          className="rounded-[6px] disabled:bg-grey-500 min-h-[50px]"
          sx={{
            mt: 2,
            backgroundColor: "#4DA533",
            color: "white",
            "&:hover": {
              backgroundColor: "#388A29",
            },
          }}
          disabled={!bank ||!iban ||!amount}
        >
          Submit Withdrawal Request
        </Button>
      </form>
    </Box>
  );
};

export default JetpayForm;
