import { Box, Typography } from "@mui/material";

export const PromotionCard = ({ title, main_image, onClick }) => {
  return (
    <Box
      aria-label="PromotionCard"
      className="rounded-[4px] overflow-hidden relative w-full flex flex-col cursor-pointer"
      onClick={onClick}
      sx={{
        "&:hover": {
          "& .promotion-card-image": {
            transform: "scale(1.05, 1.05)",
          },
        },
      }}
    >
      <img
        src={main_image}
        alt=""
        className="promotion-card-image transition-all duration-300"
      />
      <Box className="h-[34px] flex items-center p-[10px] bg-[#30323f] z-[100]">
        <Typography className="text-[12px] uppercase text-white">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
