import { Box } from "@mui/material";
import { ContentCard } from "../../component/ContentCard";
import { SelectGroup } from "../../component/InputGroups";
import { DatePicker } from "@mui/x-date-pickers";
import { couponDatas, headCells } from "./historyData";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBetHistory } from "../../store/historySlice";
import HistoryTable from "./HistoryTable";
import { getLastMondayMoreThanWeekAgo } from "src/app/utils";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";

const datePickerSx = {
  "& input": { paddingY: "3px" },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ddd !important",
    borderWidth: "1px !important",
    borderRadius: "12px",
  },
  "& .MuiInputBase-root": {
    minHeight: "38px",
  },
};

const options = [
  { label: "All", value: "all" },
  { label: "Waiting", value: "waiting" },
  { label: "Won", value: "won" },
  { label: "Lost", value: "lost" },
  { label: "Cashout", value: "cashout" },
  { label: "Cancel", value: "Cancel" },
  { label: "Refunded", value: "refunded" },
];

export const GameHistory = () => {
  const today = new Date();
  const [fromDate, setFromDate] = useState(new Date(today.setHours(0, 0, 0, 0)));
  const [toDate, setToDate] = useState(new Date(today.setHours(23, 59, 59, 999)));
  const [status, setStatus] = useState({ label: "All", value: "all" });
  const [coupons, setCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const [value, onChange] = useState(new Date());
  const [items, setItems] = useState(["s"]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBetHistory()).then((res) => {
      setCoupons(res.payload);
    });
  }, []);

  useEffect(() => {
    const lastMonday = getLastMondayMoreThanWeekAgo();
    setFromDate(lastMonday);
  }, []);

  useEffect(() => {
    const filtered = coupons?.filter((coupon) => {
      const createdDate = new Date(coupon.created_at).toISOString().slice(0, 10); // Extract only the date part
      const fromDateOnly = new Date(fromDate).toISOString().slice(0, 10);
      const toDateOnly = new Date(toDate).toISOString().slice(0, 10);
      
      const matchesDateRange = createdDate >= fromDateOnly && createdDate <= toDateOnly;
      const matchesStatus = status.value === "all" || coupon.slip_status.toLowerCase() === status.value.toLowerCase();
  
      return matchesDateRange && matchesStatus;
    });
  
    setFilteredCoupons(filtered);
  }, [fromDate, toDate, status, coupons]);

  const handleRefresh = () => {
    dispatch(getBetHistory()).then((res) => {
      setCoupons(res.payload);
    });
  };

  return (
    <Box aria-label="Transaction">
      <ContentCard title={i18n.t("Bet_History")} icon="assets/images/pages/settings/history.svg">
        <Box className="bg-white rounded-b-[12px] p-[12px] min-h-[400px]">
          <Box className="flex flex-col gap-[12px] max-w-[1000px] mx-auto relative z-[100]">
            <Box className="flex flex-col md:flex-row justify-between items-center gap-[12px]">
              <Box className="w-full flex flex-col md:flex-row gap-[12px]">
                <SelectGroup options={options} value={status} onChange={(v) => setStatus(v)} />
                <DatePicker
                  value={fromDate}
                  onChange={(newDate) => setFromDate(newDate)}
                  sx={datePickerSx}
                  format="yyyy-MM-dd"
                />
                <DatePicker
                  value={toDate}
                  onChange={(newDate) => setToDate(newDate)}
                  sx={datePickerSx}
                  format="yyyy-MM-dd"
                />
              </Box>
              <button onClick={() => { handleRefresh() }} className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce]">
                {i18n.t("Get")}
              </button>
            </Box>
            {items.length === 0 && (
              <Box className="flex justify-center mt-[100px] text-[24px] text-[#00000080]">{i18n.t("No_History")}</Box>
            )}
          </Box>
          <Box height="12px"></Box>
          {filteredCoupons?.length === 0 ? (
            <Box className="flex justify-center mt-[100px] text-[24px] text-[#00000080]">{i18n.t("No_History")}</Box>
          ) : (
            <HistoryTable headCells={headCells} rows={filteredCoupons} handleRefresh={handleRefresh} />
          )}
        </Box>
      </ContentCard>
    </Box>
  );
};
