import { Box, Button, Stack, Typography } from "@mui/material";
import i18next from "i18next";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useSelector } from "react-redux";

export const Cashback = ({
  title,
  amount,
  onClaim,
}) => {
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      flexGrow={1}
      sx={{
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Box>
        <Typography variant="h6" color="white">{title}</Typography>
        <Typography variant="body2" color="white">{i18next.t('Total_earned')}</Typography>
        <Typography variant="h5" color="white">{amount}</Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={onClaim}
      >
        {i18next.t('Claim')}
      </Button>
    </Stack>
  );
};