import { useCallback } from "react";
import { useState } from "react"
import { useEffect } from "react"
import toast from "react-hot-toast";

const duration = 5 * 60 * 1000;

export const useActivityCheck = () => {
  const [inactive, setInactive] = useState(false);

  const resetTimer = useCallback(() => {
    setInactive(false);
    clearTimeout(window.activityTimeout);
    window.activityTimeout = setTimeout(() => {
      setInactive(true);
      toast(
        'You have been inactive for 5 minutes',
        { duration: 2000 } // Set duration to Infinity so we can manually dismiss it
      );
    }, duration);
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("mousedown", resetTimer);
    document.addEventListener("keydown", resetTimer);
    document.addEventListener('touchstart', resetTimer);
    document.addEventListener('scroll', resetTimer);
    return () => {
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("mousedown", resetTimer);
      document.removeEventListener("keydown", resetTimer);
      document.removeEventListener('touchstart', resetTimer);
      document.removeEventListener('scroll', resetTimer);
    }
  }, []);

  return { inactive };
}