import { Alert, Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { ModalBox } from "app/shared-components/ModalBox";
import { useState } from "react";
import { BonusItem, bonusItemsData } from "../BonusItem";
import {
  getBonusList,
  selectAffiliateId,
} from "src/app/main/store/uiSlice";

import React, { useEffect, useRef } from "react";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import countryList from "react-select-country-list";
import "react-phone-input-2/lib/style.css";
import "../../../../styles/customPhoneCode.css";
import JwtService from "src/app/auth/services/jwtService";
import { useModal } from "src/app/hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrencies } from "app/store/i18nSlice";
import toast from "react-hot-toast";
import Turnstile from "react-turnstile";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";

const currency = [
  { label: "USD" },
  { label: "TRY" },
  { label: "ARS" },
  { label: "THB" },
  { label: "VES" },
  { label: "BRL" },
];

const options = countryList().getData();

export const SignupModal = ({ visible, onClose }) => {
  const [bonusMode, setBonusMode] = useState("caisno");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [signupDisabled, setSignupDisabled] = useState(false);

  const [inputError, setInputErrorT] = useState({});
  const [extraError, setExtraError] = useState(null);
  const [triedOnce, setTriedOnce] = useState(false);
  const [bonusList, setBonusList] = useState([]);
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const affiliateId = useSelector(selectAffiliateId)

  const setInputError = (err) => setInputErrorT((e) => ({ ...e, ...err }));

  const currency = useSelector(selectCurrencies);

  const dispatch = useDispatch();
  useModal(visible);

  useEffect(() => {
    setUsername("");
    setPassword("");
    setEmail("");
    setPhone("");
    setSelectedCountry("");
    setSelectedCurrency("");
    setTriedOnce(false);
    setInputErrorT({});
    setExtraError(null);
  }, [visible]);

  useEffect(() => {
    // dispatch(getBonusList({ trigger: "register" })).then((res) => {
    //   const data = res.payload;
    //   let bonusData = data.bonus_list.map((d) => {
    //     return {
    //       id: d.id,
    //       title: d.name,
    //       subtitle: "",
    //     };
    //   });
    //   bonusData = [{
    //     id: -1,
    //     title: i18n.t("no_bonus"),
    //     subtitle: "",
    //   }, ...bonusData]
    //   setBonusList(bonusData);
    // });
  }, []);

  const header = () => {
    return (
      <Box className="min-w-[600px] p-[12px] h-[44px]">
        {/* <img src="assets/images/logo/logo.png" alt="logo" className="w-[140px] h-auto object-contain" /> */}
      </Box>
    );
  };

  useEffect(() => {
    if (!triedOnce) return;
    if (username.length === 0) setInputError({ username: "Username required" });
    else setInputError({ username: null });
  }, [username, triedOnce]);

  useEffect(() => {
    if (!triedOnce) return;
    if (email.length === 0) setInputError({ email: "Email required" });
    else if (!email.includes("@")) setInputError({ email: "Email must contains @" });
    else setInputError({ email: null });
  }, [email, triedOnce]);

  useEffect(() => {
    if (!triedOnce) return;
    if (password.length === 0) setInputError({ password: "Password required" });
    else setInputError({ password: null });
  }, [password, triedOnce]);

  const handleCaptcha = (token) => {};

  const handleRegister = (e) => {
    e.preventDefault();
    // const captchaValue = recaptcha.current.getValue();
    // if (!captchaValue) {
    if (false) {
      toast.custom(<Alert severity="error">Please verify the reCAPTCHA!</Alert>);
    } else {
      setTriedOnce(true);
      // Perform register action (e.g., dispatch a Redux action or make an API call)

      JwtService.createUser({
        username,
        email,
        password,
        phone,
        country: selectedCountry,
        currency: selectedCurrency,
        affiliate_id: affiliateId
      })
        .then((user) => {
          // No need to do anything, registered user data will be set at app/auth/AuthContext
        })
        .catch((_errors) => {
          setExtraError(_errors.response.data.user_error);
          // _errors.forEach((error) => {
          //   setError(error.type, {
          //     type: "manual",
          //     message: error.message,
          //   });
          // });
        });
    }
  };

  const body = () => {
    return (
      <Box className="px-[24px] pb-[36px] signup-modal-body">
        <Box className="h-[200px] overflow-y-hidden">
          <img
            className="w-full h-auto object-cover rounded-b-[12px] relative top-[-30px]"
            src="/assets/images/signup.jpg"
            alt=""
          />
        </Box>
        <Grid container className="min-w-[800px] mt-[12px]">
          {/* <Grid item xs={4}>
            <Box className="border-r-[2px] border-r-[#F5F5F5] h-full">
              {bonusList.map((item) => (
                <Box key={item.id} className="border-t-[2px] border-t-[#F5F5F5] first:border-t-0">
                  <BonusItem
                    {...item}
                    checked={item.id === bonusMode}
                    onClick={() => {
                      setBonusMode(item.id);
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Grid> */}
          <Grid item xs>
            <form className="px-[16px] py-[12px]" onSubmit={handleRegister}>
              <Box className="form_group">
                <Box className="one-step-registration-form_row__L2C3i">
                  <PhoneInput country={"us"} enableSearch={true} value={phone} onChange={setPhone} />
                </Box>
              </Box>
              <Box aria-label="input-error" className="text-red text-[11px] min-h-[16px]">
                {/* {inputError["username"] && inputError["username"]} */}
              </Box>
              <Box className="form_group">
                <input
                  placeholder={i18n.t("Username")}
                  type="text"
                  className="form-input__input"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Box>
              <Box aria-label="input-error" className="text-red text-[11px] min-h-[16px]">
                {inputError["username"] && inputError["username"]}
              </Box>
              <Box className="form_group">
                <input
                  placeholder={i18n.t("Email")}
                  type="email"
                  className="form-input__input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <Box aria-label="input-error" className="text-red text-[11px] min-h-[16px]">
                {inputError["email"] && inputError["email"]}
              </Box>
              <Box className="form_group">
                <input
                  placeholder={i18n.t("Password")}
                  type="password"
                  className="form-input__input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Box>
              <Box aria-label="input-error" className="text-red text-[11px] min-h-[16px]">
                {inputError["password"] && inputError["password"]}
              </Box>
              <Box className="form_group country_currency">
                <Box className="form-input">
                  <Select
                    options={options}
                    placeholder={i18n.t("Search_country")}
                    isSearchable
                    onChange={(val) => setSelectedCountry(val.label)}
                  />
                </Box>
                <Box className="form-input currency">
                  <Select
                    options={currency.map((c) => ({ label: c }))}
                    placeholder={i18n.t("Currency")}
                    isSearchable
                    onChange={(val) => setSelectedCurrency(val.label)}
                  />
                </Box>
              </Box>
              <FormControlLabel
                control={<Checkbox defaultChecked sx={{ "& .MuiSvgIcon-root": { color: "yellowgreen" } }} />}
                label={i18n.t("I_wish_to_receive_promotional_offers")}
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              />
              <Box aria-label="error-message" className="text-red min-h-[20px]">
                {extraError && extraError}
              </Box>
              <Box className="flex flex-col justify-center items-center mt-[20px] gap-[12px]">
                {/* <Turnstile sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY} onVerify={handleCaptcha} /> */}
                <button
                  type="submit"
                  className="px-[120px] py-[12px] bg-green-700 rounded-full text-white uppercase hover:bg-green-900"
                >
                  {i18n.t('Registration')}
                </button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return <ModalBox visible={visible} onClose={onClose} header={header()} body={body()} />;
};
