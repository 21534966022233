import { Box } from "@mui/material";
import { BetslipContainer } from "app/shared-components/RightSidebar/BetslipContainer";
import { useScreenMd } from "src/app/hooks/useScreens";

export const Betslip = () => {
  const isMd = useScreenMd();

  if (isMd) return null;

  return (
    <Box className="p-[8px]">
      <BetslipContainer />
    </Box>
  );
};
