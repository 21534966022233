import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const api_domain = jwtServiceConfig.apiUrl;

export const getCMS = createAsyncThunk("cmsApp/getCMS", async (cmsId) => {
  const response = await axios.get(`${api_domain}/cms/${cmsId}`);
  const data = response.data.cms;
  return data === undefined ? null : data;
});

export const getCMSList = createAsyncThunk(
  "cmsApp/cms/getCMSList",
  async (typename) => {
    const response = await axios.get(`${api_domain}cms/list/${typename}`);
    const data = response.data.CMSList;
    return data === undefined ? null : data;
  }
);

const cmsAdapter = createEntityAdapter();

export const { selectAll: selectAllCMS } = cmsAdapter.getSelectors();

const cmsSlice = createSlice({
  name: "cmsApp/cms",
  initialState: cmsAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCMSList.fulfilled, (state, action) => {
      cmsAdapter.setAll(state, action.payload || []);
    })
  }
})

export default cmsSlice.reducer;