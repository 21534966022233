const locale = {
  CASINO_BETS: 'Casino Bets',
  HIGH_ROLLERS: 'High Rollers',
  RARE_WINS: 'Rare Wins',
  GAME: 'Game',
  USER: 'User',
  TIME: 'Time',
  BET_AMOUNT: 'Bet Amount',
  MULTIPLIER: 'Multiplier',
  PAYOUT: 'Payout',
  MORE: 'More',
  LIVE_CASINO: 'Live Casino',
  CASINO: 'Casino'
};

export default locale;
