import { Box } from "@mui/material";
import { VerticalDrawer } from "./components/VerticalDrawler";
import { informationData } from "./dummyData";
import { ContentCard } from "./components/ContentCard";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getCMSList } from "../store/cmsSlice";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useScreenMd, useScreenSm } from "src/app/hooks/useScreens";

const sections = [
  { title: "Company", value: "company" },
  { title: "Terms of Privacy", value: "terms_and_privacy" },
  { title: "Rules", value: "rules" },
  { title: "Responsible Gambling", value: "responsible_gambling" },
  { title: "FAQ", value: "faq" },
]

export const Information = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const isMd = useScreenMd();
  const isSm = useScreenSm();

  useEffect(() => {
    dispatch(getCMSList("pages_of_contents")).then((res) => {
      const list = res.payload || [];
      setData(list.filter(
        item => !item.isdeleted && item.active && item.language === currentLanguageId && (isMd ? item.device === 1 : item.device === 2)
      ));
    });
  }, [currentLanguageId, isMd, isSm]);

  return (
    <Box aria-label="Information" className="flex items-stretch sm:flex-row flex-col">
      <Box
        aria-label="left-part"
        className="flex flex-col w-full sm:min-w-[300px] sm:w-[300px] px-[8px] py-[12px] max-h-[calc(var(--app-height)-140px)] overflow-y-auto"
        style={{ scrollbarWidth: "none" }}
      >
        <Box className="rounded-[12px] shadow-md">
          {sections.map((section) => (
            <VerticalDrawer
              section={section} 
              items={data.filter(item => item.section === section.value)}
            />
          ))}
        </Box>
      </Box>
      <Box
        aria-label="right-part"
        className="flex-grow pt-[12px] max-h-[calc(var(--app-height)-140px)] overflow-y-auto sm:pr-[24px] px-6"
      >
        <Outlet context={{ data }} />
      </Box>
    </Box>
  );
};
