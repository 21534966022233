import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

export const PromotionDetail = ({ visible, onClose, title, main_image, content }) => {
  useEffect(() => {
    if (visible) document.documentElement.classList.add("overflow-hidden");
    else document.documentElement.classList.remove("overflow-hidden");
    document.getElementById("promotion-detail").innerHTML = content;
  }, [visible, content]);

  return (
    <>
      <Box
        className="z-[10000] fixed w-screen h-[calc(var(--app-height))] left-0 top-0 bg-[#00000080] invisible opacity-0 data-[visible=true]:visible data-[visible=true]:opacity-100 transition-all duration-150"
        data-visible={visible}
        onClick={onClose}
      ></Box>
      <Box
        className="z-[10001] fixed max-w-[1200px] mx-auto max-h-[calc(var(--app-height)-100px)] w-full h-full left-1/2 -translate-x-1/2 top-0 invisible opacity-0 data-[visible=true]:visible data-[visible=true]:opacity-100 transition-all duration-150 m-[50px] bg-[#000c24] rounded-[8px]"
        data-visible={visible}
      >
        <Box className="w-full h-full flex flex-col gap-[12px] p-[12px]">
          <Box className="h-[49px] px-[8px] flex justify-between items-center">
            <Typography className="text-white uppercase">{title}</Typography>
            <button onClick={onClose}>
              <CloseIcon className="text-white" />
            </button>
          </Box>
          <Box className="h-full flex gap-[32px]">
            <Box className="w-[400px] min-w-[400px] max-h-fit">
              <img src={main_image} alt="" className="rounded-[8px]" />
            </Box>
            <Box
              className="max-h-full overflow-auto"
              id="promotion-detail"
              sx={{ color: "white" }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
