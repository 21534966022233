import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import StyleIcon from '@mui/icons-material/Style';
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { ThemeBox } from "./ThemeBox";
import { useEffect } from "react";
import { useRef } from "react";
import themesConfig from "app/configs/themesConfig";
import { useDispatch } from "react-redux";
import { changeFuseTheme } from "app/store/fuse/settingsSlice";


export const ThemeSelector = () => {
  const [visible, setVisible] = useState(false);
  const [themeKey, setThemeKey] = useState('default');
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    const themeKey = localStorage.getItem('app-theme') || "default";
    // handleThemeClick(themeKey, themesConfig[themeKey]);
  }, []);

  useEffect(() => {
    if (visible && containerRef.current) {
      containerRef.current.scrollTo({ top: 0 });
    }
  }, [visible]);

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentMousedown);
    return () => document.removeEventListener('mousedown', handleDocumentMousedown);
  }, []);

  const handleDocumentMousedown = (e) => {
    const container = containerRef.current;
    if (container && !container.contains(e.target)) {
      setVisible(false);
    }
  }

  const handleShowClick = () => {
    setVisible(true);
  };

  const handleCloseClick = () => {
    setVisible(false);
  };

  const handleThemeClick = (key, theme) => {
    setThemeKey(key);
    localStorage.setItem('app-theme', key);
    dispatch(changeFuseTheme(theme));
  }

  return (
    <>
      <Button
        onClick={handleShowClick}
        sx={{
          backgroundColor: "#fa652b",
          minWidth: "auto",
          position: "fixed",
          bottom: { xs: "100px", md:"30px"},
          right: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          "&:hover": {
            backgroundColor: "red",
          },
        }}
      >
        <StyleIcon sx={{ color: "white" }} />
      </Button>
      <Box
        position='fixed'
        width="400px"
        height="var(--app-height)"
        maxHeight="var(--app-height)"
        bgcolor={theme.palette.background.paper}
        top={0}
        right={visible ? 0 : "-400px"}
        boxShadow="0px 0 8px #44444430"
        zIndex={9999}
        sx={{
          transition: "all 0.2s ease-in-out",
        }}
      >
        <Button
          onClick={handleCloseClick}
          sx={{
            minWidth: "auto",
            position: "absolute",
            top: "12px",
            left: "12px",
            bgcolor: theme.palette.background.default
          }}
        >
          <CloseIcon />
        </Button>
        <Stack
          py={8}
          px={4}
          gap={3}
          maxHeight="100%"
          ref={containerRef}
          sx={{ overflowY: "auto" }}
        >
          <Typography variant="h5" textAlign="center">Theme Color Options</Typography>
          {Object.entries(themesConfig).map(([key, theme], index) => {
            const { title, palette } = theme;
            const { mode, text, primary, secondary, background, divider } = palette;

            return (
              <ThemeBox
                key={index}
                onClick={() => handleThemeClick(key, theme)}
                selected={themeKey === mode}
                name={title}
                navbar={text.primary}
                navbarBg={background.paper}
                toolbar={primary.contrastText}
                toolbarBg={primary.light}
                header={primary.contrastText}
                headerBg={primary.main}
                paper={text.primary}
                paperBg={background.paper}
                background={text.primary}
                backgroundBg={background.default}
                footer={primary.contrastText}
                footerBg={primary.dark}
                sColor={secondary.contrastText}
                sBg={secondary.main}
                divider={divider}
              />
            );
          })}
        </Stack>
      </Box>
    </>
  );
};
