export const Chip = ({ status }) => {
  return (
    <span
      data-status={status}
      className="inline-block text-center px-[8px] py-[2px] rounded-[4px] text-white"
      style={{
        backgroundColor:
          status === "Won" || status === "Refunded"
            ? "#12851b"
            : status === "Waiting" || status === "Open"
            ? "#a1a31c"
            : status === "Lost" || status === "Cancel"
            ? "#c53846"
            : undefined,
      }}
    >
      {status}
    </span>
  );
};
