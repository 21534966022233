import { Box, Divider, MenuItem, Select, Stack, Typography } from "@mui/material"
import { Cashback } from "./Cashback"
import { useEffect, useState } from "react"
import { BonusTable } from "./BonusTable"
import { useSelect } from "@mui/base"
import { selectUser } from "app/store/userSlice"
import { useDispatch, useSelector } from "react-redux"
import { getBetBonus, claimBonus, getCashbackBonusAmount } from "../../store/bonusSlice"
import i18next from "i18next";
import { selectCurrentLanguageId } from "app/store/i18nSlice";

const selectItems = [
  { label: "Active Bonus", value: "active_bonus" },
  { label: "Bonus History", value: "bonus_history" },
]

export const Bonus = () => {
  const [selection, setSelection] = useState(selectItems[0].value);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [activeBonuses, setActiveBonuses] = useState([]);
  const [availableBonuses, setAvailableBonuses] = useState([]);
  const [pastBonuses, setPastBonuses] = useState([]);
  const [cashbackBonusAmount, setCashbackBonusAmount] = useState({});
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  useEffect(() => {
    dispatch(getBetBonus({bonusType: "all", status: "active"})).then((res) => {
      setActiveBonuses(res.payload)
    })
    dispatch(getBetBonus({bonusType: "all", status: "available"})).then((res) => {
      setAvailableBonuses(res.payload)
    })
    dispatch(getBetBonus({bonusType: "all", status: "past"})).then((res) => {
      setPastBonuses(res.payload);
    })
    dispatch(getCashbackBonusAmount()).then((res) => {
      setCashbackBonusAmount(res.payload);
    })
  }, [])

  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
  }
  
  const handleCasinoCashbackClick = () => {
    dispatch(claimBonus({bonusType: "Casino",})).then((res) => {
    })
  }

  const handleSportCashbackClick = () => {
    dispatch(claimBonus({bonusType: "Sports",})).then((res) => {
    })
  }
  
  return (
    <Box aria-label="Bonus">
      <Stack
        direction={{ md: "row" }}
        borderRadius="24px"
        p="24px"
        bgcolor="#2c374f"
      >
        <Cashback
          title={i18next.t("Casino_cashback")}
          amount={`${user.data.currency} ${cashbackBonusAmount?.casino_cashback}`}
          onClaim={handleCasinoCashbackClick}
        />
        <Box px="24px">
          <Divider
            orientation="vertical"
          />
        </Box>
        <Cashback
          title={i18next.t("Sport_cashback")}
          amount={`${user.data.currency} ${cashbackBonusAmount?.sports_cashback}`}
          onClaim={handleSportCashbackClick}
        />
      </Stack>
      {/* <Box marginTop="24px">
        <Select
          value={selection}
          onChange={handleSelectionChange}
        >
          {selectItems.map(item => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </Box> */}
      <Stack 
        gap="24px"
        mt="24px">
        <Typography fontSize="18px" fontWeight={'bold'} color="#0D1D34">
          {i18next.t('Active_Bonuses')}
        </Typography>
        <BonusTable rows={activeBonuses} />
        <Typography fontSize="18px" fontWeight={'bold'} color="#0D1D34">
          {i18next.t('Available_Bonuses')}
        </Typography>
        <BonusTable rows={availableBonuses} />
        <Typography fontSize="18px" fontWeight={'bold'} color="#0D1D34">
          {i18next.t('Past_Bonuses')}
        </Typography>
        <BonusTable rows={pastBonuses} />
      </Stack>
    </Box>
  )
}