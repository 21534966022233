import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { PromotionCard } from "../../components/PromotionCard/PromotionCard";
import { useState } from "react";
import { PromotionDetail } from "../PromotionDetail/PromotionDetail";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCMSList } from "src/app/main/store/cmsSlice";
import { useSelect } from "@mui/base";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useScreenMd } from "src/app/hooks/useScreens";

const promotionList = {
  popular: [
    {
      id: "1",
      title: "100% welcome offer on sportsbook",
      image: "assets/images/pages/promotions/welcome-bonus.webp",
      detail: `
        <div class="max-h-[calc(100%-60px)] overflow-auto">
          <div class="text-white">VBET offers to feel the rhythm of the Sports world with 100% up to €15 Free Bet.</div>
          <div class="text-white mt-[30px]">Complete qualifying steps to be 100% welcomed to VBET. </div>
          <div class="mt-[30px]">
            <div class="uppercase text-white text-bold">How to qualify</div>
            <ul>
            <li class="text-[#ccc] mt-[12px]">- Register or login on vbet.com.</li>
            <li class="text-[#ccc] mt-[12px]">- Successfully verify your account: go to My Profile > Verify Account → Upload your identity documents from the Documents page and wait for your documents to be approved.</li>
            <li class="text-[#ccc] mt-[12px]">- Place a min of €2 or more single or multiple bet with a min. of 1.5 odds.</li>
            <li class="text-[#ccc] mt-[12px]">- Get 100% up to €15 Free Bet on your first qualifying bet.</li>
            </ul>
            <div class="p-[12px] flex mt-[32px]">
              <a class="rounded-[8px] bg-light-green-500 text-white uppercase px-[20px] py-[12px]">Register Now</a>
            </div>
            <div class="uppercase text-white text-bold">HOW TO USE FREE BET</div>
            <ul>
              <li class="text-[#ccc] mt-[12px]">- The max Free Bet amount earned in this promotion is €15. </li>
              <li class="text-[#ccc] mt-[12px]">- Free Bet will be credited after calculation of qualifying bet</li>
              <li class="text-[#ccc] mt-[12px]">- Place a min of €2 or more single or multiple bet with a min. of 1.5 odds.</li>
              <li class="text-[#ccc] mt-[12px]">- Free Bet will be valid for 5 days after receipt</li>
            </ul>
            <div class="p-[12px] flex mt-[32px]">
              <a class="rounded-[8px] bg-light-green-500 text-white uppercase px-[20px] py-[12px]">Bet Now</a>
            </div>
            <div class="mt-32px">General Terms & Conditions</div>
            <div class="text-[#ccc] mt-[4px]">1. The promotion is available from June 20, 2023 for VBET Players only.</div>
            <div class="text-[#ccc] mt-[4px]">2. Only verified users and first-time bettors can qualify for this offer.</div>
            <div class="text-[#ccc] mt-[4px]">3. Only qualified users can get a bonus during the promotion.</div>
            <div class="text-[#ccc] mt-[4px]">4. Bets placed with free bets or bonus money do not participate in this promotion.</div>
            <div class="text-[#ccc] mt-[4px]">5. The promotion is available in all currencies with minimum bet and free bet amount equivalents.</div>
            <div class="text-[#ccc] mt-[4px]">6. Each Player can qualify for this promotion only once.</div>
            <div class="text-[#ccc] mt-[4px]">7. Each Player confirms to have read and accepted all the terms and conditions. This bonus offer is limited to one customer, family, address, shared computer, shared IP address. VBET reserves the right to cancel the bonus and block the account if they discover that the Player is misusing this bonus offer or is opening multiple accounts.</div>
            <div class="text-[#ccc] mt-[4px]">8. Players who open more than one account and violate the bonus offer will not be paid and their accounts will be blocked.</div>
            <div class="text-[#ccc] mt-[4px]">9. The offer is not valid when the bet is calculated with the odds of "1".</div>
            <div class="text-[#ccc] mt-[4px]">10. Any bet with the full or partial Cash Out feature will not be taken into account and will not participate in the promotion.</div>
            <div class="text-[#ccc] mt-[4px]">11. The offer is not valid when the Players' first bet is calculated with "1" odds.</div>
            <div class="text-[#ccc] mt-[4px]">12. BetBuilder bets do not participate in this promotion.</div>
            <div class="text-[#ccc] mt-[4px]">13. VBET has the right to amend the terms of the offer, cancel or renew the offer, or refuse to allow participation at any time without prior notice. This means that VBET may limit or refuse to allow customers to take part in this or any other offer.</div>
            <div class="text-[#ccc] mt-[4px]">14. VBET reserves the right to change the bonus rules, cancel or stop the bonus at any time.</div>
            <div class="text-[#ccc] mt-[4px]">15. Please be aware that if "No" is displayed in the "Eligible for bonus" subsection of the "Personal Details" section determined by the automatic system, it indicates that they have not qualified for any bonuses offered on the website.</div>
            <div class="text-[#ccc] mt-[4px]">16. The VBET Regulations, General Terms & Conditions also apply.</div>
          </div>
        </div>
      `,
    },
    {
      id: "2",
      title: "200% Up to $250 + 100 fs welcome bonus",
      image: "assets/images/pages/promotions/claim-enter-the-game.webp",
      detail: `
        <div>You</div>
      `,
    },
    {
      id: "3",
      title: "Refer-a-friend bonus",
      image: "assets/images/pages/promotions/sharing-is-caring.webp",
      detail: `
        <div class="text-white">Him</div>
      `,
    },
    {
      id: "4",
      title: "100% welcome offer on sportsbook",
      image: "assets/images/pages/promotions/welcome-bonus.webp",
      detail: `
        <div class="max-h-[calc(100%-60px)] overflow-auto">
          <div class="text-white">VBET offers to feel the rhythm of the Sports world with 100% up to €15 Free Bet.</div>
          <div class="text-white mt-[30px]">Complete qualifying steps to be 100% welcomed to VBET. </div>
          <div class="mt-[30px]">
            <div class="uppercase text-white text-bold">How to qualify</div>
            <ul>
            <li class="text-[#ccc] mt-[12px]">- Register or login on vbet.com.</li>
            <li class="text-[#ccc] mt-[12px]">- Successfully verify your account: go to My Profile > Verify Account → Upload your identity documents from the Documents page and wait for your documents to be approved.</li>
            <li class="text-[#ccc] mt-[12px]">- Place a min of €2 or more single or multiple bet with a min. of 1.5 odds.</li>
            <li class="text-[#ccc] mt-[12px]">- Get 100% up to €15 Free Bet on your first qualifying bet.</li>
            </ul>
            <div class="p-[12px] flex mt-[32px]">
              <a class="rounded-[8px] bg-light-green-500 text-white uppercase px-[20px] py-[12px]">Register Now</a>
            </div>
            <div class="uppercase text-white text-bold">HOW TO USE FREE BET</div>
            <ul>
              <li class="text-[#ccc] mt-[12px]">- The max Free Bet amount earned in this promotion is €15. </li>
              <li class="text-[#ccc] mt-[12px]">- Free Bet will be credited after calculation of qualifying bet</li>
              <li class="text-[#ccc] mt-[12px]">- Place a min of €2 or more single or multiple bet with a min. of 1.5 odds.</li>
              <li class="text-[#ccc] mt-[12px]">- Free Bet will be valid for 5 days after receipt</li>
            </ul>
            <div class="p-[12px] flex mt-[32px]">
              <a class="rounded-[8px] bg-light-green-500 text-white uppercase px-[20px] py-[12px]">Bet Now</a>
            </div>
            <div class="mt-32px">General Terms & Conditions</div>
            <div class="text-[#ccc] mt-[4px]">1. The promotion is available from June 20, 2023 for VBET Players only.</div>
            <div class="text-[#ccc] mt-[4px]">2. Only verified users and first-time bettors can qualify for this offer.</div>
            <div class="text-[#ccc] mt-[4px]">3. Only qualified users can get a bonus during the promotion.</div>
            <div class="text-[#ccc] mt-[4px]">4. Bets placed with free bets or bonus money do not participate in this promotion.</div>
            <div class="text-[#ccc] mt-[4px]">5. The promotion is available in all currencies with minimum bet and free bet amount equivalents.</div>
            <div class="text-[#ccc] mt-[4px]">6. Each Player can qualify for this promotion only once.</div>
            <div class="text-[#ccc] mt-[4px]">7. Each Player confirms to have read and accepted all the terms and conditions. This bonus offer is limited to one customer, family, address, shared computer, shared IP address. VBET reserves the right to cancel the bonus and block the account if they discover that the Player is misusing this bonus offer or is opening multiple accounts.</div>
            <div class="text-[#ccc] mt-[4px]">8. Players who open more than one account and violate the bonus offer will not be paid and their accounts will be blocked.</div>
            <div class="text-[#ccc] mt-[4px]">9. The offer is not valid when the bet is calculated with the odds of "1".</div>
            <div class="text-[#ccc] mt-[4px]">10. Any bet with the full or partial Cash Out feature will not be taken into account and will not participate in the promotion.</div>
            <div class="text-[#ccc] mt-[4px]">11. The offer is not valid when the Players' first bet is calculated with "1" odds.</div>
            <div class="text-[#ccc] mt-[4px]">12. BetBuilder bets do not participate in this promotion.</div>
            <div class="text-[#ccc] mt-[4px]">13. VBET has the right to amend the terms of the offer, cancel or renew the offer, or refuse to allow participation at any time without prior notice. This means that VBET may limit or refuse to allow customers to take part in this or any other offer.</div>
            <div class="text-[#ccc] mt-[4px]">14. VBET reserves the right to change the bonus rules, cancel or stop the bonus at any time.</div>
            <div class="text-[#ccc] mt-[4px]">15. Please be aware that if "No" is displayed in the "Eligible for bonus" subsection of the "Personal Details" section determined by the automatic system, it indicates that they have not qualified for any bonuses offered on the website.</div>
            <div class="text-[#ccc] mt-[4px]">16. The VBET Regulations, General Terms & Conditions also apply.</div>
          </div>
        </div>
      `,
    },
    {
      id: "5",
      title: "200% Up to $250 + 100 fs welcome bonus",
      image: "assets/images/pages/promotions/claim-enter-the-game.webp",
      detail: `
        <div>You</div>
      `,
    },
    {
      id: "6",
      title: "Refer-a-friend bonus",
      image: "assets/images/pages/promotions/sharing-is-caring.webp",
      detail: `
        <div class="text-white">Him</div>
      `,
    },
    {
      id: "7",
      title: "100% welcome offer on sportsbook",
      image: "assets/images/pages/promotions/welcome-bonus.webp",
      detail: `
        <div class="max-h-[calc(100%-60px)] overflow-auto">
          <div class="text-white">VBET offers to feel the rhythm of the Sports world with 100% up to €15 Free Bet.</div>
          <div class="text-white mt-[30px]">Complete qualifying steps to be 100% welcomed to VBET. </div>
          <div class="mt-[30px]">
            <div class="uppercase text-white text-bold">How to qualify</div>
            <ul>
            <li class="text-[#ccc] mt-[12px]">- Register or login on vbet.com.</li>
            <li class="text-[#ccc] mt-[12px]">- Successfully verify your account: go to My Profile > Verify Account → Upload your identity documents from the Documents page and wait for your documents to be approved.</li>
            <li class="text-[#ccc] mt-[12px]">- Place a min of €2 or more single or multiple bet with a min. of 1.5 odds.</li>
            <li class="text-[#ccc] mt-[12px]">- Get 100% up to €15 Free Bet on your first qualifying bet.</li>
            </ul>
            <div class="p-[12px] flex mt-[32px]">
              <a class="rounded-[8px] bg-light-green-500 text-white uppercase px-[20px] py-[12px]">Register Now</a>
            </div>
            <div class="uppercase text-white text-bold">HOW TO USE FREE BET</div>
            <ul>
              <li class="text-[#ccc] mt-[12px]">- The max Free Bet amount earned in this promotion is €15. </li>
              <li class="text-[#ccc] mt-[12px]">- Free Bet will be credited after calculation of qualifying bet</li>
              <li class="text-[#ccc] mt-[12px]">- Place a min of €2 or more single or multiple bet with a min. of 1.5 odds.</li>
              <li class="text-[#ccc] mt-[12px]">- Free Bet will be valid for 5 days after receipt</li>
            </ul>
            <div class="p-[12px] flex mt-[32px]">
              <a class="rounded-[8px] bg-light-green-500 text-white uppercase px-[20px] py-[12px]">Bet Now</a>
            </div>
            <div class="mt-32px">General Terms & Conditions</div>
            <div class="text-[#ccc] mt-[4px]">1. The promotion is available from June 20, 2023 for VBET Players only.</div>
            <div class="text-[#ccc] mt-[4px]">2. Only verified users and first-time bettors can qualify for this offer.</div>
            <div class="text-[#ccc] mt-[4px]">3. Only qualified users can get a bonus during the promotion.</div>
            <div class="text-[#ccc] mt-[4px]">4. Bets placed with free bets or bonus money do not participate in this promotion.</div>
            <div class="text-[#ccc] mt-[4px]">5. The promotion is available in all currencies with minimum bet and free bet amount equivalents.</div>
            <div class="text-[#ccc] mt-[4px]">6. Each Player can qualify for this promotion only once.</div>
            <div class="text-[#ccc] mt-[4px]">7. Each Player confirms to have read and accepted all the terms and conditions. This bonus offer is limited to one customer, family, address, shared computer, shared IP address. VBET reserves the right to cancel the bonus and block the account if they discover that the Player is misusing this bonus offer or is opening multiple accounts.</div>
            <div class="text-[#ccc] mt-[4px]">8. Players who open more than one account and violate the bonus offer will not be paid and their accounts will be blocked.</div>
            <div class="text-[#ccc] mt-[4px]">9. The offer is not valid when the bet is calculated with the odds of "1".</div>
            <div class="text-[#ccc] mt-[4px]">10. Any bet with the full or partial Cash Out feature will not be taken into account and will not participate in the promotion.</div>
            <div class="text-[#ccc] mt-[4px]">11. The offer is not valid when the Players' first bet is calculated with "1" odds.</div>
            <div class="text-[#ccc] mt-[4px]">12. BetBuilder bets do not participate in this promotion.</div>
            <div class="text-[#ccc] mt-[4px]">13. VBET has the right to amend the terms of the offer, cancel or renew the offer, or refuse to allow participation at any time without prior notice. This means that VBET may limit or refuse to allow customers to take part in this or any other offer.</div>
            <div class="text-[#ccc] mt-[4px]">14. VBET reserves the right to change the bonus rules, cancel or stop the bonus at any time.</div>
            <div class="text-[#ccc] mt-[4px]">15. Please be aware that if "No" is displayed in the "Eligible for bonus" subsection of the "Personal Details" section determined by the automatic system, it indicates that they have not qualified for any bonuses offered on the website.</div>
            <div class="text-[#ccc] mt-[4px]">16. The VBET Regulations, General Terms & Conditions also apply.</div>
          </div>
        </div>
      `,
    },
    {
      id: "8",
      title: "200% Up to $250 + 100 fs welcome bonus",
      image: "assets/images/pages/promotions/claim-enter-the-game.webp",
      detail: `
        <div>You</div>
      `,
    },
    {
      id: "9",
      title: "Refer-a-friend bonus",
      image: "assets/images/pages/promotions/sharing-is-caring.webp",
      detail: `
        <div class="text-white">Him</div>
      `,
    },
  ],
};

export const PromotionList = () => {
  const { category: cmsId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [detailVisible, setDetailVisible] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState({});
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const isMd = useScreenMd();

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getCMSList("promotions")).then((res) => {
      const list = res.payload || [];
      setData(list.filter(item => item.section === cmsId && item.active && currentLanguageId === item.language && (isMd ? item.device === 1 : item.device === 2)));
    });
  }, [dispatch, cmsId, currentLanguageId, isMd]);

  const onDetailClick = (data) => {
    setSelectedPromotion(data);
    setDetailVisible(true);
  };

  const onDetailClose = () => {
    setDetailVisible(false);
    setSelectedPromotion({});
  };

  if (!cmsId) {
    navigate("/promotions/popular");
  }

  return (
    <>
      <Box
        aria-label="PromotionList"
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-[12px] gap-[12px]"
      >
        {data.map((item) => (
          <PromotionCard key={item.id} {...item} onClick={() => onDetailClick(item)} />
        ))}
      </Box>
      <PromotionDetail key={selectedPromotion?.id} visible={detailVisible} onClose={onDetailClose} {...selectedPromotion} />
    </>
  );
};
