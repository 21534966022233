import React from 'react';
import { dateToText } from "src/app/utils";
import i18n from "src/i18n";

export const PrintTickets = React.forwardRef(({ tickets, betType, totalBetAmount, totalOdds, potentialWin, marketDic }, ref) => {
  const selectedPaperStyle = {
    padding: '8px',
    fontFamily: 'monospace, sans-serif',
    backgroundColor: '#ffffff',
    color: '#000000',
    width: '300px', // Default width for 80mm paper; adjust to '220px' for 58mm
    fontSize: '12px',
    lineHeight: '1.2',
  };

  return (
    <div style={selectedPaperStyle} ref={ref}>
      {/* Header Section */}
      <div style={{ textAlign: 'center', marginBottom: '8px', padding: '4px 0', borderBottom: '1px solid #000' }}>
        <h1 style={{ fontWeight: 'bold', fontSize: '16px', margin: 0 }}>{i18n.t('Betslip')}</h1>
      </div>

      {/* Summary Section */}
      <div style={{ marginBottom: '8px', paddingBottom: '4px', borderBottom: '1px dashed #000' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px' }}>
          <p><strong>{i18n.t("Bet_Type")}: </strong>{betType}</p>
          <p><strong>{i18n.t("Total_Stake")}: </strong>{totalBetAmount}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px' }}>
          <p><strong>{i18n.t("Total_Odds")}: </strong>{totalOdds}</p>
          <p><strong>{i18n.t("Potential_Win")}: </strong>{potentialWin}</p>
        </div>
      </div>

      {/* Tickets Table */}
      <h2 style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '4px' }}>{i18n.t('Tickets')}:</h2>
      
      <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '11px' }}>
        <thead>
          <tr>
            <th style={{ borderBottom: '1px solid #000', paddingBottom: '4px', textAlign: 'left' }}>{i18n.t("Event")}</th>
            <th style={{ borderBottom: '1px solid #000', paddingBottom: '4px', textAlign: 'left' }}>{i18n.t("Odds")}</th>
            <th style={{ borderBottom: '1px solid #000', paddingBottom: '4px', textAlign: 'left' }}>{i18n.t("Stake")}</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket, index) => {
            let name = ticket.detail.split(";")[1];
            name = name === "1" ? "Home" : name === "2" ? "Away" : name === "U" ? "Under" : name === "O" ? "Over" : name;

            if (ticket.detail.includes("Handicap") && !ticket.detail.includes(",0")) {
              name = ticket.detail.includes("-") ? (name === "Home" ? `-${name}` : `+${name}`) : (name === "Home" ? `+${name}` : `-${name}`);
            }

            let prefix = marketDic?.[ticket.market_id] || ticket.detail.split(";")[0];
            const parts = ticket.detail.split(";")[0].split(",");
            prefix = parts[0].includes("%P") ? parts[0].replace("%P", " ") + parts[1] : prefix.replace("%1", "Home").replace("%2", "Away");

            return (
              <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f7f7f7' }}>
                <td style={{ padding: '4px 0', fontSize: '11px', textAlign: 'left' }}>
                  {ticket.homeTeam} vs {ticket.awayTeam} <br />
                  <small>{dateToText(ticket.date)} - {`${prefix}: ${name}`}</small>
                </td>
                <td style={{ padding: '4px', fontSize: '11px', textAlign: 'center' }}>{ticket.odds}</td>
                <td style={{ padding: '4px', fontSize: '11px', textAlign: 'center' }}>{ticket.stake_amount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});
