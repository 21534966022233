import FuseSuspense from "@fuse/core/FuseSuspense";
import AppContext from "app/AppContext";
import ToolbarLayout1 from "./components/ToolbarLayout1";
import { styled } from "@mui/material/styles";
import { memo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { selectFuseCurrentLayoutConfig } from "app/store/fuse/settingsSlice";
import { MobileFooter } from "./components/MobileFooter";
import { useEffect } from "react";
import { selectDatafeedDown } from "src/app/main/store/prematchSlice";

const Root = styled("div")(({ theme, config }) => ({
  ...(config.mode === "boxed" && {
    clipPath: "inset(0)",
    maxWidth: `${config.containerWidth}px`,
    margin: "0 auto",
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  }),
  ...(config.mode === "container" && {
    "& .container": {
      maxWidth: `${config.containerWidth}px`,
      width: "100%",
      margin: "0 auto",
    },
  }),
}));

function Layout1() {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  // const themes = useSelector(selectFuseThemesSettings);
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const datafeedDown = useSelector(selectDatafeedDown);

  useEffect(() => {
    if (datafeedDown) {
      // toast.error("Datafeed server is down");
    }
  }, [datafeedDown]);

  // const tournamentStatus = useSelector(selectTournamentStatus);
  // const selectedSport = useSelector(getselectedSport);
  // const sportData = useSelector((state) => selectSport(state, selectedSport.name));
  // const prematchStatus = useSelector(selectPrematchStatus);
  // const prematchSportId = useSelector(selectPrematchSportId);
  // const liveData = useSelector(getIsLive);
  // const [params] = useSearchParams();
  // const live = params.get("live") ?? liveData;

  // useEffect(() => {
  //   dispatch(getCasinoTypes());
  // }, []);

  return (
    <Root id="fuse-layout" config={config} className="w-full">
      <ToolbarLayout1 />
      <FuseSuspense>{useRoutes(routes)}</FuseSuspense>
      <MobileFooter />
    </Root>
  );
}

export default memo(Layout1);
