import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const apiBaseUrl = jwtServiceConfig.apiUrl;

const topLeaguesAdapter = createEntityAdapter();

export const getTopLeagues = createAsyncThunk("sportsApp/getTopLeagues", async (option) => {
  const { languageId, sport_id, betType, timerFilterValue } = option;
  let url = '';
  if (betType === "Live") {
    url = `${apiBaseUrl}topLeagues/${languageId}/${sport_id}/${betType}`
  } else {
    url = `${apiBaseUrl}topLeagues/${languageId}/${sport_id}/${betType}?timerFilter=${timerFilterValue}`;
  }
  const res = await axios.get(url);
  return res.data?.top_tournaments.sort((a, b) => a.tournament_order - b.tournament_order) ?? [];
});

export const getTopLeaguesForType = createAsyncThunk("sportsApp/getTopLeaguesForType", async (option) => {
  const { languageId, betType, timerFilterValue } = option;
  let url = '';

  if (betType === "Live") {
    url = `${apiBaseUrl}topLeagues/${languageId}/${betType}`
  } else {
    url = `${apiBaseUrl}topLeagues/${languageId}/${betType}?timerFilter=${timerFilterValue}`;
  }

  const res = await axios.get(url);
  return res.data?.top_tournaments.sort((a, b) => a.tournament_order - b.tournament_order) ?? [];
});

const initialState = topLeaguesAdapter.getInitialState({
  status: "idle",
});

const topLeaguesSlice = createSlice({
  name: "sportsApp/topLeagues",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTopLeagues.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTopLeagues.fulfilled, (state, action) => {
        topLeaguesAdapter.setAll(state, action.payload);
        state.status = "fulfilled";
      })
      .addCase(getTopLeagues.rejected, (state) => {
        state.status = "rejected";
      })

      .addCase(getTopLeaguesForType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTopLeaguesForType.fulfilled, (state, action) => {
        topLeaguesAdapter.setAll(state, action.payload);
        state.status = "fulfilled";
      })
      .addCase(getTopLeaguesForType.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export const selectTopLeaguesStatus = (state) => state.sportsApp.topLeagues.status;
export const { selectAll: selectTopLeagues } = topLeaguesAdapter.getSelectors((state) => state.sportsApp.topLeagues);

export default topLeaguesSlice.reducer;
