const { Affiliate } = require("./Affiliate");

const AffiliateConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "become-affiliate",
      element: <Affiliate />,
    },
  ],
};

export default AffiliateConfig;
