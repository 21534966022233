import { Stack, useTheme } from "@mui/material";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useScreenMd } from "src/app/hooks/useScreens";
import { getCMSList } from "src/app/main/store/cmsSlice";
import { selectSystem } from "src/app/main/store/systemSettingsSlice";
import i18n from "src/i18n";

const sections = [
  { title: "Company", value: "company" },
  { title: "Terms_of_Privacy", value: "terms_and_privacy" },
  { title: "Rules", value: "rules" },
  { title: "Responsible_Gambling", value: "responsible_gambling" },
  { title: "FAQ", value: "faq" },
]

function FooterMenu() {
  const [isActiveMenu, setIsActiveMenu] = useState();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const systemSettings = useSelector(selectSystem);
  const theme = useTheme();
  const isMd = useScreenMd()

  const mobileMenuToggle = () => {
    setIsActiveMenu(!isActiveMenu);
  };

  useEffect(() => {
    dispatch(getCMSList("pages_of_contents")).then((res) => {
      const list = res.payload || [];
      setData(list.filter(item => !item.isdeleted && item.active && item.language === currentLanguageId && (isMd? item.device === 1 : item.device === 2)));
    });
  }, [currentLanguageId, isMd]);

  return (
    <Stack
      className="items-center justify-between w-full px-[24px] md:flex-row"
    >
      <Link href="/"
        className="mb-[20px]"
      >
        {systemSettings.logo && (
          <img
            src={systemSettings.logo}
            alt="logo"
            className="w-[140px] h-[30px] object-contain"
          />
        )}
      </Link>
      {sections.map((section, index) => (
        <div key={index} className={isActiveMenu ? "active mb-[12px]" : "mb-[12px]"}>
          <a onClick={mobileMenuToggle} className="justify-center text-[12px] leading-[24px] font-500 uppercase !text-white">
            {i18n.t(section.title)}
            <i className="fa-solid fa-angle-down"></i>
          </a>
          <ul>
            {data
              .filter(item => item.section === section.value)
              .map((item, subIndex) => (
                <li key={item.id} className="flex justify-center text-sm">
                  <Link to={`/information/${item.section}/${item.id}`} className="text-sm opacity-70">{i18n.t(item.title)}</Link>
                </li>
              ))}
          </ul>
        </div>
      ))}
    </Stack>
  );
}

export default FooterMenu;