const locale = {
  CASINO_BETS: 'رهانات الكازينو',
  HIGH_ROLLERS: 'بكرات عالية',
  RARE_WINS: 'انتصارات نادرة',
  GAME: 'لعبة',
  USER: 'مستخدم',
  TIME: 'وقت',
  BET_AMOUNT: 'مبلغ الرهان',
  MULTIPLIER: 'المضاعف',
  PAYOUT: 'سيصرف',
  MORE: 'أكثر',
  LIVE_CASINO: 'الكازينو المباشر',
  CASINO: 'كازينو'
};

export default locale;
