import { combineReducers } from "@reduxjs/toolkit";
import ui from "./uiSlice";
import favs from "./favSlice";
import tickets from "./ticketSlice";
import prematch from "./prematchSlice";
import tournament from "./tournamentSlice";
import topLeagues from "./topLeaguesSlice";
import boostedOdds from "./boostedOdds";
import systemSettings from "./systemSettingsSlice";
import affiliate from "./affiliateSlice"

const reducer = combineReducers({
  ui,
  favs,
  tickets,
  prematch,
  tournament,
  topLeagues,
  boostedOdds,
  systemSettings,
  affiliate
});

export default reducer;
