// import { fuseDark, skyBlue } from "@fuse/colors";
// import { blueGrey } from "@mui/material/colors";

export const lightPaletteText = {
  primary: "rgb(17, 24, 39)",
  secondary: "rgb(107, 114, 128)",
  disabled: "rgb(149, 156, 169)",
};

export const darkPaletteText = {
  primary: "rgb(255,255,255)",
  secondary: "rgb(148, 163, 184)",
  disabled: "rgb(156, 163, 175)",
};

const themesConfig = {
  default: {
    title: "Default",
    palette: {
      mode: "light",
      divider: "#00000020",
      text: lightPaletteText,
      common: {
        black: "rgb(17, 24, 39)",
        white: "rgb(255, 255, 255)",
      },
      primary: {
        light: "#64748b",
        main: "#1e293b",
        dark: "#0f172a",
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        light: "#818cf8",
        main: "#4f46e5",
        dark: "#3730a3",
        contrastText: darkPaletteText.primary,
      },
      background: {
        default: "#f1f5f9",
        paper: "#FFFFFF",
        header: "#021c26",
        footer: "#021c26",
        navigationBar: "#2C374F",
        navigationSelected: "#1c273f",
        mobileFooter: "#edf0f2"
      },
      error: {
        light: "#ffcdd2",
        main: "#f44336",
        dark: "#b71c1c",
      },
      success: {
        main: "#4da533",
        contrastText: "white",
      },
      warning: {
        main: "#ef6330",
        contrastText: "white",
      },
      cardHeader: {
        main: "#252f3e",
        text: "white",
      },
      cardHeaderButton: {
        text: {
          default: "white",
        }
      }
    },
    status: {
      danger: "orange",
    },
  },
  defaultDark: {
    title: "Dark Carbon-Purple",
    palette: {
      mode: "dark",
      divider: "rgba(241,245,249,.12)",
      text: darkPaletteText,
      common: {
        black: "rgb(17, 24, 39)",
        white: "rgb(255, 255, 255)",
      },
      primary: {
        light: "#b39ddb",
        main: "#7e57c2",
        dark: "#512da8",
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        light: "#9fa8da",
        main: "#5c6bc0",
        dark: "#3949ab",
        contrastText: darkPaletteText.primary,
      },
      background: {
        paper: "#5124a5",
        default: "#1c1c40",
        header: "#35166e",
        footer: "#021c26",
        navigationBar: "#260B59",
        navigationSelected: "#6A3DBE",
        mobileFooter: "#edf0f2"
      },
      error: {
        light: "#ef9a9a",
        main: "#e57373",
        dark: "#d32f2f",
      },
      cardHeader: {
        main: "#260B59",
        text: "white",
      },
      cardHeaderButton: {
        text: {
          default: "white",
        }
      }
    },
    status: {
      danger: "#ff7043",
    },
  },
  // greyDark: {
  //   palette: {
  //     mode: "dark",
  //     divider: "rgba(241,245,249,.12)",
  //     text: darkPaletteText,
  //     primary: {
  //       light: fuseDark[200],
  //       main: fuseDark[700],
  //       dark: fuseDark[800],
  //       contrastText: darkPaletteText.primary,
  //     },
  //     secondary: {
  //       light: skyBlue[100],
  //       main: skyBlue[500],
  //       dark: skyBlue[900],
  //       contrastText: lightPaletteText.primary,
  //     },
  //     background: {
  //       paper: blueGrey[700],
  //       default: blueGrey[900],
  //     },
  //     error: {
  //       light: "#ffcdd2",
  //       main: "#f44336",
  //       dark: "#b71c1c",
  //     },
  //   },
  //   status: {
  //     danger: "orange",
  //   },
  // },
};

export default themesConfig;
