import { Bonus } from "./outlets/Bonus/bonus";
import { CasinoHistory } from "./outlets/CasinoHistory";
import { GameHistory } from "./outlets/GameHistory";
import { Preferences } from "./outlets/Preferences";
import { Transaction } from "./outlets/TransactionHistory/Transaction";
import { Verification } from "./outlets/Verification";

const { Settings } = require("./Settings");
const { Profile } = require("./outlets/Profile");
const { SettingContent } = require("./component/SettingContent");

const SettingsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "settings",
      element: <Settings />,
      children: [
        { path: "", element: <SettingContent /> },
        {
          path: "profile",
          element: <Profile />,
        },
        { path: "transaction-history", element: <Transaction /> },
        { path: "preferences", element: <Preferences /> },
        { path: "game-history", element: <GameHistory /> },
        { path: "casino-history", element: <CasinoHistory /> },
        { path: "verification", element: <Verification /> },
        { path: "bonus", element: <Bonus /> },
      ],
    },
  ],
};

export default SettingsConfig;
