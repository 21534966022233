import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, selectCurrentLanguage, selectLanguages } from "app/store/i18nSlice";
import { Box, IconButton, Modal } from "@mui/material";
import { OddsSettings } from "./LanguageSwitcher/OddsSettings";

function LanguageSwitcherMobile(props) {
  const currentLanguage = useSelector(selectCurrentLanguage);
  const languages = useSelector(selectLanguages);
  // const [slectedLanguage, setSlectedLanguage] = useState("en")
  const [languageMenuVisible, setLanguageMenuVisible] = useState(null);
  const [oddSettingsVisible, setOddSettingsVisible] = useState(false);
  const dispatch = useDispatch();

  function handleLanguageChange(lng) {
    dispatch(changeLanguage(lng.id));
    localStorage.setItem('app-lang', lng.id);
    handleLanguageMenuClose();
  }

  const handleLanguageMenuClose = () => {
    setLanguageMenuVisible(null);
  };

  const languageModal = () => {
    return (
      <>
        <Box
          className="w-screen h-[calc(var(--app-height))] bg-[#ffffff10] fixed left-0 top-0 z-[800] hidden data-[visible]:block"
          sx={{ backdropFilter: "blur(4px)" }}
          onClick={handleLanguageMenuClose}
          data-visible={languageMenuVisible}
        ></Box>
        <Box
          className="w-[calc(100vw-48px)] h-[calc(var(--app-height)-48px)] max-h-[calc(var(--app-height)-24px)] overflow-y-auto bg-[white] fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-[801] hidden data-[visible]:block pt-[60px] rounded-[12px]"
          data-visible={languageMenuVisible}
        >
          <Box className="absolute right-[12px] top-[12px] z-[100]">
            <IconButton onClick={handleLanguageMenuClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {languages.map((lng) => (
            <MenuItem
              key={lng.id}
              onClick={() => handleLanguageChange(lng)}
              className="transition-all duration-150"
              sx={{
                "&:hover": {
                  backgroundColor: "#3C475F",
                },
                borderBottom: "1px solid #22222210",
              }}
            >
              <ListItemIcon className="min-w-20">
                <img
                  className="iconSize iconList mr-10 w-[24px] h-[24px] rounded-full object-cover"
                  src={`assets/images/flags/${lng.flag}.svg`}
                  alt={lng.title}
                />
              </ListItemIcon>
              <ListItemText
                primary={lng.title}
                className="truncate text-[10px]"
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
              />
            </MenuItem>
          ))}
        </Box>
      </>
    );
  };

  return (
    <>
      <Box className="flex items-center h-40 bg-[#ffffff10] rounded-[12px] border !border-white">
        <Button
          onClick={() => setLanguageMenuVisible("visible")}
          className="p-[8px] min-w-fit min-h-fit h-fit hover:bg-[#ffffff33]"
        >
          <img
            className="min-w-[18px] w-[18px] h-[18px] rounded-full object-cover"
            src={`assets/images/flags/${currentLanguage.flag}.svg`}
            alt={currentLanguage.title}
          />
        </Button>
        <img src="assets/images/login/line.svg" alt="line" className="mx-[8px]" />
        <Button
          onClick={() => setOddSettingsVisible(true)}
          className="p-[8px] min-w-fit min-h-fit h-fit hover:bg-[#ffffff33]"
        >
          <img src="assets/images/login/setting.svg" alt="settings" />
        </Button>
      </Box>

      {languageModal()}
      <OddsSettings visible={oddSettingsVisible} onClose={() => setOddSettingsVisible(false)} />
    </>
  );
}

export default LanguageSwitcherMobile;
