import { memo } from "react";
import { useSelector } from "react-redux";
import { NavigationBar } from "./NavigationBar";
import { Box, IconButton, useTheme } from "@mui/material";
import { useState } from "react";
import { UserBox } from "app/shared-components/UserBox";

import LanguageSwitcher from "../../../shared-components/LanguageSwitcher";
import MenuIcon from "@mui/icons-material/Menu";
import LogoNew from "app/shared-components/LogoNew";
import LoginSignUpButtons from "app/shared-components/LoginSignUpButtons";
import { MobileNavBar } from "app/shared-components/MobileNavBar";
import { selectUser } from "app/store/userSlice";
import { selectToolbarTheme } from "app/store/fuse/settingsSlice";
import { useCustomAuth } from "src/app/hooks/useAuth";

function ToolbarLayout1() {
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useSelector(selectUser);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const authenticated = useCustomAuth();
  const theme = useTheme();

  return (
    <>
      <header>
        <Box
          aria-label="headerOutter" 
          className="w-full z-[1002] relative"
          sx={{
            background: theme.palette.background.header || "#021c26",
          }}
        >
          <Box
            aria-label="header"
            className="w-full py-[4px] container xl:!max-w-none mx-auto flex xl:pl-[80px] justify-between items-center h-[69px]"
            sx={{
              WebkitTransition: "background-color 0.2s",
              transition: "background-color 0.2s",
              WebkitBoxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              boxShadow: "0 4px 4px #00000040",
            }}
          >
            <Box className="flex items-center sm:gap-[8px]">
              <IconButton className="md:hidden" onClick={() => setMenuOpen(true)}>
                <MenuIcon className="text-white" />
              </IconButton>
              <LogoNew />
            </Box>
            <Box data-role="headerButtonBox" className="flex gap-[20px]">
              {authenticated && <UserBox />}
              <Box className="hidden sm:block">
                <LanguageSwitcher />
              </Box>
              {!authenticated && <LoginSignUpButtons />}
            </Box>
          </Box>
        </Box>
        <NavigationBar />
      </header>

      <MobileNavBar visible={menuOpen} onClose={() => setMenuOpen(false)} />
    </>
  );
}

export default memo(ToolbarLayout1);
