import React, { memo, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
} from "@mui/material";
import { SortTableHead } from "./SortTableHead/SortTableHead";
import { format } from 'date-fns';
import i18n from "src/i18n";
import { useSelector } from "react-redux";
import { selectCurrentLanguageId } from "app/store/i18nSlice";

// const columns = [
//   {
//     id: "no",
//     label: "No",
//     width: "80px",
//     align: "right",
//   },
//   {
//     id: "type",
//     label: "Type",
//     width: "200px",
//     align: "center",
//   },
//   {
//     id: "date",
//     label: "Date",
//     width: "200px",
//     align: "center",
//     value: (row) => format(new Date(row.date), "MM-dd-yyyy"),
//   },
// ];

// const rows = [
//   {
//     no: "1",
//     type: "Sport",
//     date: "2024-02-11",
//   },
//   {
//     no: "2",
//     type: "Casino",
//     date: "2024-03-08",
//   },
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const CustomTable = memo(({
  columns,
  rows,
  page: pageProp,
  rowPerPage: rowPerPageProp,
  onPageChange,
  onRowPerPageChange,
  noPagination
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("no");
  const [page, setPage] = useState(pageProp || 0);
  const [rowsPerPage, setRowsPerPage] = useState(rowPerPageProp || 25);
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (pageProp) {
      setPage(pageProp);
    }
  }, [pageProp]);

  useEffect(() => {
    if (rowPerPageProp) {
      setRowsPerPage(rowPerPageProp);
    }
  }, [rowPerPageProp])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (onPageChange) {
      onPageChange(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (onRowPerPageChange) {
      onRowPerPageChange(parseInt(event.target.value, 10));
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(0);
  };

  const visibleRows = useMemo(() => {
    if (!rows) return [];
    return stableSort(rows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [order, orderBy, page, rowsPerPage, rows]);

  if (isMobile) {
    return (
      <Box>
        {visibleRows.length === 0 ? (
          <Box sx={{ padding: "16px", textAlign: "center" }}>
            <Typography variant="subtitle1">No Data Available</Typography>
          </Box>
        ) : (
          visibleRows.map((row, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "16px",
              }}
            >
              {columns.map((column) => {
                let value = column.value
                      ? column.value(row)
                      : row[column.id];
                if(column.id == "transaction_date") value = formatPopularDateFormat(value);
                else if(column.id == "amount" || column.id == "opening_balance" || column.id == "closing_balance") value = value.toFixed(2);
                return (
                  <Box
                    key={column.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      {column.label}
                    </Typography>
                    <Typography variant="body2">{value}</Typography>
                  </Box>
                );
              })}

            </Box>
          ))
        )}
        {!noPagination && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    );
  }

  function formatPopularDateFormat(input) {
    const date = new Date(input);
  
    return format(date, "MMMM d, yyyy h:mm a");  // Example: "September 9, 2024 5:18 PM"
  }

  return (
    <Paper className="w-full mb-[24px] overflow-hidden">
      <TableContainer sx={{ minHeight: "300px" }}>
        <Table
          sx={{
            "& .Mui-active": { color: "white !important" },
            minHeight: "250px"
          }}
        >
          <SortTableHead
            headCells={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {visibleRows.length === 0 ? (
              <TableRow className="h-full">
                <TableCell colSpan={columns.length} align="center">
                  <Typography variant="subtitle1">{i18n.t("No_Data_Available")}</Typography>
                </TableCell>
              </TableRow>
            ) : (
              visibleRows.map((row, index) => (
                <TableRow hover key={index}>
                  {columns.map((column) => {
                    let value = column.value
                      ? column.value(row)
                      : row[column.id];
                    if(column.id == "transaction_date") value = formatPopularDateFormat(value);
                    else if(column.id == "amount" || column.id == "opening_balance" || column.id == "closing_balance") value = value.toFixed(2);
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {i18n.t(value)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!noPagination && (<TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />)}
    </Paper>
  );
});
