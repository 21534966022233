// import i18next from 'i18next';

import { lazy } from "react";
import { Navigate } from "react-router-dom";

const Sports = lazy(() => import("./Sports"));
const EventDetail = lazy(() => import("./outlets/EventDetail"));
const MainContent = lazy(() => import("./outlets/MainContent"));
const FavoriteSports = lazy(() => import("./outlets/FavoriteSports"));

const SportsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "sports",
      element: <Sports />,
      children: [
        {
          path: "",
          element: <Navigate to="all" />,
        },
        {
          path: "leagues",
          element: <MainContent key='leagues' />,
          children: [{ path: ":sportParam", element: null }],
        },
        {
          path: ":categoryParam",
          element: <MainContent key="sport-page" />,
        },
        {
          path: "detail/:id",
          element: <EventDetail />,
        },
        {
          path: "favorites",
          element: <FavoriteSports />,
          children: [{ path: ":favLeaguesParam", element: <div></div> }],
        },
      ],
    },
  ],
};

export default SportsConfig;
