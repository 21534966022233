import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import toast from "react-hot-toast";
import { Alert } from "@mui/material";


const api_domain = jwtServiceConfig.apiUrl;

export const updateProfile = createAsyncThunk("settingApp/updateProfile", async (data) => {
  try {
    const response = await axios.put(`${api_domain}user/updateDetail`, data);
    toast.custom(
      <Alert severity="success">Profile has been successfully saved</Alert>
    );
    return response.data;
  } catch (err) {
    // if (err?.response?.data?.status) {
      toast.custom(
        <Alert severity="error">{err.response.data.user_error}</Alert>
      );
      return err?.response;
      // throw err
    // }
  }
});

export const getProfile = createAsyncThunk("settingApp/getProfile", async () => {
  const response = await axios.put(`${api_domain}user/getDetail`);
  return response.data.user;
});

export const updateUserPreference = createAsyncThunk("settingApp/updateUserPreference", async (data) => {
  try {
    const response = await axios.put(`${api_domain}user/updateUserPreference`, data);
    toast.custom(
      <Alert severity="success">Profile has been successfully saved</Alert>
    );
    return response.data;
  } catch (err) {
    // if (err?.response?.data?.status) {
      toast.custom(
        <Alert severity="error">{err.response.data.user_error}</Alert>
      );
      return err?.response;
      // throw err
    // }
  }
});

export const getUserPreference = createAsyncThunk("settingApp/getUserPreference", async () => {
  const response = await axios.put(`${api_domain}user/getUserPreference`);
  return response.data.user;
});

export const updatePassword = createAsyncThunk("settingApp/updatePassword", async (data) => {
  try {
    const response = await axios.post(`${api_domain}auth/change-password`, data);
    toast.custom(
      <Alert severity="success">Password has been successfully changed</Alert>
    );
    return response.data;
  } catch (err) {
    // if (err?.response?.data?.status) {
      toast.custom(
        <Alert severity="error">{err.response.data.user_error}</Alert>
      );
      return err?.response;
      // throw err
    // }
  }
});

export const verifyEmail = createAsyncThunk("settingApp/verifyEmail", async () => {
  try {
    const response = await axios.post(`${api_domain}auth/user-verify-email`);
    toast.custom(
      <Alert severity="success">Verification email has been successfully sent to your email</Alert>
    );
    return response.data.user;
  } catch (err) {
    // if (err?.response?.data?.status) {
      toast.custom(
        <Alert severity="error">{err.response.data.user_error}</Alert>
      );
      return err?.response;
      // throw err
    // }
  }
});

const profileAdapter = createEntityAdapter([]);


const profileSlice = createSlice({
  name: "settingApp/profile",
  initialState: profileAdapter.getInitialState({
    profile: {},
    preference: {},
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
    builder.addCase(getUserPreference.fulfilled, (state, action) => {
      state.preference = action.payload;
    });
  },
});

export const selectProfile = ({ profile }) => profile.profile;

export default profileSlice.reducer;
