import { Betslip } from "./Betslip";

export const BetslipConfig = {
  settigns: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "betslip",
      element: <Betslip />,
    },
  ],
};

export default BetslipConfig;
