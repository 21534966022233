import { Box, Typography } from "@mui/material";

export const ContentCard = ({ title, icon, children }) => {
  return (
    <Box aria-label="contentCard" className="w-full flex flex-col min-w-[310px]">
      <Box className="rounded-t-[12px] flex gap-[8px] items-center text-white text-[14px] uppercase font-500 bg-[#021C26] py-[8px] px-[16px] w-full">
        {icon && <img src={icon} alt="" />}
        <Typography className="text-[14px] text-[#04CEB0] font-700 uppercase">{title}</Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
