import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getCountrylist = createAsyncThunk("commonApp/countrylist/getCountrylist", async () => {
  const response = await axios.get(`https://restcountries.com/v3.1/all`);
  // debugger;
  const data = await response.data;
  return data === undefined ? null : data;
});

const countryListAdapter = createEntityAdapter({});

const countryListSlice = createSlice({
  name: "commonApp/countrylist",
  initialState: countryListAdapter.getInitialState({}),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCountrylist.fulfilled, (_, action) => action.payload);
  },
});

export const selectCountryList = ({ commonApp }) => commonApp?.countrylist || [];

export default countryListSlice.reducer;
