import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const ModalBox = ({ visible = false, onClose = () => {}, header = null, body = null }) => {
  return (
    <>
      <Box
        aria-label="ModalBoxBackdrop"
        className="w-screen h-[calc(var(--app-height))] fixed left-0 top-0 z-[1100] bg-[#D1D1D130] hidden opacity-0 data-[visible=true]:block data-[visible=true]:opacity-100 transition-all duration-150"
        style={{ backdropFilter: "blur(4px)" }}
        data-visible={visible}
        onClick={onClose}
      ></Box>
      <Box
        aria-label="ModalBox"
        data-visible={visible}
        className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-[55%] z-[1101] hidden opacity-0 data-[visible=true]:block data-[visible=true]:opacity-100 transition-all duration-150"
      >
        <Box className="rounded-t-[12px] bg-[#021C26] relative" style={{ boxShadow: "0 4px 8px #000000a0" }}>
          {header}
          <button onClick={onClose} className="absolute right-[12px] top-1/2 -translate-y-1/2">
            <CloseIcon className="text-white" />
          </button>
        </Box>
        <Box className="rounded-b-[12px] bg-white" style={{ boxShadow: "0 0 36px #95979750" }}>
          {body}
        </Box>
      </Box>
    </>
  );
};
