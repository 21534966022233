// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the phone input container */
.react-tel-input .form-control {
  border: 1px solid #ccc;
  height: 40px;
  font-size: 16px;
  padding-left: 48px;
  border-radius: 30px;
  width: 100% !important;
}

/* Adjust the flag dropdown button */
.react-tel-input .flag-dropdown {
  background: transparent;
  border-left: none;
  border: 1px solid #ccc;
  border-radius: 30px 0 0 30px;
}

/* Dropdown menu styles */
.react-tel-input .country-list {
  border: 1px solid #ccc;
  box-shadow: none;
  border-radius: 20px;
}

/* Hover and focus states */
.react-tel-input .form-control:hover,
.react-tel-input .form-control:focus {
  border-color: #888;
}
`, "",{"version":3,"sources":["webpack://./src/styles/customPhoneCode.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;EACE,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA,oCAAoC;AACpC;EACE,uBAAuB;EACvB,iBAAiB;EACjB,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA,yBAAyB;AACzB;EACE,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA,2BAA2B;AAC3B;;EAEE,kBAAkB;AACpB","sourcesContent":["/* Styles for the phone input container */\n.react-tel-input .form-control {\n  border: 1px solid #ccc;\n  height: 40px;\n  font-size: 16px;\n  padding-left: 48px;\n  border-radius: 30px;\n  width: 100% !important;\n}\n\n/* Adjust the flag dropdown button */\n.react-tel-input .flag-dropdown {\n  background: transparent;\n  border-left: none;\n  border: 1px solid #ccc;\n  border-radius: 30px 0 0 30px;\n}\n\n/* Dropdown menu styles */\n.react-tel-input .country-list {\n  border: 1px solid #ccc;\n  box-shadow: none;\n  border-radius: 20px;\n}\n\n/* Hover and focus states */\n.react-tel-input .form-control:hover,\n.react-tel-input .form-control:focus {\n  border-color: #888;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
