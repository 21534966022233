import { useMediaQuery } from "@mui/material";
import { SCREEN_LG, SCREEN_MD, SCREEN_SM } from "../constants";

export const useScreenSm = () => {
  return useMediaQuery((theme) => theme.breakpoints.up(SCREEN_SM));
};

export const useScreenMd = () => {
  return useMediaQuery((theme) => theme.breakpoints.up(SCREEN_MD));
};
export const useScreenLg = () => {
  return useMediaQuery((theme) => theme.breakpoints.up(SCREEN_LG));
};

export const useBreakpointUp = (breakpoint) => {
  return useMediaQuery((theme) => theme.breakpoints.up(breakpoint));
};

export const useScreens = () => {
  const isSm = useScreenSm();
  const isMd = useScreenMd();
  const isLg = useScreenLg();

  return { isSm, isMd, isLg };
};
