import { Box, Typography, useTheme } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearTicket, selectIsBetSaving } from "src/app/main/store/ticketSlice";

export const SettingCard = (props) => {
  const { panelVisible, onSettingClick, onPanelClose } = props;
  const [deleteConfirming, setDeleteConfirming] = useState(false);
  const dispatch = useDispatch();
  const timerRef = useRef();
  const theme = useTheme();
  const isBetting = useSelector(selectIsBetSaving)

  useEffect(() => {
    if (deleteConfirming) timerRef.current = setTimeout(() => setDeleteConfirming(false), 3000);
    if (!deleteConfirming && timerRef.current) clearTimeout(timerRef.current);
    return () => clearTimeout(timerRef.current);
  }, [deleteConfirming]);

  const handleDeleteAll = () => {
    if (deleteConfirming) {
      dispatch(clearTicket());
      setDeleteConfirming(false);
    } else {
      setDeleteConfirming(true);
    }
  };

  return (
    <Box
      className="p-[12px] border-b border-b-[#00000030] last:border-b-0 hover:bg-[#00000008] transition-all duration-100 flex justify-end items-center gap-[4px] relative"
      color={theme.palette.text.primary}
      aria-label="SettingCard"
    >
      <button className="p-[4px]" onClick={onSettingClick}>
        <SettingsIcon />
      </button>
      <button
        className="p-[4px] flex items-center gap-[4px] data-[confirming=true]:bg-[#eee] data-[confirming=true]:text-[#232323] rounded-full px-[8px]"
        onClick={handleDeleteAll}
        data-confirming={deleteConfirming}
        disabled={isBetting}
      >
        <DeleteSweepIcon />
        {deleteConfirming && <Typography>Confirm delete all</Typography>}
      </button>
    </Box>
  );
};
