import { IconButton, Modal, Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
const IFrameModal = ({ open, handleBack, iFrameUrl }) => {

  return (
    <Modal
      open={open}
      onClose={handleBack}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack className="w-full max-w-[500px] min-h-[300px] sm:h-auto h-screen bg-[#0D1D34] items-center rounded-[8px] p-[16px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute">
        <Stack className="flex-row justify-between items-center w-full">
          <IconButton onClick={handleBack}>
            <ArrowBackIosIcon className="text-white" />
          </IconButton>
          <IconButton onClick={handleBack}>
            <CloseIcon className="text-white" />
          </IconButton>
        </Stack>
        <iframe
          width="100%"
          height="100%" 
          src={iFrameUrl}
          className="min-w-[460px] min-h-[600px]" 
        />
      </Stack>
    </Modal>
  )
}

export default IFrameModal;