import { Stack } from "@mui/material";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { useSelector } from "react-redux";
import i18n from "src/i18n";


function FooterText() {
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  return (
    <Stack
      direction={'column'}
      className="justify-center gap-[16px]"
    >
      <p className="break-words text-center text-white">
        {i18n.t('In_order_to_register_for_this_website,_the_user_is_required_to_accept_the_General_Terms')} &nbsp;
        {i18n.t('after_it_has_been_announced')}.
      </p>
      <p className="text-center text-white">
        ©2018-2024 {i18n.t('All_Rights_Reserved')}
      </p>
    </Stack>
  );
}

export default FooterText;