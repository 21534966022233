import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import i18n from "src/i18n"
import CloseIcon from "@mui/icons-material/Close";

export const PrintRequestPopup = ({ open, handleClose, openPrintPopup }) => {

  const popupClose = () => {
    openPrintPopup();
    handleClose();
  }

  return (
    <Box className="top-[40%] absolute w-full px-[20px]">
      <Stack 
        className="bg-white rounded-xl w-full p-[12px] gap-[20px] relative"
        sx={{
          border: '2px dotted green'
        }}
      >
        <Typography className="text-black mt-6 font-bold">
          {i18n.t("Would you print coupon details?")}
        </Typography>
        <IconButton
          className="absolute top-4 right-4"
        >
          <CloseIcon />
        </IconButton>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <Button
            className="text-white bg-green-500 hover:bg-green-700"
            onClick={popupClose}>
            <Typography className="text-white">
              {i18n.t('Yes')}
            </Typography>
          </Button>
          <Button
            className="text-white bg-red-600 hover:bg-red-700"
            onClick={handleClose}
            autoFocus
          >
            <Typography className="text-white">
              {i18n.t('No')}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}