import { Box, Grid, Stack, Typography } from "@mui/material";

export const ThemeBox = ({
  selected = false,
  onClick,
  name,
  navbar,
  navbarBg,
  toolbar,
  toolbarBg,
  header,
  headerBg,
  paper,
  paperBg,
  background,
  backgroundBg,
  footer,
  footerBg,
  sColor, 
  sBg,
  divider,
}) => {
  return (
    <Box
      p={1}
      borderRadius={2}
      bgcolor={ selected ? "#cccccc" : "#cccccc40" }
      sx={{
        cursor: "pointer",
        transition: "0.2s all",
        "&:hover": {
          bgcolor: "#cccccc80"
        }
      }}
      onClick={onClick}
    >
      <Box
        borderRadius={2}
        overflow="hidden"
        border="1px solid white"
      >
        <Grid container minHeight="260px">
          {/* Navbar Section */}
          <Grid item xs={3}>
            <Box
              width="100%"
              height="100%"
              borderRight={`1px solid ${divider}`}
              bgcolor={navbarBg}
              color={navbar}
              display="flex"
              justifyContent="center"
              p={2}
            >
              Navbar
            </Box>
          </Grid>

          {/* Main Content Section */}
          <Grid item xs={9} position="relative">
            <Stack
              sx={{
                height: "100%",
                bgcolor: backgroundBg, // Background color for the entire right section
                "& > div:not(:first-of-type)": {
                  borderTop: `1px solid ${divider}`
                }
              }}
            >
              <Box
                py={1}
                px={2}
                bgcolor={toolbarBg}
                color={toolbar}
              >
                Toolbar
              </Box>
              <Box
                pt={1}
                pb={5}
                px={2}
                bgcolor={headerBg}
                color={header}
              >
                Header (Primary)
              </Box>
              <Box
                flexGrow={1}
                py={2}
                px={2}
                bgcolor={backgroundBg}
                color={background}
              >
                <Stack justifyContent="flex-end" height="100%">
                  <Typography mt="auto">
                    Background
                  </Typography>
                </Stack>
              </Box>
              <Box
                py={1}
                px={2}
                bgcolor={footerBg}
                color={footer}
              >
                Footer
              </Box>
            </Stack>
            <Box
              position="absolute"
              left="10%"
              right="20%"
              top="30%"
              bottom="35%"
              bgcolor={paperBg}
              color={paper}
              borderRadius={2}
              p={1}
            >
              Paper
            </Box>
            <Box
              position="absolute"
              top="30%"
              right="20%"
              bgcolor={sBg}
              color={sColor}
              width={36}
              height={36}
              borderRadius={9999}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ transform: "translate(50%, -4px)"}}
            >
              s
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Typography
        textAlign="center"
        mt={1}
      >
        {name}
      </Typography>
    </Box>
  );
};
