import { Box, Button, Collapse, Typography, useTheme } from "@mui/material";
import { memo, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  setCashierModalTab,
  setCashierModalVisible,
} from "../../store/uiSlice";
import i18n from "src/i18n";
import { selectCurrentLanguageId } from "app/store/i18nSlice";
import { ONLINE_SYSTEM } from "src/app/constants";
import { useSystemType } from "src/app/hooks/useSystemType";

export const VerticalDrawer = memo((props) => {
  const { title, items, titleIcon, onLinkClick } = props;
  const [menuVisible, setMenuVisible] = useState(true);
  const dispatch = useDispatch();
  const theme = useTheme();
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const systemType = useSystemType();
  const navigate = useNavigate()

  const handleLinkClick = (item) => {
    if (item.id === "deposit" || item.id === "withdrawal") {
      dispatch(setCashierModalTab(item.id));
      dispatch(setCashierModalVisible(true));
      return;
    }
    navigate(item.path);
  };

  const cardHeader = theme.palette.cardHeader;

  return (
    <Box
      aria-label="verticalScrollBar"
      className="flex flex-col shadow-2"
      sx={{
        background: theme.palette.background.paper || "#5124a5",
        borderRadius: "12px",
        "&:first-of-type": {
          "[aria-label=titleBox]": {
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          },
        },
        "&:last-child": {
          "[aria-label=drawButton]:last-child": {
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          },
        },
      }}
    >
      <button
        aria-label="titleBox"
        className="flex justify-between items-center px-[16px] py-[8px]"
        style={{
          backgroundColor: cardHeader ? cardHeader.main : "#252f3e",
          color: cardHeader ? cardHeader.text : "white",
        }}
        onClick={() => setMenuVisible((v) => !v)}
      >
        <Typography className="uppercase font-500 text-[12px]">
          {titleIcon && (
            <FontAwesomeIcon icon={titleIcon} className="!text-[18px] mr-2" />
          )}{" "}
          {title}
        </Typography>
        {menuVisible && <KeyboardArrowUpIcon />}
        {!menuVisible && <KeyboardArrowDownIcon />}
      </button>
      <Collapse in={menuVisible}>
        <Box className="flex flex-col">
          {items
            .filter(
              (item) =>
                systemType === ONLINE_SYSTEM ||
                item.name !== "Account_Verification"
            )
            .map((item, index) => (
              <Button
                key={index}
                aria-label="drawButton"
                className="px-[12px] py-[10px] flex gap-[13px] items-center justify-normal border-b border-b-[#F0F3F8] border-x border-x-[#F0F3F8] hover:bg-[#ddd] whitespace-nowrap text-left"
                // to={item.path}
                onClick={() => handleLinkClick(item)}
              >
                {item.icon != "" && (
                  <img
                    src={item.icon}
                    alt="icon"
                    className="w-[16px] h-[16px] object-cover sport-item-icon"
                  />
                )}
                <Typography
                  className="text-[14px] max-w-full truncate text-[#3E4049]"
                  sx={{ color: theme.palette.text.primary }}
                >
                  {i18n.t(item.name)}
                </Typography>
              </Button>
            ))}
        </Box>
      </Collapse>
    </Box>
  );
});
