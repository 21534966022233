import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import toast from "react-hot-toast";
import { Alert } from "@mui/material";

const api_domain = jwtServiceConfig.apiUrl;

export const getVerification = createAsyncThunk("settingApp/getVerification", async ({kycType}) => {
  try {
    const response = await axios.get(`${api_domain}kyc/${kycType}`);
    return response.data.kyc;
  } catch (err) {
    toast.custom(
      <Alert severity="error">{err.response?.data?.user_error || "Failed to fetch coupons history"}</Alert>
    );
    throw err;
  }
});

export const uploadKYC = createAsyncThunk("settingApp/uploadVerification", async ({kycType, file}) => {
  try {
    const formData = new FormData();
    formData.append("kycType", kycType); // Append the kycType if needed
    formData.append("main_image", file);

    const response = await axios.put(`${api_domain}kyc/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.user_bonus_list;
  } catch (err) {
    toast.custom(
      <Alert severity="error">{err.response?.data?.user_error || "Failed to fetch coupons history"}</Alert>
    );
    throw err;
  }
});

const verificationAdapter = createEntityAdapter([]);

const verificationSlice = createSlice({
  name: "settingApp/verification",
  initialState: verificationAdapter.getInitialState({}),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVerification.fulfilled, (state, action) => {
      verificationAdapter.setAll(state, action.payload);
    });
  },
});

export const { selectAll: selectVerification } = verificationAdapter.getSelectors((state) => state.settingApp.verification);

export default verificationSlice.reducer;
