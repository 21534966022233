import i18next from 'i18next';
// import ar from './navigation-i18n/ar';
// import en from './navigation-i18n/en';
// import tr from './navigation-i18n/tr';
import authRoles from "../auth/authRoles";

// i18next.addResourceBundle('en', 'navigation', en);
// i18next.addResourceBundle('tr', 'navigation', tr);
// i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
    {
        id: 'casino-component',
        title: 'Casino',
        translate: 'Casino',
        type: 'item',
        icon: 'heroicons-outline:currency-dollar',
        auth: authRoles.onlyGuest,
        url: 'casino',
    },
    {
        id: 'sports-component',
        title: 'Sports',
        translate: 'Sports',
        type: 'item',
        icon: 'heroicons-outline:globe-alt',
        auth: authRoles.onlyGuest,
        url: '/sports',
    },
    {
        id: 'live-component',
        title: 'Live',
        translate: 'Live',
        type: 'item',
        icon: 'heroicons-outline:play',
        auth: authRoles.onlyGuest,
        url: 'live',
    },
    {
        id: 'promotion-component',
        title: 'Promotion',
        translate: 'Promotion',
        type: 'item',
        icon: 'heroicons-outline:gift',
        auth: authRoles.onlyGuest,
        url: 'promotion',
    },
    {
        id: 'bets-component',
        title: 'My Bets',
        translate: 'My_Bets',
        type: 'item',
        icon: 'heroicons-outline:ticket',
        auth: authRoles.onlyGuest,
        url: 'bets',
    },
];

export default navigationConfig;
