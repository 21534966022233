import {
  Box,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeCashoutCoupon,
  offerCashoutCoupon,
} from "../../store/historySlice";
import toast from "react-hot-toast";
import { selectMarketDic } from "src/app/main/store/prematchSlice";
import { useEffect } from "react";
import { selectUser } from "app/store/userSlice";
import { useScreenMd } from "src/app/hooks/useScreens";
import format from "date-fns/format";
import { Chip } from "./Chip";

const SlipDetailDrawer = ({ open, handleClose, couponUserDetails }) => {
  const [sliderValue, setSliderValue] = useState(100);
  const [winningRecordsVisible, setWinningRecordsVisible] = useState(false);
  const [cashoutAccepted, setCashoutAccepted] = useState(false);
  const dispatch = useDispatch();
  const marketDic = useSelector(selectMarketDic);
  const [columns, setColumns] = useState([]);
  const user = useSelector(selectUser);
  const md = useScreenMd();

  useEffect(() => {
    let combs = [];

    let nonBankers = [];
    let bankers = [];
    let start = 1;
    let end = 1;
    switch (couponUserDetails?.system_bet_type) {
      case "Trixie":
        start = 2;
        end = 3;
        break;
      case "Patent":
        start = 1;
        end = 3;
        break;
      case "Yankee":
        start = 2;
        end = 4;
        break;
      case "Lucky15":
        start = 1;
        end = 4;
        break;
      case "Canadian":
        start = 2;
        end = 5;
        break;
      case "Lucky31":
        start = 1;
        end = 5;
        break;
      case "Heinz":
        start = 2;
        end = 6;
        break;
      case "Lucky63":
        start = 1;
        end = 6;
        break;
      case "Super Heinz":
        start = 2;
        end = 7;
        break;
      case "Goliath":
        start = 2;
        end = 8;
        break;
      case "2/3":
        start = 2;
        end = 2;
        break;
      case "2/4":
        start = 2;
        end = 2;
        break;
      case "3/4":
        start = 3;
        end = 3;
        break;
      case "2/5":
        start = 2;
        end = 2;
        break;
      case "3/5":
        start = 3;
        end = 3;
        break;
      case "4/5":
        start = 4;
        end = 4;
        break;
      case "2/6":
        start = 2;
        end = 2;
        break;
      case "3/6":
        start = 3;
        end = 3;
        break;
      case "4/6":
        start = 4;
        end = 4;
        break;
      case "5/6":
        start = 5;
        end = 5;
        break;
      case "2/7":
        start = 2;
        end = 2;
        break;
      case "3/7":
        start = 3;
        end = 3;
        break;
      case "4/7":
        start = 4;
        end = 4;
        break;
      case "5/7":
        start = 5;
        end = 5;
        break;
      case "6/7":
        start = 6;
        end = 6;
        break;
      case "2/8":
        start = 2;
        end = 2;
        break;
      case "3/8":
        start = 3;
        end = 3;
        break;
      case "4/8":
        start = 4;
        end = 4;
        break;
      case "5/8":
        start = 5;
        end = 5;
        break;
      case "6/8":
        start = 6;
        end = 6;
        break;
      case "7/8":
        start = 7;
        end = 7;
        break;
    }

    for (let i = 0; i < couponUserDetails?.coupon_details.length; i++) {
      if (couponUserDetails?.coupon_details[i].is_banker == true)
        bankers.push(couponUserDetails?.coupon_details[i]);
      else nonBankers.push(couponUserDetails?.coupon_details[i]);
    }

    for (let i = start; i <= end; i++) {
      let res = combinations(nonBankers, i);
      combs = combs.concat(res);
    }

    for (let i = 0; i < combs.length; i++) {
      combs[i].push(...bankers);
    }
    setColumns(combs);
  }, [couponUserDetails]);

  const handleColumnTotalOdds = (column) => {
    let winnings = 1.0;
    for (let i = 0; i < column.length; i++) {
      winnings *= column[i].odds_price;
    }
    return winnings.toFixed(2);
  };

  const combinations = (arr, k) => {
    const result = [];

    function helper(start, combo) {
      if (combo.length === k) {
        result.push([...combo]);
        return;
      }
      for (let i = start; i < arr.length; i++) {
        combo.push(arr[i]);
        helper(i + 1, combo);
        combo.pop();
      }
    }

    helper(0, []);
    return result;
  };

  const showAlert = (type) => {
    toast.error(`${type} not supported`);
  };

  const renderSelection = (selection) => {
    if (selection == "1") {
      return "Home";
    } else if (selection == "2") {
      return "Away";
    } else if (selection == "U") {
      return "Under";
    } else if (selection == "O") {
      return "Over";
    }
    return selection;
  };

  const handleColumnStatus = (column) => {
    let status = "Won";
    for (let i = 0; i < column.length; i++) {
      if (column[i].bet_status == "Waiting") {
        status = "Waiting";
        break;
      }
    }
    for (let i = 0; i < column.length; i++) {
      if (column[i].bet_status == "Lost") {
        status = "Lost";
        break;
      }
    }
    return status;
  };

  const handleColumnWinnings = (column) => {
    let winnings = 1.0;
    for (let i = 0; i < column.length; i++) {
      if (column[i].bet_status == "Waiting" || column[i].bet_status == "Lost") {
        winnings = 0;
        break;
      }
      winnings *= column[i].odds_price;
    }
    return winnings.toFixed(2);
  };

  const handleGetOfferClick = () => {
    const partialAmount =
      (couponUserDetails.original_stake * sliderValue) / 100.0;
    const couponId = couponUserDetails.id;
    if (!cashoutAccepted) {
      dispatch(offerCashoutCoupon({ partialAmount, couponId })).then((res) => {
        const data = res.payload;
        if (data.can_cash_out) {
          setCashoutAccepted(true);
          setCashoutAmount(data.cash_out_amount);
        }
        if (data.status != 200 && data.data.user_error) {
          showAlert(data.data.user_error);
        }
      });
    } else {
      dispatch(makeCashoutCoupon({ partialAmount, couponId })).then((res) => {
        const data = res.payload;
        if (data.success) {
          setCashoutAccepted(false);
          setOpen(false);
          handleRefresh();
        }
        if (data.user_error) {
          showAlert(data.user_error);
        }
      });
    }
  };

  const handlePrint = () => {
    const totalOdds = couponUserDetails.total_odds;
    const potentialWin = couponUserDetails.total_won;

    const printWindow = window.open("", "_blank", "width=800,height=600");
    printWindow.document.write(
      '<html><head><title>Print Tickets</title></head><body><div id="print-root"></div></body></html>'
    );
    printWindow.document.close();

    ReactDOM.render(
      <PrintTickets
        coupons={couponUserDetails}
        betType={couponUserDetails.coupon_type}
        totalBetAmount={couponUserDetails.original_stake}
        totalOdds={couponUserDetails.total_odds}
        potentialWin={couponUserDetails.total_won}
        selectedPrinter={selectedPrinter}
        marketDic={marketDic}
      />,
      printWindow.document.getElementById("print-root")
    );

    if (autoPrint) {
      printWindow.focus();
      printWindow.print();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          overflow: "visible",
          width: { xs: "100%", md: "60%" },
        },
        width: { xs: "100%", md: "auto" },
      }}
    >
      <Box className="relative pb-[200px]">
        <IconButton
          className="absolute z-[50] top-[4px] right-[4px] md:right-auto md:-left-[20px] text-white bg-[#111825] hover:bg-[#111825]"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Stack
          spacing="12px"
          className="max-h-[var(--app-height)] overflow-y-auto pb-[50%] sm:pb-0"
        >
          <Box
            aria-label="slipHeader"
            className="bg-[#2C374F] text-white pt-[24px] pb-[8px]"
          >
            <Stack
                direction={{ md: "row", sm: "col" }}
                spacing="36px"
                className="px-[24px] pb-[12px]"
                justifyContent="space-between"
              >
                <Typography variant="body1" sx={{ fontSize: "14px" }}>
                  <span style={{ fontSize: "16px" }}>
                    <FiberManualRecordIcon
                      fontSize="4px"
                      sx={{
                        color: "#4DA533",
                      }}
                    />{" "}
                    Betslip # :
                  </span>{" "}
                  {couponUserDetails?.id}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "14px" }}>
                  <span style={{ fontSize: "16px" }}>
                    <FiberManualRecordIcon
                      fontSize="4px"
                      sx={{
                        color: "#4DA533",
                      }}
                    />{" "}
                    Code :
                  </span>{" "}
                  {couponUserDetails?.code}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "14px" }}>
                  <span style={{ fontSize: "16px" }}>
                    <FiberManualRecordIcon
                      fontSize="4px"
                      sx={{
                        color: "#4DA533",
                      }}
                    />{" "}
                    Date:
                  </span>{" "}
                  {couponUserDetails
                    ? format(
                        new Date(couponUserDetails?.created_at),
                        "yyyy MMM dd HH:mm"
                      )
                    : "Invalid_Date"}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "14px" }}>
                  <span style={{ fontSize: "16px" }}>
                    <FiberManualRecordIcon
                      fontSize="4px"
                      sx={{
                        color: "#4DA533",
                      }}
                    />{" "}
                    Closing:
                  </span>{" "}
                  {couponUserDetails?.closed_at[0] != "0"
                    ? couponUserDetails?.closed_at
                      ? format(
                          new Date(couponUserDetails.closed_at),
                          "yyyy MMM dd HH:mm"
                        )
                      : "Not_Closed"
                    : "Not_Closed"}
                </Typography>
                <Stack direction="row">
                  <Typography variant="body1" sx={{ fontSize: "14px" }}>
                    <span style={{ fontSize: "16px" }}>
                      <FiberManualRecordIcon
                        fontSize="4px"
                        sx={{
                          color: "#4DA533",
                        }}
                      />{" "}
                      Status:
                    </span>
                  </Typography>
                  <Box className="ml-[8px] px-[8px] py-[2px] bg-[#4f46e5] text-white rounded-[4px]">
                    {couponUserDetails?.slip_status}
                  </Box>
                  <button
                    className="ml-[8px] bg-[#4f46e5] rounded-[4px] text-white px-[8px] py-[2px]"
                    onClick={(e) => {
                      setWinningRecordsVisible((v) => !v);
                    }}
                    style={{
                      visibility:
                        couponUserDetails?.coupon_type === "System"
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    <RemoveRedEyeIcon fontSize="small" />
                  </button>
                </Stack>
              </Stack>
            <Divider />
            <Grid container className="px-[24px] flex-col sm:flex-row">
              <Grid item xs={6} md={3} mt={"12px"}>
                <Stack gap="12px">
                  <Typography>
                    Betslip Type: {couponUserDetails?.coupon_type}
                  </Typography>
                  <Typography>
                    Live:{" "}
                    {couponUserDetails?.game_type == "Prematch" ? "No" : "Yes"}
                  </Typography>
                  <Typography>
                    Total Bets:{" "}
                    {couponUserDetails?.coupon_type === "System"
                      ? columns.length
                      : couponUserDetails?.coupon_details?.length}{" "}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={3} mt={"12px"}>
                <Stack gap="12px">
                  <Typography>
                    Total Odds: {couponUserDetails?.total_odds.toFixed(2)}
                  </Typography>
                  <Typography>
                    Total Stake: {couponUserDetails?.original_stake.toFixed(2)}
                  </Typography>
                </Stack>
              </Grid>
              {couponUserDetails?.coupon_type != "System" && (
                <Grid item xs={6} md={3} mt={"12px"}>
                  <Stack gap="12px">
                    <Typography>
                      Partial Cashed:{" "}
                      {couponUserDetails?.partial_cash_amount.toFixed(2)}
                    </Typography>
                    <Typography>
                      Cashed Out:{" "}
                      {couponUserDetails?.refunded_amount.toFixed(2)}
                    </Typography>
                  </Stack>
                </Grid>
              )}
              <Grid item xs mt={"12px"}>
                <Stack gap="12px">
                  <Typography>
                    Pos.Winnings: {couponUserDetails?.total_won.toFixed(2)}
                  </Typography>
                  <Typography>
                    Winnings:{" "}
                    {couponUserDetails?.slip_status == "Waiting"
                      ? ""
                      : couponUserDetails?.total_won.toFixed(2)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box className="px-[12px] bg-[white]">
            <Box className="max-w-[700px] mx-auto">
              <Stack
                direction={{ md: "row", sm: "col" }}
                alignItems="center"
                gap="12px"
              >
                {couponUserDetails?.slip_status === "Waiting" &&
                  couponUserDetails?.coupon_type != "System" && (
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Box className="flex-grow pr-[24px] min-w-[300px]">
                        <Slider
                          value={sliderValue}
                          color="success"
                          onChange={(e) => {
                            setSliderValue(e.target.value);
                          }}
                        />
                      </Box>
                      <Box className="mr-[32px]">
                        {user.data.currency}{" "}
                        {couponUserDetails?.original_stake.toFixed(2)} (
                        {sliderValue}% ,{" "}
                        {(
                          (couponUserDetails?.original_stake * sliderValue) /
                          100
                        ).toFixed(2)}
                        )
                      </Box>
                    </Stack>
                  )}
                {couponUserDetails?.coupon_type != "System" &&
                  couponUserDetails?.slip_status == "Waiting" &&
                  !cashoutAccepted && (
                    <button
                      className="bg-[#4f46e5] text-white rounded-full px-[8px] py-[4px]"
                      onClick={handleGetOfferClick}
                    >
                      Get Offer for CashOut
                    </button>
                  )}
                {couponUserDetails?.coupon_type != "System" &&
                  cashoutAccepted && (
                    <button
                      className="bg-[#3abb7f] text-white rounded-full px-[8px] py-[4px] "
                      onClick={handleGetOfferClick}
                    >
                      Accept Cashout Offer {cashoutAmount.toFixed(2)}{" "}
                      {user.data.currency}
                    </button>
                  )}
                {user?.role && user.role.includes("betshop_user") ? (
                  <button
                    className="bg-[#4f46e5] text-white rounded-full px-[8px] py-[4px]"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                ) : null}
              </Stack>
            </Box>
          </Box>
          {md && (
            <Table aria-label="FinalScoreTable" stickyHeader className="">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "20px", fontSize: "12px" }}>
                    #
                  </TableCell>
                  <TableCell style={{ width: "65px", fontSize: "12px" }}>
                    Type
                  </TableCell>
                  <TableCell style={{ width: "120px", fontSize: "12px" }}>
                    Date
                  </TableCell>
                  <TableCell style={{ width: "325px", fontSize: "12px" }}>
                    Event
                  </TableCell>
                  <TableCell style={{ width: "65px", fontSize: "12px" }}>
                    Bet Market
                  </TableCell>
                  <TableCell style={{ width: "75px", fontSize: "12px" }}>
                    Selection
                  </TableCell>
                  <TableCell style={{ width: "50px", fontSize: "12px" }}>
                    Odds
                  </TableCell>
                  <TableCell style={{ width: "75px", fontSize: "12px" }}>
                    Score
                  </TableCell>
                  <TableCell style={{ width: "75px", fontSize: "12px" }}>
                    Time
                  </TableCell>
                  <TableCell style={{ width: "40px", fontSize: "12px" }}>
                    Banker
                  </TableCell>
                  <TableCell style={{ width: "100px", fontSize: "12px" }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {couponUserDetails?.coupon_details?.map((val, i) => {
                  return (
                    <TableRow
                      key={i}
                      className="h-72 cursor-pointer"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <TableCell className="text-[12px]">{i + 1}</TableCell>
                      <TableCell className="text-[12px]">
                        {val?.bet_game_type === "Live" ? (
                          <img
                            src="assets/images/live.png"
                            alt=""
                            className="h-[40px] object-contain"
                          />
                        ) : null}
                      </TableCell>
                      <TableCell className="text-[12px]">
                        {couponUserDetails
                          ? format(
                              new Date(couponUserDetails?.created_at),
                              "yyyy MMM dd HH:mm"
                            )
                          : "?"}
                      </TableCell>
                      <TableCell className="text-[12px]">
                        {val?.event_name}
                      </TableCell>
                      <TableCell className="text-[12px]">
                        {val?.outcome_ref_id.split(";")[0]}
                      </TableCell>
                      <TableCell className="text-[12px]">
                        {renderSelection(val?.outcome_ref_id.split(";")[1])}
                      </TableCell>
                      <TableCell className="text-[12px]">
                        {val?.odds_price.toFixed(2)}
                      </TableCell>
                      <TableCell className="text-[12px]">
                        {val?.bet_game_type == "Prematch"
                          ? ""
                          : val?.score == ":"
                          ? "0:0"
                          : val?.score}
                      </TableCell>
                      <TableCell className="text-[12px]">
                        {val?.bet_game_type == "Live" && val?.tmr_update > 0
                          ? (() => {
                              const minutes = Math.floor(val?.tmr_update / 60);
                              const seconds = val?.tmr_update % 60;

                              const suffixPeriod =
                                val?.sport_id == 79 ? "h" : "p";

                              const period =
                                val?.period == 0
                                  ? ""
                                  : `${val?.period}${suffixPeriod} `;

                              return `${period}${minutes
                                .toString()
                                .padStart(2, "0")}:${seconds
                                .toString()
                                .padStart(2, "0")}`;
                            })()
                          : ""}
                      </TableCell>
                      <TableCell className="text-[12px]">
                        {val?.is_banker ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>
                        <Chip status={val?.bet_status}>{val?.bet_status}</Chip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
          {!md &&
            couponUserDetails?.coupon_details?.map((val, i) => (
              <Stack
                key={i}
                className="cursor-pointer"
                sx={{
                  "&>div": {
                    display: "flex",
                    alignItems: "center",
                    "&>div:first-child": {
                      minWidth: "100px",
                    },
                  },
                }}
                gap="12px"
                px="12px"
              >
                <Box className="text-[12px]">
                  <Box>No</Box>
                  <Box>{i + 1}</Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Type</Box>
                  <Box>
                    {val?.bet_game_type === "Live" ? (
                      <img
                        src="assets/images/live.png"
                        alt=""
                        className="h-[40px] object-contain"
                      />
                    ) : null}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Date</Box>
                  <Box>
                    {couponUserDetails
                      ? format(
                          new Date(couponUserDetails?.created_at),
                          "yyyy MMM dd hh:mm"
                        )
                      : "?"}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Event</Box>
                  <Box>{val?.event_name}</Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Bet Market</Box>
                  <Box>{val?.outcome_ref_id.split(";")[0]}</Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Selection</Box>
                  <Box>
                    {renderSelection(val?.outcome_ref_id.split(";")[1])}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Odds</Box>
                  <Box>{val?.odds_price.toFixed(2)}</Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Score</Box>
                  <Box>{val?.score == ":" ? "0:0" : val?.score}</Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Time</Box>
                  <Box>
                    {val?.bet_game_type == "Live" && val?.tmr_update > 0
                      ? (() => {
                          const minutes = Math.floor(val?.tmr_update / 60);
                          const seconds = val?.tmr_update % 60;

                          const suffixPeriod = val?.sport_id == 79 ? "h" : "p";

                          const period =
                            val?.period == 0
                              ? ""
                              : `${val?.period}${suffixPeriod} `;

                          return `${period}${minutes
                            .toString()
                            .padStart(2, "0")}:${seconds
                            .toString()
                            .padStart(2, "0")}`;
                        })()
                      : "-"}
                  </Box>
                </Box>
                <Box className="text-[12px]">
                  <Box>Banker</Box>
                  <Box>{val?.is_banker ? "Yes" : "No"}</Box>
                </Box>
                <Box>
                  <Box>Status</Box>
                  <Chip status={val?.bet_status}>{val?.bet_status}</Chip>
                </Box>
              </Stack>
            ))}
          <Box>
            {columns.map((column, index) => {
              return (
                <Collapse in={winningRecordsVisible} className="p-[12px]">
                  <Box
                    aria-label="SystemColumns"
                    className="border rounded-[8px] p-[12px] overflow-auto "
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      className="bg-[#2C374F] text-white"
                    >
                      <Grid
                        container
                        className="sm:px-[24px] sm:pb-[24px] p-[12px]"
                      >
                        <Grid item xs={6} md={3} mt={"12px"}>
                          <Stack gap="12px">
                            {/* <Typography variant="body1">{`System Columns: ${column.length}/${couponUserDetails.coupon_details.length}`}</Typography> */}
                            <Typography
                              variant="body1"
                              className="text-sm sm:text-md"
                            >{`System Columns: ${index + 1} / ${
                              columns.length
                            }`}</Typography>
                            <Typography
                              variant="body1"
                              className="text-sm sm:text-md"
                            >{`Bestslip Type: System`}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} md={3} mt={"12px"}>
                          <Stack gap="12px">
                            <Typography
                              variant="body1"
                              className="text-sm sm:text-md"
                            >{`Status: ${handleColumnStatus(
                              column
                            )}`}</Typography>
                            <Typography
                              variant="body1"
                              className="text-sm sm:text-md"
                            >{`Total Odds: ${handleColumnTotalOdds(
                              column
                            )}`}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} md={3} mt={"12px"}>
                          <Stack gap="12px">
                            {/* <Typography variant="body1">{`Possible Winnings: ${(
                            (handleColumnWinnings(column) *
                              couponUserDetails.stake) /
                            columns.length
                          ).toFixed(2)}`}</Typography> */}
                            <Typography
                              variant="body1"
                              className="text-sm sm:text-md"
                            >{`Stake: ${(
                              couponUserDetails.stake / columns.length
                            )?.toFixed(2)}`}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} md={3} mt={"12px"}>
                          <Stack direction="row" spacing="8px">
                            <Stack gap="12px">
                              <Typography className="text-sm sm:text-md">
                                Winnings:{" "}
                              </Typography>
                              <Typography
                                variant="body1"
                                className="text-white bg-[#6ebd6e] rounded-[4px] px-[4px] text-sm sm:text-md"
                              >
                                {(
                                  (couponUserDetails.stake / columns.length) *
                                  handleColumnWinnings(column)
                                ).toFixed(2)}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                    <TableContainer>
                      <Table
                        stickyHeader
                        className="mt-[12px]"
                        aria-labelledby="tableTitle"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{ width: "20px", fontSize: "12px" }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              style={{ width: "60px", fontSize: "12px" }}
                            >
                              Date
                            </TableCell>
                            <TableCell
                              style={{ width: "125px", fontSize: "12px" }}
                            >
                              Event
                            </TableCell>
                            <TableCell
                              style={{ width: "65px", fontSize: "12px" }}
                            >
                              Bet Market
                            </TableCell>
                            <TableCell
                              style={{ width: "75px", fontSize: "12px" }}
                            >
                              Selection
                            </TableCell>
                            <TableCell
                              style={{ width: "50px", fontSize: "12px" }}
                            >
                              Odds
                            </TableCell>
                            <TableCell
                              style={{ width: "100px", fontSize: "12px" }}
                            >
                              Status
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {column?.map((val, i) => (
                            <TableRow
                              key={i}
                              className="h-72 cursor-pointer"
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <TableCell className="text-[12px]">
                                {i + 1}
                              </TableCell>
                              <TableCell className="text-[12px]">
                                {couponUserDetails?.created_at
                                  ? format(
                                      new Date(couponUserDetails.created_at),
                                      "yyyy MMM dd"
                                    )
                                  : "Invalid Date"}
                              </TableCell>
                              <TableCell className="text-[12px]">
                                {val.event_name}
                              </TableCell>
                              <TableCell className="text-[12px]">
                                {val?.outcome_ref_id?.split(";")[0]}
                              </TableCell>
                              <TableCell className="text-[12px]">
                                {val?.outcome_ref_id?.split(";")[1]}
                              </TableCell>
                              <TableCell className="text-[12px]">
                                {val.odds_price?.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                <Chip status={val?.bet_status}>
                                  {val?.bet_status}
                                </Chip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Collapse>
              );
            })}
          </Box>
        </Stack>
      </Box>
    </Drawer>
  );
};

const PrintTickets = ({
  coupons,
  betType,
  totalBetAmount,
  totalOdds,
  potentialWin,
  selectedPrinter,
  marketDic,
}) => {
  const selectedPaperStyle =
    PaperStyles[selectedPrinter.value] || PaperStyles.A4;

  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        ...selectedPaperStyle,
        fontSize: "14px",
      }}
    >
      <h2>Betslip</h2>
      <div>
        <p style={{ margin: "0px" }}>
          <strong>Bet Type:</strong> {betType}
        </p>
        <p style={{ margin: "0px" }}>
          <strong>Total Stake:</strong> {totalBetAmount}
        </p>
        <p style={{ margin: "0px" }}>
          <strong>Total Odds:</strong> {totalOdds}
        </p>
        <p style={{ margin: "0px" }}>
          <strong>Possible Winnings:</strong> {potentialWin}
        </p>
        <h4>Tickets:</h4>
        {coupons?.coupon_details.map((coupon, index) => {
          let name = coupon.outcome_ref_id.split(";")[1];
          if (name == "1") {
            name = "Home";
          } else if (name == "2") {
            name = "Away";
          }
          if (name == "U") {
            name = "Under";
          }
          if (name == "O") {
            name = "Over";
          }
          if (
            coupon.outcome_ref_id.includes("Handicap") &&
            !coupon.outcome_ref_id.includes(",0")
          ) {
            if (coupon.outcome_ref_id.includes("-")) {
              if (name == "Home") name = "-" + name;
              else if (name == "Away") name = "+" + name;
            } else {
              if (name == "Home") name = "+" + name;
              else if (name == "Away") name = "-" + name;
            }
          }

          let prefix =
            marketDic && marketDic[coupon.market_id]
              ? marketDic[coupon.market_id].split(";")[0]
              : coupon.outcome_ref_id.split(";")[0];

          const parts = coupon.outcome_ref_id.split(";")[0].split(",");
          parts[0] = prefix;
          if (coupon.outcome_ref_id.includes("%P")) {
            const cleanedParts = parts.map((part) => part.replace("%P", " "));
            prefix =
              cleanedParts[0] +
              coupon.outcome_ref_id.split(";")[0].split(",")[1];
            // prefix = formatPrefix(prefix);
          } else if (coupon.outcome_ref_id.split(";")[0].includes(",")) {
            prefix = parts[0];
            name = name + " " + parts[1];
          } else if (coupon.outcome_ref_id.split(";")[0].includes("%")) {
            prefix = prefix.replace("%1", "Home").replace("%2", "Away");
          }
          return (
            <div
              key={index}
              style={{ marginBottom: "10px", borderBottom: "1px solid #ccc" }}
            >
              <p style={{ margin: "0px" }}>
                <strong>Event:</strong> {coupon.event_name}
              </p>
              <p style={{ margin: "0px" }}>
                <strong>Date:</strong> {dateToText(coupons.created_at)}
              </p>
              <p style={{ margin: "0px" }}>
                <strong>Market:</strong> {prefix + ": " + name}
              </p>
              <p style={{ margin: "0px" }}>
                <strong>Odds:</strong> {coupon.odds_price.toFixed(2)}
              </p>
              <p style={{ margin: "0px" }}>
                <strong>Stake:</strong> {coupons.original_stake.toFixed(2)}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SlipDetailDrawer;
