import { React, useState, lazy, Suspense } from "react";
import {
  Box,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { SearchBox } from "app/shared-components/SearchBox";
import { useLocation, useNavigate } from "react-router-dom";
import { useScreenMd } from "src/app/hooks/useScreens";
import { useDispatch, useSelector } from "react-redux";
import { getTournament } from "../store/tournamentSlice";
import { selectMenuList, setAffiliateId, setMenuList, toggleLive } from "../store/uiSlice";
import { useEffect } from "react";
import withReducer from "app/store/withReducer";
import reducer from "../store";
import i18next from "i18next";
import { useIsLive } from "src/app/hooks/useIsLive";
import { useSystemType } from "src/app/hooks/useSystemType";
import { BETSHOP_SYSTEM } from "src/app/constants";
import { memo } from "react";
import { selectCurrentLanguageId, selectMenu } from "app/store/i18nSlice";
import { selectIsBetSaving } from "../store/ticketSlice";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import CasinoIcon from "@mui/icons-material/Casino";
// Lazy-loaded components
const DashboardBanner = lazy(() => import("./components/DashboardBanner"));
const FooterLayout1 = lazy(() => import("app/theme-layouts/layout1/components/FooterLayout1"));
const RightSidebar = lazy(() => import("../../shared-components/RightSidebar"));
const PopularGameContainer = lazy(() => import("./components/PopularGameContainer"));
const BoostOddsContainer = lazy(() => import("./components/BoostOddsContainer"));
const GamesContainer = lazy(() => import("./components/GamesContainer"));
const VerticalDrawerContainer = lazy(() => import("./components/VerticalDrawerContainer"));
const BettingsBox = lazy(() => import("./components/BettingsBox"));
const CategoryButton = lazy(() => import("./components/CategoryButton"));
const SearchModal = lazy(() => import("../sports/components/SearchModal"));

const paths = {
  sports_betting: "/sports/top",
  home: "/home",
  live: "/sports/top?live=true",
  casino: "/casino/all",
  live_casino: "/live-casino/all",
  virtual_games: "/virtual-games/all",
};

function Dashboard() {
  const [searchVisible, setSearchVisible] = useState(false);
  const navigate = useNavigate();
  const isMd = useScreenMd();
  const dispatch = useDispatch();
  const { isLive } = useIsLive();
  const systemType = useSystemType();
  const currentLanguageId = useSelector(selectCurrentLanguageId);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const affiliateId = queryParams.get("afflink");
  const menus = useSelector(selectMenu);
  const menuList = useSelector(selectMenuList);
  const theme = useTheme();
  const [initLoading, setInitLoading] = useState(true);
  const betting = useSelector(selectIsBetSaving);

  useEffect(() => {
    setTimeout(() => {
      setInitLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (menus.length > 0) {
      const availableMenus = [...menus];
      const slugs = availableMenus.map((menu) => menu.slug);
      dispatch(setMenuList(slugs));
      const isAccessible = availableMenus.some((menu) => menu.slug === "home");
      const firstMenu = menus[0];
      if (!isAccessible) navigate(paths[firstMenu.slug]);
    }
  }, [menus]);

  useEffect(() => {
    dispatch(setAffiliateId(affiliateId));
  }, [affiliateId]);

  useEffect(() => {
    dispatch(getTournament({ langId: currentLanguageId, type: "unplayed" }));
  }, [currentLanguageId]);

  useEffect(() => {
    if (isLive) {
      dispatch(toggleLive());
    }
    dispatch(
      getTournament({ langId: "en", type: isLive ? "Live" : "unplayed" })
    );
  }, [dispatch, isLive]);

  const handleSearchClick = () => {
    setSearchVisible(true);
  };

  console.log('dashboardRendering')

  return (
    <>
      <SearchModal
        visible={searchVisible}
        onClose={() => setSearchVisible(false)}
      />
      <Box
        aria-label="dashboard"
        className="flex gap-[12px] md:pl-0 md:pr-[12px]"
      >
        <Box aria-label="leftSideBar" className="min-w-[244px] hidden md:block">
          <Box className="flex flex-col gap-[18px]">
            <Box
              className="pt-[16px] pb-[12px] px-[10px] rounded-br-[12px]"
              bgcolor={theme.palette.background.paper}
            >
              <Suspense fallback={<CircularProgress />}>
                <Box className="flex gap-[9px] justify-center">
                  {(menuList.includes("sports_betting") ||
                    menuList.includes("live")) && (
                      <CategoryButton
                        bgcolorvalue={theme.palette.success.main}
                        textcolor={theme.palette.success.contrastText}
                        selected={true}
                        image={
                          <SportsSoccerIcon
                            fontSize="small"
                            htmlColor={theme.palette.success.contrastText}
                          />
                        }
                        title={i18next.t("Sports")}
                      />
                    )}
                  {(menuList.includes("casino") ||
                    menuList.includes("live_casino") ||
                    menuList.includes("virtual_games")) && (
                      <CategoryButton
                        bgcolorvalue={theme.palette.warning.main}
                        textcolor={theme.palette.warning.contrastText}
                        selected={false}
                        image={
                          <CasinoIcon
                            fontSize="small"
                            htmlColor={theme.palette.warning.contrastText}
                          />
                        }
                        title={i18next.t("Casino")}
                      />
                    )}
                </Box>
                <Box className="mt-[10px]">
                  <SearchBox boxProps={{ onClick: handleSearchClick }} />
                </Box>
              </Suspense>
            </Box>
            <VerticalDrawerContainer />
          </Box>
        </Box>

        <Box
          aria-label="mainContent"
          className="flex flex-col gap-[15px] max-h-[calc(var(--app-height)-134px)] min-h-[100vh] overflow-y-auto md:pr-[8px] w-full justify-between relative"
        >
          <Suspense fallback={<CircularProgress />}>
            <Box className="mt-[16px] px-[4px]">
              <DashboardBanner category="home" />
            </Box>
          </Suspense>
          {menuList.includes("casino") && (
            <Suspense fallback={<CircularProgress />}>
              <PopularGameContainer />
            </Suspense>
          )}
          {(menuList.includes("sports_betting") ||
            menuList.includes("live")) && (
              <Suspense fallback={<CircularProgress />}>
                <BoostOddsContainer />
              </Suspense>
            )}
          {menuList.includes("live") && (
            <Suspense fallback={<CircularProgress />}>
              <Box className="px-[4px]">
                <BettingsBox
                  title={i18next.t("Live_Bettings")}
                  betType="Live"
                  onMore={() => navigate("/sports/top?live=true")}
                />
              </Box>
            </Suspense>
          )}
          {menuList.includes("sports_betting") && (
            <Suspense fallback={<CircularProgress />}>
              <Box className="px-[4px]">
                <BettingsBox
                  title={i18next.t("Sports_Betting")}
                  betType="unplayed"
                  onMore={() => navigate("/sports/top")}
                />
              </Box>
            </Suspense>
          )}
          {systemType !== BETSHOP_SYSTEM &&
            menuList.includes("live_casino") && (
              <Suspense fallback={<CircularProgress />}>
                <GamesContainer />
              </Suspense>
            )}
          {!initLoading && (
            <FooterLayout1 />
          )}
        </Box>

        {isMd &&
          (menuList.includes("sports_betting") ||
            menuList.includes("live") ||
            menuList.includes("casino") ||
            menuList.includes("live_casino")) && (
            <RightSidebar />
          )}
      </Box>
      {betting && (
        <Box className="fixed w-full h-full top-0 left-0 z-[100] flex justify-center items-center">
          
        </Box>
      )}
    </>
  );
}

export default withReducer("sportsApp", reducer)(memo(Dashboard));
