import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const BonusItem = ({ item, bonusMode, onClick }) => {

  return (
    <Box
      key={item.id}
      className="min-h-[100px] rounded-[5px] bg-[#0D1D34] px-[12px] py-[4px] flex w-full items-center gap-[10px] sm:gap-[20px] relative"
      data-selected={item.id === bonusMode}
      onClick={onClick}
      sx={{
        background: item.id === bonusMode ? "#4DA533" : "#0D1D34",
      }}
    >
      <IconButton
        className="absolute top-[2px] right-[2px] cursor-pointer"
      >
        <HighlightOffIcon className="text-white" />
      </IconButton>
      <img
        src="assets/images/bonus-item.png"
        alt="bonus icon"
        className="w-[50px] h-[50px] sm:w-[65px] sm:h-[65px] object-cover"
      />
      <Stack>
        <Typography
          className="text-[#ddd] data-[selected=true]:text-white"
          data-selected={item.id === bonusMode}
        >
          {item.title}
        </Typography>
        {/* <Typography
          className="text-[14px] sm:text-[16px] text-[#4DA533]"
        >
          100% up to €120  + 120 free spins
        </Typography> */}
      </Stack>
    </Box>
  );
}

export default BonusItem;