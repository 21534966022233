const locale = {
  CASINO_BETS: 'Casino Bahisleri',
  HIGH_ROLLERS: 'High Rollers',
  RARE_WINS: 'Nadir Kazanımlar',
  GAME: 'Oyun',
  USER: 'Kullanıcı',
  TIME: 'Zaman',
  BET_AMOUNT: 'Bahis Tutarı',
  MULTIPLIER: 'Çarpan',
  PAYOUT: 'Ödeme',
  MORE: 'Daha fazla',
  LIVE_CASINO: 'Canlı Casino',
  CASINO: 'Kumarhane'
};

export default locale;
