import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sportbook_id } from "src/app/constants/casino-types";
import toast from "react-hot-toast";
import { Alert } from "@mui/material";

const api_domain = jwtServiceConfig.apiUrl;

export const getCasinoTypes = createAsyncThunk("casinoApp/getCasinoTypes", async () => {
  const res = await axios.get(`${api_domain}casinoTypes`);
  const { casino_types } = res.data;
  return casino_types.filter((t) => t.name !== "sportsbook");
});

export const getVendorsForCasinoType = createAsyncThunk("casinoApp/getVendorsForCasinoType", async (casinoTypeId) => {
  const res = await axios.get(`${api_domain}casinoCategories/${casinoTypeId}`);
  return res.data.casino_categories;
});

export const getVendorsForCasinoTypes = createAsyncThunk("casinoApp/getVendorsForCasinoTypes", async ({ rootPath, casinoTypes}) => {
  let vendors = [];
  const vendorGroups = [];
  for await (const casinoType of casinoTypes) {
    const res = await axios.get(`${api_domain}casinoCategories/${casinoType.id}`);
    const vendorList = res.data.casino_categories;
    vendors = _.unionBy(vendors, vendorList, "vendor_id");
    vendorGroups.push({ casinoType, vendors: vendorList });
  }

  return { rootPath, vendors, vendorGroups };
});

export const getCasinoHighlight = createAsyncThunk(
  "casinoApp/getCasinoHighlight",
  async () => {
    const res = await axios.get(`${api_domain}casinoHighlight`);
    return res.data.casino_overview;
  }
);

export const getGamesForCasinoGameType = createAsyncThunk(
  "casinoApp/getGamesForCasinoGameType",
  async (casinoTypeId) => {
    const res = await axios.get(`${api_domain}casinoGamesByType/${casinoTypeId}`);
    return res.data.casino_game_list;
  }
);

export const getGamesForVendor = createAsyncThunk("casinoApp/getGamesForVendor", async (vendorId) => {
  const res = await axios.get(`${api_domain}casinoGamesByType/${vendorId}`);
  return res.data.casino_game_list;
});

export const getGameGroupsForVendorGroups = createAsyncThunk(
  "casinoApp/getGameGroupsForVendorGroups",
  async ({ vendorGroups, rootPath }) => {
    let result = [];
    for await (const { casinoType, vendors } of vendorGroups) {
      const list = [];
      for await (const vendor of vendors) {
        const res = await axios.get(`${api_domain}casinoGames/${casinoType.id}/${vendor.vendor_id}`);
        if (!res.data.casino_game_list) continue;
        list.push(...res.data.casino_game_list);
        if (list.length >= 30) break;
      }
      result.push({ casinoType, games: list });
    }
    return { gameGroups: result, rootPath };
  }
);

export const getSearchGames = createAsyncThunk(
  "casinoApp/getSearchGames",
  async ({typeName, searchTerm}) => {
    const res = await axios.get(`${api_domain}searchCasinoGames/${typeName}/all/all/${searchTerm}`);
    return res.data.casino_game_list;
  }
);

export const getPopularGames = createAsyncThunk("casinoApp/getPopularGames", async () => {
  const res = await axios.get(`${api_domain}casinoPopular`);
  return res.data.casino_game_list || [];
});

export const getGamesByVendor = createAsyncThunk("casinoApp/getGamesByVendor", async (vendorId) => {
  const res = await axios.get(`${api_domain}casinoGamesByVendor/${vendorId}`);
  return res.data.casino_game_list;
});


export const getDemoGame = createAsyncThunk("casinoApp/getDemoGame", async ({ lang, gameId, currency }) => {
  const { data } = await axios.post(`${api_domain}casino/getDemoGame`, { lang, gameid: gameId, currency })
  return data;
})

export const getGameWithPlayerCheck = createAsyncThunk("casinoApp/getGameWithPlayerCheck", async ({ lang, gameId, use_supported_currency }) => {
  // const { data } = await axios.post(`${api_domain}casino/getGameWithPlayerCheck`, { lang, gameid: gameId });
  // return data;

  try {
    const response = await axios.post(`${api_domain}casino/getGameWithPlayerCheck`, { lang, gameid: gameId, use_supported_currency});
    // toast.custom(
    //   <Alert severity="success">Profile has been successfully saved</Alert>
    // );
    return response.data;
  } catch (err) {
    // if (err?.response?.data?.status) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
      // throw err
    // }
  }
});

export const getRefreshToken = createAsyncThunk("casinoApp/getRefreshToken", async () => {
  try {
    const response = await axios.get(`${api_domain}userInfo`);
    return response.data;
  } catch (err) {
    return err;
  }
});

const initialState = {
  casinoTypes: [],
  casinoTypesStatus: "idle",
  vendors: [],
  vendorGroups: [], // { casinoType, vendors }[],
  vendorsStatus: "idle",
  games: [],
  gameGroups: [], // { casinoType, games }[]
  gamesStatus: "idle",
  casinoDemoUrl: "",
  casinoGameUrl: ""
};

const gameSlice = createSlice({
  name: "casinoApp/game",
  initialState,
  reducers: {
    clearGameGroups: (state) => {
      state.gameGroups = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCasinoTypes.pending, (state) => {
        state.casinoTypesStatus = "loading";
      })
      .addCase(getCasinoTypes.fulfilled, (state, action) => {
        state.casinoTypesStatus = "fulfilled";
        state.casinoTypes = action.payload;
      })
      .addCase(getCasinoTypes.rejected, (state) => {
        state.casinoTypesStatus = "rejected";
      })

      .addCase(getVendorsForCasinoType.pending, (state) => {
        state.vendorsStatus = "loading";
      })
      .addCase(getVendorsForCasinoType.fulfilled, (state, action) => {
        state.vendorsStatus = "fulfilled";
        state.vendors = action.payload;
      })
      .addCase(getVendorsForCasinoType.rejected, (state) => {
        state.vendorsStatus = "rejected";
      })

      .addCase(getVendorsForCasinoTypes.pending, (state) => {
        state.vendorsStatus = "loading";
      })
      .addCase(getVendorsForCasinoTypes.fulfilled, (state, action) => {
        state.vendorsStatus = "fulfilled";
        state.vendors = action.payload.vendors;
        state.vendorGroups = action.payload.vendorGroups;
      })
      .addCase(getVendorsForCasinoTypes.rejected, (state) => {
        state.vendorsStatus = "rejected";
      })

      .addCase(getGameGroupsForVendorGroups.pending, (state) => {
        state.gamesStatus = "loading";
      })
      .addCase(getGameGroupsForVendorGroups.fulfilled, (state, action) => {
        state.gamesStatus = "fulfilled";
        state.gameGroups = [...state.gameGroups, ...action.payload.gameGroups];
      })
      .addCase(getGameGroupsForVendorGroups.rejected, (state) => {
        state.gamesStatus = "rejected";
      })

      .addCase(getGamesForCasinoGameType.pending, (state) => {
        state.gamesStatus = "loading";
      })
      .addCase(getGamesForCasinoGameType.fulfilled, (state, action) => {
        state.gamesStatus = "fulfilled";
        state.games = action.payload;
      })
      .addCase(getGamesForCasinoGameType.rejected, (state) => {
        state.gamesStatus = "rejected";
      })

      .addCase(getGamesForVendor.pending, (state) => {
        state.gamesStatus = "loading";
      })
      .addCase(getGamesForVendor.fulfilled, (state, action) => {
        state.gamesStatus = "fulfilled";
        state.games = action.payload;
      })
      .addCase(getGamesForVendor.rejected, (state) => {
        state.gamesStatus = "rejected";
      })

      .addCase(getSearchGames.pending, (state) => {
        state.gamesStatus = "loading";
      })
      .addCase(getSearchGames.fulfilled, (state, action) => {
        state.gamesStatus = "fulfilled";
        state.games = action.payload;
      })
      .addCase(getSearchGames.rejected, (state) => {
        state.gamesStatus = "rejected";
      })

      .addCase(getPopularGames.pending, (state) => {
        state.gamesStatus = "loading";
      })
      .addCase(getPopularGames.fulfilled, (state, action) => {
        state.gamesStatus = "fulfilled";
        state.games = action.payload;
      })
      .addCase(getPopularGames.rejected, (state) => {
        state.gamesStatus = "rejected";
      })

      .addCase(getGamesByVendor.pending, (state) => {
        state.gamesStatus = "loading";
      })
      .addCase(getGamesByVendor.fulfilled, (state, action) => {
        state.gamesStatus = "fulfilled";
        state.games = action.payload;
      })
      .addCase(getGamesByVendor.rejected, (state) => {
        state.gamesStatus = "rejected";
      })
      .addCase(getDemoGame.fulfilled, (state, action) => {
        state.casinoDemoUrl = action.payload;
      })
      .addCase(getGameWithPlayerCheck.fulfilled, (state, action) => {
        state.casinoGameUrl = action.payload;
      });
  },
});

export const { clearGameGroups } = gameSlice.actions;

export const selectGameData = (state) => state.casinoApp.game;

export default gameSlice.reducer;
