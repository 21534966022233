import { useRef } from "react";
import { useEffect } from "react";

export const useBoundingClientRect = (ref, onChange) => {
  const rectRef = useRef();

  useEffect(() => {
    const timer = setInterval(() => {
      if (!ref.current) return;
      const newRect = ref.current.getBoundingClientRect();
      if (!rectRef.current) {
        onChange();
        rectRef.current = newRect;
      } else if (
        rectRef.current.x !== newRect.x ||
        rectRef.current.y !== newRect.y
      ) {
        onChange();
        rectRef.current = newRect;
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);
};
