import { Box, Typography, IconButton, Stack, Alert } from "@mui/material";
import { ContentCard } from "../component/ContentCard";
import { useState, useEffect, useRef } from "react";
import { Hr } from "../component/InputGroups";
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail } from "../store/profileSlice";
import { selectUser } from 'app/store/userSlice';
import { useSystemType } from "src/app/hooks/useSystemType";
import { BETSHOP_SYSTEM } from "src/app/constants";
import { getVerification, uploadKYC } from "../store/verificationSlice"; // Ensure uploadKYC is imported
import { toast } from 'react-hot-toast'; // Assuming you're using this toast for notifications
import CloseIcon from '@mui/icons-material/Close'; // Import the close icon
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import i18next from "i18next";
import {selectCurrentLanguageId} from "app/store/i18nSlice";

export const Verification = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [value, onChange] = useState(new Date());
  const [type, setType] = useState("proof_of_identity");
  const fileInputRef = useRef(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null); // State to hold the file preview
  const systemType = useSystemType();
  const [kycData, setKycData] = useState();
  const currentLanguageId = useSelector(selectCurrentLanguageId);

  useEffect(() => {
    dispatch(getVerification({ kycType: type })).then((res) => {
      setKycData(res.payload);
    });
  }, [type]);

  const handleFileUpload = () => {
    if (file === null) return;
    dispatch(uploadKYC({ kycType: type, file }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    // Validate file type to allow only images
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validImageTypes.includes(file.type)) {
      toast.error(i18next.t("Only_PNG_JPEG_and_JPG_files_are_allowed"));
      setFile(null);
      setPreview(null);
      return;
    }

    setFile(file);

    // Create a preview URL for the selected file
    const filePreview = URL.createObjectURL(file);
    setPreview(filePreview);
  };

  const handleRemoveFile = () => {
    // Reset the file and preview states
    setFile(null);
    setPreview(null);
    setKycData(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input value to allow re-upload of the same file
    }
  };

  const handleVerify = () => {
    dispatch(verifyEmail());
  };

  if (systemType === BETSHOP_SYSTEM) {
    location.href = "/";
    return null;
  }

  return (
    <Box aria-label="Verification" className="flex flex-col-reverse md:flex-row gap-[12px]">
      <ContentCard title={i18next.t("Know_Your_Customer")} icon="assets/images/pages/settings/verification.svg">
        <Box className="bg-white rounded-b-[12px] p-[12px]">
          <Box className="flex flex-col gap-[12px] max-w-[1000px] mx-auto min-h-[500px]">
            <Box className="font-bold text-[16px]">{i18next.t("Email_Verification")}</Box>
            <Box sx={{ color: user.data.email_verified ? "green" : "red" }}>
              {user.data.email_verified ? i18next.t("Verified") : i18next.t("Not_Verified")}
            </Box>
            {!user.data.email_verified && (
              <button
                className="bg-[#E3E9ED] rounded-[12px] px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce]"
                onClick={handleVerify}
              >
                {i18next.t("Verify")}
              </button>
            )}
            <Hr />
            <Box>
              {i18next.t("Please_upload_copies_of_documents_in_the_format_supported_by_our_website_")}
            </Box>
            <Hr />
            <Box className="font-bold text-[16px] relative">
              {type === "proof_of_identity" && i18next.t("Proof_of_identity")}
              {type === "proof_of_address" && i18next.t("Proof_of_address")}
              {type === "proof_of_deposit" && i18next.t("Proof_of_deposit")}
              {kycData && (
                <Alert
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0
                  }} 
                  icon={
                    kycData?.status === 'Accept' ? <CheckCircleIcon fontSize="inherit" />
                    : kycData?.status === 'Declined'? <SmsFailedIcon fontSize="inherit" /> : 
                    <HourglassBottomIcon fontSize="inherit" />
                  } 
                  severity={
                    kycData?.status === 'Accept' ? "success"
                    : kycData?.status === 'Declined'? "error"
                    : "warning"
                  }>
                    {
                      kycData?.status === 'Accept' ? i18next.t("Your_documents_have_been_verified_successfully")
                      : kycData?.status === 'Declined' ? i18next.t("Your_documents_have_been_declined")
                      : i18next.t('Processing..._Please_wait')
                    }
                </Alert>
              )}
            </Box>
            <Box className="flex flex-col gap-[8px]">
              {type === "proof_of_identity" && (
                <>
                  <Typography>{i18next.t("Passport")}</Typography>
                  <Typography>{i18next.t("Drivers_license")}</Typography>
                  <Typography>{i18next.t("National_ID")}</Typography>
                </>
              )}
              {type === "proof_of_address" && (
                <>
                  <Typography>{i18next.t("Bank_statement")}</Typography>
                  <Typography>{i18next.t("Utility_bill")}</Typography>
                  <Typography>{i18next.t("Payment_for_mobile_service")}</Typography>
                </>
              )}
              {type === "proof_of_deposit" && (
                <>
                  <Typography>{i18next.t("Must_display_the_company_name")}</Typography>
                  <Typography>{i18next.t("Must_display_the_amount_paid")}</Typography>
                </>
              )}
            </Box>
            {kycData?.status !== 'Accept' && (
              <Stack
                gap="12px"
              >
                <Box className="flex items-center gap-[24px]">
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept=".jpg, .jpeg, .png" // Restrict the file input to image types
                  />
                  <button
                    className="bg-[#E3E9ED] rounded-[12px] sm:px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce] px-5 w-full"
                    onClick={() => fileInputRef.current.click()}
                  >
                    {i18next.t('Choose_Files')}
                  </button>
                  <button
                    className="bg-[#E3E9ED] rounded-[12px] px-5 sm:px-[32px] py-[6px] border-[1.5px] border-[#E3E9ED] max-w-fit hover:bg-[#c2c8ce] w-full"
                    onClick={handleFileUpload}
                  >
                    {i18next.t("Upload_Documents")}
                  </button>
                </Box>
                {/* File Preview Section */}
                {(preview || kycData) && (
                  <Box className="mt-4 relative max-w-[300px]">
                    <Typography variant="subtitle1">{i18next.t("File_Preview")}:</Typography>
                    <img
                      src={preview || kycData.doc_url}
                      alt="Preview"
                      className="max-w-[300px] max-h-[300px] rounded-[12px] border-[1.5px] border-[#E3E9ED]"
                    />
                    <IconButton
                      onClick={handleRemoveFile}
                      className="absolute top-0 right-0"
                      aria-label="Remove file"
                      sx={{ background: '#fff', ':hover': { background: '#f0f0f0' } }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </Stack>
            )}
          </Box>
        </Box>
      </ContentCard>
      <Box className="flex flex-col gap-[24px]">
        <button
          className="p-[12px] flex flex-col gap-[12px] shadow-md hover:shadow-lg hover:bg-[#00000030] rounded-[12px] max-w-[420px] text-left"
          onClick={() => setType("proof_of_identity")}
        >
          <Typography>{i18next.t("Proof_of_identity")}</Typography>
          <Hr />
          <Typography className="text-[12px]">
            {i18next.t("Please_make_sure_that_all_data_name_date_of_birth_citizenship_")}
          </Typography>
          <Hr />
          <Box className="flex flex-col gap-[6px]">
            <Typography>{i18next.t("Passport")}</Typography>
            <Typography>{i18next.t("Drivers_license")}</Typography>
            <Typography>{i18next.t("National_ID")}</Typography>
          </Box>
        </button>
        <button
          className="p-[12px] flex flex-col gap-[12px] shadow-md hover:shadow-lg hover:bg-[#00000030] rounded-[12px] max-w-[420px] text-left"
          onClick={() => setType("proof_of_address")}
        >
          <Typography>{i18next.t("Proof_of_address")}</Typography>
          <Hr />
          <Typography className="text-[12px]">
            {i18next.t("A_photo_or_a_screenshot_of_a_document_confirming_the_current_address_of_residence_")}
          </Typography>
          <Hr />
          <Box className="flex flex-col gap-[6px]">
            <Typography>{i18next.t("Bank_statement")}</Typography>
            <Typography>{i18next.t("Utility_bill")}</Typography>
            <Typography>{i18next.t("Payment_for_mobile_service")}</Typography>
          </Box>
        </button>
        <button
          className="p-[12px] flex flex-col gap-[12px] shadow-md hover:shadow-lg hover:bg-[#00000030] rounded-[12px] max-w-[420px] text-left"
          onClick={() => setType("proof_of_deposit")}
        >
          <Typography>{i18next.t("Proof_of_deposit")}</Typography>
          <Hr />
          <Typography className="text-[12px]">
            {i18next.t("Screenshot_or_photo_from_online_bank_")}
          </Typography>
          <Hr />
          <Box className="flex flex-col gap-[6px]">
            <Typography>{i18next.t("Must_display_the_company_name")}</Typography>
            <Typography>{i18next.t("Must_display_the_amount_paid")}</Typography>
          </Box>
        </button>
      </Box>
    </Box>
  );
};
