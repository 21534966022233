import React, { useState } from "react";
import { getWithdrawPayfix } from "../main/store/uiSlice";
import { useDispatch } from "react-redux";
import { TextField, Button, Box, Typography } from "@mui/material";

const PayfixForm = () => {
  const [account, setAccount] = useState("");
  const [amount, setAmount] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      getWithdrawPayfix({
        amount: amount,
        account: account,
      })
    ).then(async (response) => {
      const result = await response.json();
      if (result.code === 200) {
        alert("Withdrawal request accepted. Status changed to Pending.");
      } else {
        alert(`Withdrawal request rejected: ${result.message}`);
      }
    });
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Payfix Withdrawal Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Payfix Account Number"
          fullWidth
          value={account}
          onChange={(e) => setAccount(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          label="Amount"
          type="number"
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          margin="normal"
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Submit Withdrawal Request
        </Button>
      </form>
    </Box>
  );
};

export default PayfixForm;
