import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";
import toast from "react-hot-toast";
import { Alert } from "@mui/material";

const api_domain = jwtServiceConfig.apiUrl;

export const getBetHistory = createAsyncThunk("settingApp/getBetHistory", async () => {
  try {
    const response = await axios.get(`${api_domain}betHistory`);
    return response.data.coupons;
  } catch (err) {
    toast.custom(
      <Alert severity="error">{err.response?.data?.user_error || "Failed to fetch coupons history"}</Alert>
    );
    throw err;
  }
});

export const getCasinoHistory = createAsyncThunk("settingApp/getCasinoHistory", async (data) => {
  try {
    const response = await axios.get(`${api_domain}casino/getHistory/${data.transactionType.value}/${data.startDate}/${data.endDate}`);

    return response.data.histories;
  } catch (err) {
    toast.custom(
      <Alert severity="error">{err.response?.data?.user_error || "Failed to fetch casino history"}</Alert>
    );
    throw err;
  }
});

export const getTransactionHistory = createAsyncThunk("settingApp/getTransactionHistory", async ({ startDate, endDate, transactionType }) => {
  try {
    const response = await axios.get(`${api_domain}transactions/list/${startDate}/${endDate}/${transactionType}`);

    return response.data.transactions;
  } catch (err) {
    toast.custom(
      <Alert severity="error">{err.response?.data?.user_error || "Failed to fetch casino history"}</Alert>
    );
    throw err;
  }
});

export const offerCashoutCoupon = createAsyncThunk("settingApp/offerCashoutCoupon", async ({ couponId, partialAmount }) => {

  try {
    const response = await axios.put(`${api_domain}cashout/offer/${couponId}/${partialAmount}`);
    return response.data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
});

export const makeCashoutCoupon = createAsyncThunk("settingApp/makeCashoutCoupon", async ({ couponId, partialAmount }) => {

  try {
    const response = await axios.put(`${api_domain}cashout/make/${couponId}/${partialAmount}`);
    return response.data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
});

export const getRoundHistory = createAsyncThunk("casinoApp/getRoundHistory", async ({ roundId, gameId }) => {

  try {
    const response = await axios.post(`${api_domain}casino/getRoundHistory`, { roundID: roundId, gameId });
    return response.data;
  } catch (err) {
      toast.custom(
        <Alert severity="error">{err.response.data.error}</Alert>
      );
      return err?.response;
  }
});

const historyAdapter = createEntityAdapter([]);

const historySlice = createSlice({
  name: "settingApp/history",
  initialState: historyAdapter.getInitialState({}),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBetHistory.fulfilled, (state, action) => {
      historyAdapter.setAll(state, action.payload);
    });
  },
});

export const { selectAll: selectBetHistory } = historyAdapter.getSelectors((state) => state.settingApp.history);

export default historySlice.reducer;
