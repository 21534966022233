import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtServiceConfig from "src/app/auth/services/jwtService/jwtServiceConfig";

const apiBaseUrl = jwtServiceConfig.apiUrl;

const boostedOddsAdapter = createEntityAdapter();

const initialState = boostedOddsAdapter.getInitialState({
  status: "idle",
  boostedOdds: [],
});

export const getBoostedOdds = createAsyncThunk("sportsApp/getBoostedOdds", async () => {
  const res = await axios.get(`${apiBaseUrl}boosted`);
  return res.data.sportEventList || [];
});

const boostedOddsSlice = createSlice({
  name: "sportsApp/boostedOdds",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBoostedOdds.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBoostedOdds.fulfilled, (state, action) => {
        state.boostedOdds = action.payload;
        state.status = "fulfilled";
      })
      .addCase(getBoostedOdds.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export const selectBoostedOddsStatus = (state) => state.sportsApp.boostedOdds.status;
export const selectBoostedOdds = (state) => state.sportsApp.boostedOdds.boostedOdds;

export default boostedOddsSlice.reducer;
