import { Box, Stack } from "@mui/material";
import { useMemo } from "react";
import BackspaceIcon from "@mui/icons-material/Backspace";

const additionValues = [5, 10, 20, 50, 100];

export const DigitKeys = (props) => {
  const { startNumber, onClick } = props; // startNumber: 1 | 6
  const digits = useMemo(() => {
    const result = [];
    for (let i = 0; i < 5; i++) result.push((startNumber + i) % 10);
    return result;
  }, [startNumber]);

  return (
    <Stack direction="row" gap="2px">
      {digits.map((d) => (
        <button
          key={d}
          className="w-full bg-[#333] hover:bg-[#252525] active:bg-[#222] text-white py-[4px]"
          onClick={() => onClick(d)}
        >
          {d}
        </button>
      ))}
    </Stack>
  );
};

export const StateAmountInput = (props) => {
  const { onOkay, onIncrease, onDigitClick, onPointClick, onBackspaceClick } = props;
  return (
    <Box>
      <Stack direction="row" gap="4px">
        {additionValues.map((value) => (
          <button
            key={value}
            className="bg-[#eee] hover:bg-[#ddd] active:bg-[#ccc] text-[black] py-[4px] w-full rounded-[4px]"
            onClick={() => onIncrease(value)}
          >
            {`+${value}`}
          </button>
        ))}
      </Stack>
      <Stack direction="row" gap="2px" className="mt-[4px]">
        <Stack gap="2px" width="70%">
          <DigitKeys startNumber={1} onClick={onDigitClick} />
          <DigitKeys startNumber={6} onClick={onDigitClick} />
        </Stack>
        <Stack direction="row" gap="2px" width="30%">
          <Stack gap="2px" width="100%">
            <button
              className="w-full h-full bg-[#333] hover:bg-[#252525] active:bg-[#222] text-white py-[4px]"
              onClick={onPointClick}
            >
              .
            </button>
            <button
              className="w-full h-full bg-[#333] hover:bg-[#252525] active:bg-[#222] text-white py-[4px]"
              onClick={onBackspaceClick}
            >
              <BackspaceIcon className="text-[16px]" />
            </button>
          </Stack>
          <button
            className="w-full h-full bg-[#333] hover:bg-[#252525] active:bg-[#222] text-white py-[4px]"
            onClick={onOkay}
          >
            OK
          </button>
        </Stack>
      </Stack>
    </Box>
  );
};
