const jwtServiceConfig = {
  signIn: process.env.REACT_APP_API_URL + "auth/sign-in",
  signUp: process.env.REACT_APP_API_URL + "auth/sign-up",
  signUpAffiliate: process.env.REACT_APP_API_URL + "auth/affiliate/sign-up",
  accessToken: process.env.REACT_APP_API_URL + "auth/refresh-token",
  updateUser: process.env.REACT_APP_API_URL + "auth/user/update",
  apiUrl: process.env.REACT_APP_API_URL,
  datafeedUrl: "https://oddsfeed.meganetpanel.com/", //process.env.DATAFEED_API_URL,
  cdnUrl: "https://dev.meganetpanel.com/api",
};

export default jwtServiceConfig;
