import React from "react";
import { Stack, useTheme, useMediaQuery } from "@mui/material";

function FooterLogos() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const logos = [
    { src: "assets/images/FooterLogos/Bitcoin.svg", alt: "Bitcoin" },
    { src: "assets/images/FooterLogos/Dogecoin.svg", alt: "Dogecoin" },
    { src: "assets/images/FooterLogos/eos.svg", alt: "Eos" },
    { src: "assets/images/FooterLogos/etherium2.svg", alt: "Ethereum" },
    { src: "assets/images/FooterLogos/tron2.svg", alt: "Tron" },
    { src: "assets/images/FooterLogos/ripple2.svg", alt: "Ripple" },
    { src: "assets/images/FooterLogos/banxa2.svg", alt: "Banxa" },
    { src: "assets/images/FooterLogos/wert2.svg", alt: "Wert" },
    { src: "assets/images/FooterLogos/moonpay2.svg", alt: "Moonpay" },
  ];

  return (
    // Desktop View: Static Grid
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className="md:px-[24px] md:gap-[58px] md:py-[8px] py-[16px] flex-wrap justify-center gap-[24px] px-[8px]"
      sx={{
        // background: theme.palette.background.dark600,
      }}
    >
      {logos.map((logo, index) => (
        <a href="#" key={index} className="opacity-80 md:flex-1">
          <img src={logo.src} alt={logo.alt} />
        </a>
      ))}
    </Stack>
  );
}

export default FooterLogos;