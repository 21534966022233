
function FooterPaymentLogos() {
    return (
        <>
            <div className="footer_logos">
                <img src="assets/images/cards/visa.png" alt="" />
                <img src="assets/images/cards/masterCard.png" alt="" />
                <img src="assets/images/cards/americanExpress.png" alt="" />
                <img src="assets/images/cards/jcb.png" alt="" />
                <img src="assets/images/cards/swift.png" alt="" />
                <img src="assets/images/cards/sepa.png" alt="" />
                <img src="assets/images/cards/paypal.png" alt="" />
                <img src="assets/images/cards/skrill.png" alt="" />
                <img src="assets/images/cards/neteller.png" alt="" />
                <img src="assets/images/cards/paysafe.png" alt="" />
                <img src="assets/images/cards/applePay.png" alt="" />
                <img src="assets/images/cards/gPay.png" alt="" />
            </div>
        </>
    );
}

export default FooterPaymentLogos;